import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import { HiOutlineMagnifyingGlassCircle } from "react-icons/hi2";
import TooltipComponent from "components/reusable/Tooltip/Tooltip";
import Dialog from "@mui/material/Dialog";
import "./profile.scss";
import { useAppDispatch } from "components/store/configureStore";
import { IoMdClose } from "react-icons/io";
import { DATE_FORMAT } from "utils/constants";

import {
  deleteContactRequestsAsync,
  fetchContactRequestsAsync,
} from "components/views/LoginRegister/accountSlice";
import { useTranslation } from "react-i18next";
import { Button, IconButton } from "@mui/material";

export interface ContactRequest {
  id: number;
  offer_id: string;
  message: string;
  customer_email: string;
  customer_phone: string;
  created_at: string;
  updated_at: string;
}

enum DialogFunction {
  Delete = "DELETE",
  Show = "SHOW",
}

const ContactRequestsTable = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [currentRequest, setCurrentRequest] = useState<ContactRequest | null>(
    null
  );
  const [dialogToDisplay, setDialogToDisplay] = useState<DialogFunction>();
  const [contactRequests, setContactRequests] = useState<ContactRequest[]>([]);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    setOpen(false);
  };

  const deleteRequest = (id: number) => {
    dispatch(deleteContactRequestsAsync(id)).then(() => {
      setContactRequests((prevState) =>
        prevState.filter((element) => element.id !== id)
      );
      handleClose();
    });
  };

  const openDialog = (
    dialogFunction: DialogFunction,
    request: ContactRequest
  ) => {
    setCurrentRequest(request);
    setDialogToDisplay(dialogFunction);
    setOpen(true);
  };

  const showMessageDialog = (request: ContactRequest) => {
    return (
      <div className="dialog-wrapper">
        <IoMdClose
          className="close-icon"
          size={20}
          onClick={() => handleClose()}
        />
        <h4>{t("profile:message-details")}</h4>
        <hr />
        <div>
          <div className="row">
            <span className="dialog-text bold">
              {t("profile:request-email")}:{" "}
            </span>
            {request.customer_email}
          </div>
          <div className="row">
            <span className="dialog-text bold">
              {t("profile:request-phone")}:{" "}
            </span>
            {request.customer_phone}
          </div>
          <div className="row">
            <span className="dialog-text bold">
              {t("profile:request-created")}:{" "}
            </span>
            {dayjs(request.created_at).format(DATE_FORMAT)}
          </div>
          <div className="message-wrapper">
            <div className="dialog-text">{request.message}</div>
          </div>
          <div className="button-section">
            <Button onClick={() => setDialogToDisplay(DialogFunction.Delete)}>
              {t("profile:delete-request")}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const deleteRequestDialog = (request: ContactRequest) => {
    return (
      <div className="dialog-wrapper delete-dialog">
        <IoMdClose
          className="close-icon"
          size={20}
          onClick={() => handleClose()}
        />
        <h4 className="delete-question">{t("profile:sure-to-delete")}</h4>
        <h4 className="delete-question cut-text">
          {t("profile:delete-from")} {request.customer_email}?
        </h4>
        <div className="button-section">
          <Button
            color="secondary"
            onClick={() => setDialogToDisplay(DialogFunction.Show)}
          >
            {t("profile:cancel")}
          </Button>
          <Button
            onClick={() => deleteRequest(request.id)}
            className="delete-button"
          >
            {t("profile:delete")}
          </Button>
        </div>
      </div>
    );
  };

  const showDialog = (request: ContactRequest) => {
    switch (dialogToDisplay) {
      case DialogFunction.Show:
        return showMessageDialog(request);
      case DialogFunction.Delete:
        return deleteRequestDialog(request);
      default:
        break;
    }
  };

  useEffect(() => {
    //Function to fetch mock data for testing
    // fetchData("../mocks/contact-request.mock.json");
    dispatch(fetchContactRequestsAsync()).then((data: any) => {
      setContactRequests(data.payload.data);
    });
  }, [dispatch]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        className="contact-request-dialog"
        BackdropProps={{
          style: { backgroundColor: "transparent" },
        }}
      >
        {currentRequest && showDialog(currentRequest)}
      </Dialog>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>{t("profile:request-email")}</TableCell>
              <TableCell align="right" className="md-cell">
                {t("profile:request-phone")}
              </TableCell>
              <TableCell align="right" className="md-cell">
                {t("profile:request-created")}
              </TableCell>
              <TableCell align="right" className="md-cell">
                {t("profile:request-updated")}
              </TableCell>
              <TableCell align="right">
                {t("profile:request-actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contactRequests &&
              contactRequests.length > 0 &&
              contactRequests.map((request) => (
                <TableRow
                  key={request.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {request.customer_email}
                  </TableCell>
                  <TableCell className="md-cell" align="right">
                    {request.customer_phone}
                  </TableCell>
                  <TableCell align="right" className="md-cell">
                    {dayjs(request.created_at).format(DATE_FORMAT)}
                  </TableCell>
                  <TableCell align="right" className="md-cell">
                    {dayjs(request.updated_at).format(DATE_FORMAT)}
                  </TableCell>
                  <TableCell align="right">
                    <TooltipComponent
                      WrappedComponent={
                        <IconButton
                          aria-label="edit"
                          onClick={() =>
                            openDialog(DialogFunction.Show, request)
                          }
                        >
                          <HiOutlineMagnifyingGlassCircle size={24} />
                        </IconButton>
                      }
                      message={t("profile:show-message")}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ContactRequestsTable;
