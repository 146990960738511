import { useTranslation } from "react-i18next";
import SmallContact from "../../utils/SmallContact";

const TenAdvices = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="tenadvices__header">
        <h1>{t("sell:10-amazing-tips")}</h1>
      </div>
      <div className="homevalue_flexbox">
        <div className="homevalue__box">
          <h2>{t("sell:10-amazing-tips-header")}</h2>
          <ul>
            <li>
              <h3>{t("sell:prepare-house-to-sell")}</h3>
              <p>{t("sell:keep-clean")}</p>
            </li>
            <li>
              <h3>{t("sell:first-impression")}</h3>
              <p>{t("sell:improving-facade")}</p>
            </li>
            <li>
              <h3>{t("sell:refreshing")}</h3>
              <p>{t("sell:painting-walls")}</p>
            </li>
            <li>
              <h3>{t("sell:proffesional-photos")}</h3>
              <p>{t("sell:use-the-services")}</p>
            </li>
            <li>
              <h3>{t("sell:online-marketing")}</h3>
              <p>{t("sell:use-online-marketing")}</p>
            </li>
            <li>
              <h3>{t("sell:highlight-strengths")}</h3>
              <p>{t("sell:highlight-unique-features")}</p>
            </li>
            <li>
              <h3>{t("sell:competitive-price")}</h3>
              <p>{t("sell:conduct-market-research")}</p>
            </li>
            <li>
              <h3>{t("sell:open-session")}</h3>
              <p>{t("sell:open-house")}</p>
            </li>
            <li>
              <h3>{t("sell:work-with-agent")}</h3>
              <p>{t("sell:hire-an-agent")}</p>
            </li>
            <li>
              <h3>{t("sell:be-flexible")}</h3>
              <p>{t("sell:negotiation-open")}</p>
            </li>
          </ul>
          <h4>{t("sell:remember")}</h4>
        </div>
      </div>
      <SmallContact />{" "}
    </>
  );
};

export default TenAdvices;
