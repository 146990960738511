import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAppSelector } from "../../store/configureStore";
import Navlinks from "./Navlinks";
import LoggedUserData from "./LoggedUserData";
import Login from "../LoginRegister/Login";
import Signup from "../LoginRegister/Signup";
import MobileMenu from "./MobileMenu";
import logo from "../../../images/logorealn.webp";
import logo2 from "../../../images/logo2.webp";
import beta from "../../../images/beta.webp";
import { AiOutlineMenu } from "react-icons/ai";
import { RxCross1 } from "react-icons/rx";
import "./nav.scss";
import variables from "../../../variables.js";
import { useTranslation } from "react-i18next";
import SearchWithState from "../utils/Searcher/SearchWithState";
import { MdPerson, MdPersonAdd } from "react-icons/md";

const Nav = () => {
  const user = useAppSelector((state) => state.account.user);

  const routerLocation = useLocation();
  const { t } = useTranslation();
  const [isBuyActive, setIsBuyActive] = useState(false);
  const [isSellActive, setIsSellActive] = useState(false);
  const [isRentActive, setIsRentActive] = useState(false);
  const [menuActive, setMenuActive] = useState(false);

  const deactivateTopBars = () => {
    isBuyActive && setIsBuyActive(false);
    isSellActive && setIsSellActive(false);
    isRentActive && setIsRentActive(false);
  };

  const menuControl = () => {
    setMenuActive(false);
  };

  const handleBuy = (i: boolean) => {
    setIsBuyActive(i);
  };

  const handleRent = (i: boolean) => {
    setIsRentActive(i);
  };

  const handleSell = (i: boolean) => {
    setIsSellActive(i);
  };

  const NavLoginComponent = () => {
    return (
      <>
        <li className="desktop">
          <MdPerson />
          {t("login:login")}
        </li>
        <li className="small">
          <MdPerson />
        </li>
      </>
    );
  };

  const NavSignupComponent = () => {
    return (
      <>
        <li className="desktop">
          <MdPersonAdd style={{ marginRight: "5px" }} />
          {t("login:registration")}
        </li>
        <li className="small">
          <MdPersonAdd />
        </li>
      </>
    );
  };

  return (
    <nav className={routerLocation.pathname === "/" ? "" : "nav2"}>
      <aside className="nav-searcher-box">
        <Link to="/">
          <img
            className="logo"
            src={routerLocation.pathname === "/" ? logo : logo2}
            alt="logo"
          />
          <img className="beta" src={beta} alt="beta" />
        </Link>
        {routerLocation.pathname !== "/" &&
          !routerLocation.pathname.includes("search") && (
            <div className="desktop">
              <SearchWithState />
            </div>
          )}
      </aside>
      <ul
        className={
          routerLocation.pathname === "/" ? "nav-bar" : "nav-bar nav-bar2"
        }
      >
        <div className="nav-bar desktop">
          <a
            href={`tel: ${variables.realnPhone}`}
            className="phone-box"
            onMouseEnter={(e) => deactivateTopBars()}
          >
            {variables.realnPhone}
          </a>
          <Navlinks
            isBuyActive={isBuyActive}
            isRentActive={isRentActive}
            isSellActive={isSellActive}
            handleBuy={handleBuy}
            handleSell={handleSell}
            handleRent={handleRent}
            menuControl={menuControl}
          />
          {/* <Link to="/realn-premium" className="contact-link premium">
            {t("nav:realn-premium")}
          </Link> */}
          <Link
            to="/contact"
            className="contact-link"
            onMouseEnter={(e) => deactivateTopBars()}
          >
            {t("main:contact")}
          </Link>
        </div>
        <ul
          className={
            routerLocation.pathname === "/"
              ? "nav-login-bar"
              : "nav-login-bar nav-login-bar2"
          }
        >
          <li className="mobile" onClick={() => setMenuActive(!menuActive)}>
            {!menuActive ? <AiOutlineMenu /> : <RxCross1 />}
          </li>
          <Link to="search/?" className="outlined-link">
            {t("nav:offer-catalogue")}
          </Link>
          {user && <LoggedUserData user={user} />}

          {!user && (
            <>
              <Login Component={NavLoginComponent} />
              <Signup Component={NavSignupComponent} />
            </>
          )}
        </ul>
      </ul>

      {menuActive && (
        <MobileMenu menuControl={menuControl} menuActive={menuActive} />
      )}
    </nav>
  );
};

export default Nav;
