import { SearchOffersParams } from "../../models/offer";
export const formatTableParameter = (array: string[]) => {
  return array.reduce((acc: any, element, index) => {
    acc[index + 1] = element;
    return acc;
  }, []);
};

export default function getAxiosParams(offerParams: SearchOffersParams) {
  const params = new URLSearchParams();
  const allowedParams = [
    "type",
    "buildType",
    "min_price",
    "max_price",
    "max_size",
    "min_size",
    "location",
    "order_by",
    "order",
    "page_size",
    "page",
    "searchString",
    "title",
    "rooms",
    "floor",
    "loudness",
    "installation",
    "furniture",
    "condition",
    "direction",
    "amenity",
    "medium",
    "parking",
    "energy",
    "window",
    "kitchen",
    "bathroom",
    "health",
    "recreation",
    "education",
    "others",
  ];

  Object.entries(offerParams)
    .filter(
      ([key, value]) =>
        allowedParams.includes(key) &&
        Boolean(value) &&
        Boolean(value.toString())
    )
    .forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((element, index) => {
          params.append(`${key}[${index + 1}]`, element);
        });
        return;
      }
      params.append(key, value.toString());
    });

  if (!params.get("page")) {
    params.append("page", "1");
  }

  return params;
}
