import { Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { MdCloudUpload } from "react-icons/md";

interface OfferImagesUploadInputProps {
  name?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const OfferImagesUploadInput = ({
  name = "uploadimage",
  onChange,
}: OfferImagesUploadInputProps) => {
  const { t } = useTranslation();
  return (
    <Stack
      component="label"
      htmlFor={name}
      sx={{
        width: "100%",
        height: "auto",
        aspectRatio: "4/3",
        border: "1px solid",
        borderColor: "grey.100",
        borderRadius: "4px",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        "&:hover": {
          borderColor: "grey.200",
          backgroundColor: "grey.100",
        },
      }}
    >
      <MdCloudUpload style={{ fontSize: "35px", color: "gray" }} />
      <Typography variant="caption">{t("addOffer:click-add-photo")}</Typography>
      <input
        type="file"
        name={name}
        id={name}
        accept="image/*"
        multiple
        style={{ display: "none" }}
        onChange={onChange}
      />
    </Stack>
  );
};

export default OfferImagesUploadInput;
