import { Box, MarginProps } from "@mui/system";
import { PropsWithChildren } from "react";

interface Props {
  heading?: string;
  mb?: MarginProps["marginBottom"];
}

const FormFieldset = ({ heading, children, mb }: Props & PropsWithChildren) => {
  return (
    <Box
      component={"fieldset"}
      sx={{
        border: 0,
        margin: 0,
        minWidth: 0,
        p: 0,
        mb: mb ?? 6,
      }}
    >
      {heading && (
        <Box
          component={"legend"}
          sx={{
            fontSize: "16px",
            fontWeight: 600,
            mb: 6,
          }}
        >
          {heading}
        </Box>
      )}
      {children}
    </Box>
  );
};

export default FormFieldset;
