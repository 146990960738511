import {
  headingsPlugin,
  listsPlugin,
  MDXEditor,
  type MDXEditorMethods,
} from "@mdxeditor/editor";
import "@mdxeditor/editor/style.css";
import { Box } from "@mui/material";
import { useRef } from "react";
import markdownStyles from "./styles";

interface MarkdownPreviewProps {
  source?: string;
}

const MarkdownPreview = ({ source = "" }: MarkdownPreviewProps) => {
  const previewRef = useRef<MDXEditorMethods>(null);
  return (
    <Box sx={markdownStyles}>
      <MDXEditor
        plugins={[headingsPlugin(), listsPlugin()]}
        markdown={source}
        ref={previewRef}
        className="markdown-preview"
        contentEditableClassName="markdown-editor-content preview"
        readOnly
      />
    </Box>
  );
};

export default MarkdownPreview;
