import { useParams } from "react-router-dom";
import { useEffect } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "components/store/configureStore";
import { fetchOfferAsync } from "../offers/catalogSlice";
import { offersSelectors } from "components/store/selectors";
import OfferForm from "./components/FormOffer";
import { createOfferData } from "utils/offer";
import { fetchOfferImagesAsync } from "components/store/offerImageSlice";

const EditOffer = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const offerID = params?.id;

  const offer = useAppSelector(
    (state) =>
      offerID && createOfferData(offersSelectors.selectById(state, offerID))
  );

  useEffect(() => {
    dispatch(fetchOfferAsync(offerID!));
    dispatch(fetchOfferImagesAsync(offerID!));
  }, [offerID]);

  if (!offer) return null;
  return <OfferForm offer={offer} />;
};

export default EditOffer;
