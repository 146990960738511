import { AiFillHeart, AiOutlineArrowLeft } from "react-icons/ai";
import { FaShare } from "react-icons/fa";
import "../propertyDetails.scss";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import PropAddToFavorites from "./PropAddToFavorites";
import { useTranslation } from "react-i18next";
import PropShare from "./PropShare";
import MessageDialog from "./MessageDialog";
import { Button } from "@mui/material";
import AgentInfoDialog from "./AgentInfoDialog";
import { AuthPoster } from "components/reusable/AuthPoster/AuthPoster";
import { ResponseOffer } from "components/models/offer";

interface Props {
  offer: ResponseOffer;
}
export default function PropNav({ offer }: Props) {
  const { t } = useTranslation();
  const [isSticky, setIsSticky] = useState(false);
  const initialOffset = 70;
  const [messageOpen, setMessageOpen] = useState(false);
  const [agentOpen, setAgentOpen] = useState(false);

  const handleMessageOpen = () => {
    setMessageOpen(true);
  };

  const handleMessageClose = () => {
    setMessageOpen(false);
  };

  const handleAgentOpen = () => {
    setAgentOpen(true);
  };

  const handleAgentClose = () => {
    setAgentOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > initialOffset);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      className={`property-nav ${isSticky ? "sticky" : ""}`}
      style={{ top: isSticky ? 0 : initialOffset }}
    >
      <div className="property-nav-box">
        <div>
          <Link to="/search">
            <AiOutlineArrowLeft />
            {t("buy:to-search-results")}
          </Link>
          <a href="#details" className="prop-det">
            {t("buy:about-the-facility")}
          </a>
          {/* <a href="/">{t("buy:details")}</a> */}
          {/* <a href="#info" className="prop-info">
          {t("buy:additional-info")}
        </a> */}
        </div>
        <div className="property-icons">
          <PropAddToFavorites />
          <PropShare />
        </div>
      </div>
      {offer.user ? (
        <div className="prop-nav-buttons-box">
          <Button size="large" fullWidth onClick={handleAgentOpen}>
            {t("buy:appointment-with-agent")}
          </Button>
          <Button size="large" fullWidth onClick={handleMessageOpen}>
            {t("buy:agent-message")}
          </Button>
          <AgentInfoDialog
            open={agentOpen}
            handleClose={handleAgentClose}
            offer={offer}
          />
          <MessageDialog
            open={messageOpen}
            handleClose={handleMessageClose}
            offer={offer}
          />
        </div>
      ) : (
        <AuthPoster message={t("utils:log-in-to-contact-agent")} />
      )}
    </nav>
  );
}
