import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { FavoriteOffer } from "components/models/offer";
import TooltipComponent from "components/reusable/Tooltip/Tooltip";
import { MdDelete, MdOpenInNew } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface FavoritesTableProps {
  favoriteOffers: FavoriteOffer[];
  onDelete: (
    e: React.MouseEvent<HTMLButtonElement>,
    id: FavoriteOffer["id"]
  ) => void;
}

const FavoritesTable = ({ favoriteOffers, onDelete }: FavoritesTableProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    offer: FavoriteOffer
  ) => {
    e.preventDefault();
    navigate(`/offer/${offer.id}`);
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>{t("profile:favorite-title")}</TableCell>
            <TableCell className="md-cell">
              {t("profile:favorite-type")}
            </TableCell>
            <TableCell align="right" className="md-cell">
              {t("profile:favorite-city")}
            </TableCell>
            <TableCell align="right" className="md-cell">
              {t("profile:favorite-district")}
            </TableCell>
            <TableCell align="right" className="md-cell">
              {t("profile:favorite-size")}
            </TableCell>
            <TableCell align="right">{t("profile:favorite-actions")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {favoriteOffers.map((offer) => (
            <TableRow
              key={offer.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {offer.title}
              </TableCell>
              <TableCell className="md-cell" align="right">
                {offer.type?.name ?? "-"}
              </TableCell>
              <TableCell align="right" className="md-cell">
                {offer.city?.name ?? "-"}
              </TableCell>
              <TableCell align="right" className="md-cell">
                {offer.district?.name ?? "-"}
              </TableCell>
              <TableCell align="right" className="md-cell">
                {offer.size ?? "-"} m<sup>2</sup>
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  "& > div:not(:last-child)": { mr: 1 },
                  minWidth: "110px",
                }}
              >
                <TooltipComponent
                  WrappedComponent={
                    <IconButton onClick={(e) => handleClick(e, offer)}>
                      <MdOpenInNew size={24} />
                    </IconButton>
                  }
                  message={t("profile:favorite-go-to-offer")}
                />
                <TooltipComponent
                  WrappedComponent={
                    <IconButton onClick={(e) => onDelete(e, offer.id)}>
                      <MdDelete size={24} />
                    </IconButton>
                  }
                  message={t("profile:search-criteria-delete")}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FavoritesTable;
