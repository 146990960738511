import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ResponseOffer } from "components/models/offer";
import TooltipComponent from "components/reusable/Tooltip/Tooltip";
import { MdContentCopy, MdDelete, MdOutlineEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import notFoundImage from "images/home-placeholder.webp";
import { formatPrice } from "utils/helpers";
import { useAppSelector } from "components/store/configureStore";
import { TableElement } from "components/models/table";

interface Props {
  userOffers: ResponseOffer[];
  onDelete: (
    e: React.MouseEvent<HTMLButtonElement>,
    id: ResponseOffer["id"]
  ) => void;
  onDuplicate: (
    e: React.MouseEvent<HTMLButtonElement>,
    id: ResponseOffer["id"]
  ) => void;
}

const OfferTable = ({ userOffers, onDelete, onDuplicate }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const table = useAppSelector((state) => state.utility.table);

  const offers = userOffers
    .slice()
    .sort((a, b) => (a.created_at > b.created_at ? -1 : 1));

  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    offer: ResponseOffer
  ) => {
    e.stopPropagation();
    navigate(`/catalog/${offer.id}`);
  };

  const handleRedirect = (
    e: React.MouseEvent<HTMLTableRowElement>,
    id: string
  ) => {
    e.stopPropagation();
    navigate(`/offer/${id}`);
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>

            <TableCell>{t("profile:favorite-title")}</TableCell>
            <TableCell className="md-cell">
              {t("profile:favorite-type")}
            </TableCell>
            <TableCell align="right" className="md-cell">
              {t("profile:search-criteria-price")}
            </TableCell>

            <TableCell align="right">{t("profile:favorite-actions")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {offers.map((offer) => (
            <TableRow
              role="button"
              onClick={(e) => handleRedirect(e, offer.id)}
              key={offer.id}
              sx={{
                cursor: "pointer",
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { backgroundColor: "#f4f6f7" },
              }}
            >
              <TableCell sx={{ p: 0, textAlign: "left" }}>
                <img
                  src={
                    offer.featured_image
                      ? offer.featured_image.variants.thumbnail
                      : notFoundImage
                  }
                  alt={offer.title}
                  className="user-offer-img"
                ></img>
              </TableCell>
              <TableCell component="th" scope="row">
                {offer.title}
              </TableCell>
              <TableCell className="md-cell" align="right">
                {table.OfferType.find(
                  (element: TableElement) => element.id === offer.type?.id
                )?.name ?? "-"}
              </TableCell>
              <TableCell align="right" className="md-cell">
                {offer.price ? formatPrice(offer.price) : "-"}
              </TableCell>

              <TableCell
                align="right"
                sx={{
                  "& > div:not(:last-child)": { mr: 1 },
                  minWidth: "110px",
                }}
              >
                <TooltipComponent
                  WrappedComponent={
                    <IconButton
                      aria-label="duplicate"
                      onClick={(e) => onDuplicate(e, offer.id)}
                    >
                      <MdContentCopy size={24} />
                    </IconButton>
                  }
                  message={t("profile:duplicate-offer")}
                />
                <TooltipComponent
                  WrappedComponent={
                    <IconButton
                      aria-label="edit"
                      onClick={(e) => handleClick(e, offer)}
                    >
                      <MdOutlineEdit size={24} />
                    </IconButton>
                  }
                  message={t("profile:edit-offer")}
                />
                <TooltipComponent
                  WrappedComponent={
                    <IconButton onClick={(e) => onDelete(e, offer.id)}>
                      <MdDelete size={24} />
                    </IconButton>
                  }
                  message={t("profile:search-criteria-delete")}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OfferTable;
