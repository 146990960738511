import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, Dialog } from "@mui/material";
import { ResponseOffer } from "components/models/offer";
import {
  useAppDispatch,
  useAppSelector,
} from "components/store/configureStore";
import { duplicateOfferAsync } from "components/views/offers/catalogSlice";
import { IoMdClose } from "react-icons/io";

interface EditOfferButtonProps {
  offer: ResponseOffer;
}

const DuplicationOfferButton = ({ offer }: EditOfferButtonProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const userId = useAppSelector((state) => state.account.user?.id);

  const isDuplicating = useAppSelector(
    (state) => state.catalog.status === "pendingDuplicateOffer"
  );

  const handleDuplicate = async () => {
    if (!offer.id) return;

    const res = await dispatch(duplicateOfferAsync(offer.id));
    if (res.type === "catalog/duplicateOfferAsync/fulfilled")
      navigate(`/profile/offers`);
  };

  if (!offer?.user || offer.user.id !== userId) return null;

  return (
    <>
      <Button size="large" fullWidth onClick={() => setIsOpen(true)}>
        {t("profile:duplicate-offer")}
      </Button>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="user-offer-duplicate-dialog"
        slotProps={{
          backdrop: {
            style: { backgroundColor: "transparent" },
          },
        }}
      >
        <div className="dialog-wrapper confirmation-dialog">
          <IoMdClose
            className="close-icon"
            size={20}
            onClick={() => setIsOpen(false)}
          />
          <h4 className="confirmation-question">
            {t("profile:sure-to-duplicate-offer")}
          </h4>
          <div className="button-section">
            <Button onClick={() => setIsOpen(false)} color="selected">
              {t("profile:cancel")}
            </Button>
            <Button
              onClick={handleDuplicate}
              size="medium"
              disabled={isDuplicating}
            >
              {t("profile:duplicate")}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default DuplicationOfferButton;
