import { FC, useEffect, useState } from "react";
import "./notification.scss";
import Alert, { AlertColor } from "@mui/material/Alert";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import { useAppDispatch } from "components/store/configureStore";
import { ActionCreatorWithoutPayload } from "@reduxjs/toolkit";
import variables from "variables";

interface NotificationProps {
  message: string;
  severity?: AlertColor;
  resetFunc: ActionCreatorWithoutPayload;
}

export const Notification: FC<NotificationProps> = ({
  message,
  severity = "error",
  resetFunc,
}) => {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();

  const convertHideDuration = (duration: any) => {
    return parseInt(duration);
  };

  useEffect(() => {
    message && setOpen(true);
  }, [message]);

  const handleClose = () => {
    setOpen(false);
    dispatch(resetFunc());
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={convertHideDuration(
        variables.notificationAutoHideDuration
      )}
      onClose={handleClose}
    >
      <Alert
        className="notification"
        severity={severity}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={handleClose}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        {message}
      </Alert>
    </Snackbar>
  );
};
