import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  isAnyOf,
} from "@reduxjs/toolkit";
import { RequestOffer, ResponseOffer } from "../../models/offer";
import axios from "axios";
import variables from "../../../variables";
import {
  attachOfferImagesAsync,
  deleteOfferImagesAsync,
  reorderOfferImagesAsync,
} from "components/store/offerImageSlice";
import { MetaData } from "components/models/meta";
import DOMPurify from "dompurify";
import i18next from "i18next";

interface CatalogState {
  offers: any[];
  status: string;
  offersLoaded: boolean;
  offerLoaded: boolean;
  data: any;
  offerAdding: boolean;
  filterValue: string;
  metaData: MetaData | null;
  catalogError: any;
}

const offersAdapter = createEntityAdapter<ResponseOffer>({});

export const createOfferAsync = createAsyncThunk<
  any,
  { offerData: RequestOffer }
>("catalog/createOfferAsync", async ({ offerData }, thunkAPI) => {
  try {
    const sanitizedDescription = DOMPurify.sanitize(offerData.description, {
      ALLOWED_TAGS: ["p", "br"],
    });

    offerData.description = sanitizedDescription;

    const response = await axios({
      method: "post",
      url: `${variables.getBackend}/api/v1/offers`,
      headers: {
        ...variables.xsrfToken,
        Authorization: `Bearer ${localStorage.getItem("currentToken")}`,
        Accept: "application/json",
      },
      data: offerData,
      withCredentials: true,
    });

    const offerId = response.data.data.id;
    const { dispatch } = thunkAPI;

    await Promise.all([
      dispatch(attachOfferImagesAsync(offerId)),
      dispatch(reorderOfferImagesAsync(offerId)),
    ]);

    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.errors);
  }
});

export const updateOfferAsync = createAsyncThunk<
  any,
  { offerData: RequestOffer; id: string | undefined }
>("catalog/updateOfferAsync", async ({ offerData, id }, thunkAPI) => {
  const { dispatch } = thunkAPI;

  await Promise.all([
    dispatch(attachOfferImagesAsync(offerData.id)),
    dispatch(deleteOfferImagesAsync(offerData.id)),
    dispatch(reorderOfferImagesAsync(offerData.id)),
  ]);

  try {
    let response = await axios({
      method: "put",
      url: `${variables.getBackend}/api/v1/offers/${id}`,
      headers: {
        ...variables.xsrfToken,
        Authorization: `Bearer ${localStorage.getItem("currentToken")}`,
        Accept: "application/json",
      },
      data: offerData,
      withCredentials: true,
    });
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.errors);
  }
});

export const searchOffersAsync = createAsyncThunk<any, string | undefined>(
  "catalog/searchOffersAsync",
  async (params, thunkAPI) => {
    try {
      thunkAPI.dispatch(clearOffers());
      const config = {
        headers: {
          ...variables.xsrfToken,
          Authorization: `Bearer ${localStorage.getItem("currentToken")}`,
          Accept: "application/json",
        },
        withCredentials: true,
      };
      const response = await axios.get(
        `${variables.getBackend}/api/v1/offers/search/?${params ?? ""}`,
        config
      );
      thunkAPI.dispatch(setMetaData(response.data.meta));
      return { data: response.data.data };
    } catch (error: any) {
      thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchOfferAsync = createAsyncThunk<any, string>(
  "catalog/fetchOfferAsync",
  async (offerId, thunkAPI) => {
    try {
      const config = {
        headers: {
          ...variables.xsrfToken,
          Authorization: `Bearer ${localStorage.getItem("currentToken")}`,
          Accept: "application/json",
        },
        withCredentials: true,
      };
      let offer = await axios.get(
        `${variables.getBackend}/api/v1/offers/${offerId}`,
        config
      );
      return offer.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteOfferAsync = createAsyncThunk<any, string>(
  "catalog/deleteOfferAsync",
  async (offerId, thunkAPI) => {
    try {
      const config = {
        headers: {
          ...variables.xsrfToken,
          Authorization: `Bearer ${localStorage.getItem("currentToken")}`,
          Accept: "application/json",
        },
        withCredentials: true,
      };
      await axios.delete(
        `${variables.getBackend}/api/v1/offers/${offerId}`,
        config
      );
      return { offerId: offerId, success: true };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.data });
    }
  }
);

export const duplicateOfferAsync = createAsyncThunk<any, string>(
  "catalog/duplicateOfferAsync",
  async (offerId, thunkAPI) => {
    try {
      const response = await axios({
        method: "post",
        url: `${variables.getBackend}/api/v1/offers/${offerId}/duplicate`,
        headers: {
          ...variables.xsrfToken,
          Authorization: `Bearer ${localStorage.getItem("currentToken")}`,
          Accept: "application/json",
          "Accept-Language": i18next.language,
        },
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const catalogSlice = createSlice({
  name: "catalog",
  initialState: offersAdapter.getInitialState<CatalogState>({
    offers: [],
    status: "idle",
    offersLoaded: false,
    offerLoaded: false,
    data: [],
    offerAdding: false,
    filterValue: "",
    catalogError: "",
    metaData: null,
  }),
  reducers: {
    setPageNumber: (state, action) => {
      state.offersLoaded = false;
      // state.dispPage = action.payload;
    },
    clearOffers: (state) => {
      offersAdapter.removeAll(state);
      state.offersLoaded = false;
      state.metaData = null;
    },
    setMetaData: (state, action) => {
      state.metaData = action.payload;
    },
    resetCatalogError: (state) => {
      state.catalogError = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchOffersAsync.fulfilled, (state, action) => {
      offersAdapter.setAll(state, action.payload.data);
      state.status = "idle";
      state.offersLoaded = true;
    });
    builder.addCase(searchOffersAsync.rejected, (state, action) => {
      state.offersLoaded = true;
    });
    builder.addCase(fetchOfferAsync.pending, (state) => {
      state.status = "pendingFetchOffers";
      state.offerAdding = true;
    });
    builder.addCase(fetchOfferAsync.fulfilled, (state, action) => {
      offersAdapter.setOne(state, action.payload.data);
      state.status = "idle";
      state.offerLoaded = true;
    });
    builder.addCase(createOfferAsync.pending, (state) => {
      state.status = "pendingAddOffer";
      state.offerAdding = true;
    });
    builder.addCase(createOfferAsync.fulfilled, (state) => {
      state.status = "idle";
      state.offerAdding = false;
      state.offerLoaded = false;
    });
    builder.addCase(updateOfferAsync.fulfilled, (state, action) => {
      offersAdapter.setOne(state, action.payload.data);
      state.status = "idle";
    });
    builder.addCase(createOfferAsync.rejected, (state, action) => {
      state.status = "idle";
      state.catalogError = action.error.message;
      state.offerAdding = false;
    });
    builder.addCase(deleteOfferAsync.pending, (state) => {
      state.status = "pendingDeleteOffer";
    });
    builder.addCase(deleteOfferAsync.fulfilled, (state, action) => {
      offersAdapter.removeOne(state, action.payload.offerId);
      state.status = "idle";
    });
    builder.addCase(duplicateOfferAsync.pending, (state) => {
      state.status = "pendingDuplicateOffer";
    });
    builder.addCase(duplicateOfferAsync.fulfilled, (state, action) => {
      offersAdapter.setOne(state, action.payload.data);
      state.status = "idle";
    });
    builder.addCase(updateOfferAsync.pending, (state) => {
      state.status = "pendingUpdateOffer";
    });
    builder.addMatcher(isAnyOf(searchOffersAsync.pending), (state) => {
      state.offersLoaded = false;
      state.status = "pendingSearchOffers";
    });
    builder.addMatcher(
      isAnyOf(
        searchOffersAsync.rejected,
        fetchOfferAsync.rejected,
        createOfferAsync.rejected,
        deleteOfferAsync.rejected,
        duplicateOfferAsync.rejected,
        updateOfferAsync.rejected
      ),
      (state, action) => {
        if (typeof action.payload === "string") {
          state.catalogError = action.payload;
        }
        state.status = "idle";
      }
    );
  },
});

export const { setPageNumber, setMetaData, resetCatalogError, clearOffers } =
  catalogSlice.actions;
