import { MdPerson } from "react-icons/md";
import "./auth-poster.scss";
import { useTranslation } from "react-i18next";
import Login from "components/views/LoginRegister/Login";
import Signup from "components/views/LoginRegister/Signup";

interface Props {
  message: string;
}

export const AuthPoster = ({ message }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="auth-poster">
      <div className="auth-poster-row">
        <MdPerson /> <span>{message}</span>
      </div>
      <span className="auth-section">
        <Login
          Component={() => (
            <span className="auth-section-element">{t("login:login")}</span>
          )}
        />
        <span>/</span>
        <Signup
          Component={() => (
            <span className="auth-section-element">
              {t("login:registration")}
            </span>
          )}
        />
      </span>
    </div>
  );
};
