import React, { useEffect, useState } from "react";
import { useFormDispatch } from "../../store/configureStore";
import { Controller, useForm } from "react-hook-form";
import { login } from "./accountSlice";
import logo from "../../../images/logo2.webp";
import Dialog from "@mui/material/Dialog";
import "./loginregister.scss";
import RemindPassword from "./RemindPassword";
import { EMAIL_PATTERN } from "utils/constants";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { Box, Button, FormControl, TextField } from "@mui/material";
import InputPassword from "components/reusable/InputPassword";

interface Props {
  Component: () => JSX.Element;
}

interface LoginParams {
  email: string;
  password: string;
}

const Login = ({ Component }: Props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openPassword, setOpenPassword] = React.useState(false);

  const form = useForm<LoginParams>({
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors, isValid, isSubmitted },
  } = form;
  const dispatchForm = useFormDispatch(form);

  const submitForm = async (data: LoginParams) => {
    await dispatchForm(login(data));
  };
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handlePasswordOpen = () => setOpenPassword(true);
  const handlePasswordClose = () => setOpenPassword(false);

  useEffect(() => {
    !open && reset();
  }, [reset, open]);

  return (
    <>
      <span onClick={handleClickOpen}>
        <Component />
      </span>
      <Dialog open={open} onClose={handleClose}>
        <div className="login-box">
          <img src={logo} alt="logo realn" />
          <h2>{t("login:welcome")}</h2>
          <FormControl component={"form"} onSubmit={handleSubmit(submitForm)}>
            <Controller
              name={"email"}
              control={control}
              rules={{
                required: t("validation:field-required"),
                pattern: {
                  value: EMAIL_PATTERN,
                  message: t("validation:field-invalid"),
                },
              }}
              render={({ field }) => (
                <TextField
                  label={t("login:email")}
                  variant="outlined"
                  autoComplete="username"
                  size="small"
                  type="email"
                  error={Boolean(errors[field.name])}
                  helperText={errors[field.name]?.message}
                  fullWidth
                  sx={{ mb: 3 }}
                  {...field}
                />
              )}
            />
            <Controller
              name={"password"}
              control={control}
              rules={{
                required: t("validation:field-required"),
              }}
              render={({ field }) => (
                <InputPassword
                  label={t("login:password")}
                  variant="outlined"
                  size="small"
                  autoComplete="current-password"
                  error={Boolean(errors[field.name])}
                  helperText={errors[field.name]?.message}
                  sx={{ mb: 1 }}
                  fullWidth
                  {...field}
                />
              )}
            />
            <Box alignSelf={"end"}>
              <Button
                variant="text"
                size="small"
                onClick={() => handlePasswordOpen()}
                sx={{ mb: 5 }}
              >
                {t("login:forget-password")}
              </Button>
            </Box>
            <LoadingButton
              type="submit"
              size="large"
              loading={isSubmitting}
              disabled={!isValid && isSubmitted}
              sx={{ minWidth: "200px", mx: "auto" }}
            >
              {!isSubmitting && t("login:log-in")}
            </LoadingButton>
          </FormControl>
        </div>
      </Dialog>
      <RemindPassword
        openPassword={openPassword}
        handlePasswordClose={handlePasswordClose}
      />
    </>
  );
};

export default Login;
