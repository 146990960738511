import "./profile.scss";
import { useEffect, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
  useFormDispatch,
} from "components/store/configureStore";
import i18next from "i18n";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import ProfileNav from "./ProfileNav";
import avatar from "images/avatar.webp";
import {
  forgotPassword,
  getUserProfile,
  updateUserProfile,
} from "../LoginRegister/accountSlice";
import { BsFillPencilFill } from "react-icons/bs";
import Loader from "../utils/Loader";
import { Controller, useForm } from "react-hook-form";
import { UserProfile, UserProfileFormValues } from "components/models/user";
import { shallowEqual } from "react-redux";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { DATE_FORMAT } from "utils/constants";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import Select from "components/reusable/LazySelect";

const getUserProfileValues: (
  user: UserProfile | null
) => UserProfileFormValues = (user) => ({
  firstname: user?.firstname ?? "",
  lastname: user?.lastname ?? "",
  gender: user?.gender?.id ?? "",
  language: user?.language?.id ?? "",
  date_of_birth: user?.date_of_birth ? dayjs(user.date_of_birth) : null,
});

const Profile = () => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.account.user, shallowEqual);
  const userProfile = useAppSelector(
    (state) => state.account.userProfile,
    shallowEqual
  );
  const status = useAppSelector((state) => state.account.status);
  const table = useAppSelector((state) => state.utility.table);
  const tableLoaded = useAppSelector((state) => state.utility.tableLoaded);
  const [imgPrev, setImgPrev] = useState("");
  const [resetPasswordInfo, setResetPasswordInfo] = useState<{
    type: "success" | "error";
    message: string;
  } | null>(null);
  const navigate = useNavigate();

  const isLoadingUserProfileData = status.includes("pendingGetUserProfile");
  const isLoadingResetPassword = status.includes("pendingForgotPassword");
  const userProfileDataLoaded =
    userProfile &&
    "firstname" in userProfile &&
    "lastname" in userProfile &&
    "date_of_birth" in userProfile &&
    "gender" in userProfile &&
    "language" in userProfile;

  const hookForm = useForm<UserProfileFormValues>({
    defaultValues: getUserProfileValues(userProfile),
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = hookForm;
  const formDispatch = useFormDispatch(hookForm);
  const dispatch = useAppDispatch();
  const isLoading = status.includes("pendingUpdateUserProfile");

  const handleClickResetPassword = async (e: any) => {
    e.preventDefault();
    if (resetPasswordInfo) setResetPasswordInfo(null);

    /**
     * This is just a temporary solution until we introduce dedicated
     * endpoint for changing password through user profile.
     *
     * The /auth/forgot-password endpoint may respond with 302 cors error
     * which still means that the request was processed succesfully, but the
     * redirection back to the frontend site failed due to cors error.
     *
     * Therefore we ignore the error here and assume that the reset pasword email
     * has been sent to the user's email address succesfully.
     */

    if (!user?.email) return;
    dispatch(forgotPassword({ email: user.email })).then((response) => {
      if (
        response.type.endsWith("/rejected") &&
        response.payload !== "Network Error"
      ) {
        return setResetPasswordInfo({
          type: "error",
          message:
            (response.payload as string) || t("profile:reset-password-error"),
        });
      }
      setResetPasswordInfo({
        type: "success",
        message:
          (response.payload as string) || t("profile:reset-password-success"),
      });
    });
  };

  function handleImageChange(e: any) {
    if (e.target.files.length > 0) {
      const objectURL = URL.createObjectURL(e.target.files[0]);
      setImgPrev(objectURL);
    } else {
      setImgPrev(userProfile?.photo ?? avatar);
    }
  }

  const handleUpdateProfile = async (data: UserProfileFormValues) => {
    const formData = new FormData();
    formData.append("firstname", data.firstname);
    formData.append("lastname", data.lastname);
    formData.append("language", data.language);
    formData.append("gender", data.gender);
    formData.append(
      "date_of_birth",
      dayjs.isDayjs(data.date_of_birth)
        ? data.date_of_birth.format(DATE_FORMAT)
        : ""
    );

    if (data.photo) {
      formData.append("photo", data.photo);
    }

    await formDispatch(updateUserProfile({ formData }));
  };

  useEffect(() => {
    if (!userProfile || !userProfileDataLoaded) return;

    reset(getUserProfileValues(userProfile));

    if (userProfile.language) {
      i18next.changeLanguage(userProfile.language.id);
      navigate("/profile");
    }
  }, [userProfile, userProfileDataLoaded, reset, dispatch, navigate]);

  useEffect(() => {
    if (userProfileDataLoaded) return;
    dispatch(getUserProfile());
  }, [dispatch, userProfileDataLoaded]);

  return (
    <>
      <div className="profile-container">
        <ProfileNav />
        <div className="profile-page">
          <form
            onSubmit={handleSubmit(handleUpdateProfile)}
            className="profile-box"
          >
            <div className="profile-info">
              <h1>{t("profile:hi", { name: user?.name ?? "user" })} </h1>
              <div className="profile-pic">
                <img
                  src={imgPrev || (userProfile?.photo ?? avatar)}
                  alt="profile pic"
                ></img>
                <Controller
                  control={control}
                  name="photo"
                  render={({ field }) => (
                    <div className="profile-edit">
                      <label htmlFor="fileInput">
                        <BsFillPencilFill />
                      </label>
                      <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id="fileInput"
                        type="file"
                        onChange={(e) => {
                          handleImageChange(e);
                          field.onChange(e.target.files?.[0]);
                        }}
                      />
                    </div>
                  )}
                />
              </div>
              <div className="profile-data">
                <p>{userProfile?.firstname}</p>
                <p>{userProfile?.lastname}</p>
                <p>{user?.email}</p>
                <LoadingButton
                  type="submit"
                  size="large"
                  onClick={handleClickResetPassword}
                  loading={isLoadingResetPassword}
                  fullWidth
                >
                  {!isLoadingResetPassword && t("profile:reset")}
                </LoadingButton>
                <p
                  className={classNames(
                    "reset-password-info",
                    resetPasswordInfo?.type
                  )}
                >
                  {resetPasswordInfo?.message}
                </p>
              </div>
            </div>
            <div className="profile-details">
              <Controller
                control={control}
                name="firstname"
                disabled={isLoadingUserProfileData}
                render={({ field }) => (
                  <TextField
                    sx={{ marginBottom: 1 }}
                    variant="outlined"
                    label={t("profile:name")}
                    error={Boolean(errors[field.name])}
                    helperText={errors[field.name]?.message}
                    {...field}
                  />
                )}
              />
              <Controller
                control={control}
                name="lastname"
                disabled={isLoadingUserProfileData}
                render={({ field }) => (
                  <TextField
                    sx={{ marginBottom: 1 }}
                    variant="outlined"
                    label={t("profile:lastName")}
                    error={Boolean(errors[field.name])}
                    helperText={errors[field.name]?.message}
                    {...field}
                  />
                )}
              />
              <Controller
                control={control}
                name="date_of_birth"
                disabled={isLoadingUserProfileData}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disableFuture
                      label={t("profile:birth")}
                      sx={{ marginBottom: 1 }}
                      slotProps={{
                        textField: {
                          error: Boolean(errors[field.name]),
                          helperText: errors[field.name]?.message,
                        },
                      }}
                      format={DATE_FORMAT}
                      {...field}
                    />
                  </LocalizationProvider>
                )}
              />
              <Controller
                control={control}
                name="gender"
                disabled={isLoadingUserProfileData}
                render={({ field: { value, ...field } }) => (
                  <FormControl>
                    <InputLabel id="gender-select">
                      {t("profile:gender")}
                    </InputLabel>
                    <Select
                      labelId="gender-select"
                      label={t("profile:gender")}
                      sx={{ marginBottom: 1 }}
                      displayEmpty
                      error={Boolean(errors[field.name])}
                      value={
                        table.Gender.find(({ id }) => value === id) ? value : ""
                      }
                      loading={!tableLoaded}
                      {...field}
                    >
                      <MenuItem value="" sx={{ opacity: 0.5 }}>
                        {t("profile:gender")}
                      </MenuItem>
                      {table.Gender.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error>
                      {errors[field.name]?.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />
              <Controller
                control={control}
                name="language"
                disabled={isLoadingUserProfileData}
                render={({ field: { value, ...field } }) => (
                  <FormControl>
                    <InputLabel id="lang-select">
                      {t("profile:lang")}
                    </InputLabel>
                    <Select
                      labelId="lang-select"
                      label={t("profile:lang")}
                      sx={{ marginBottom: 1 }}
                      displayEmpty
                      error={Boolean(errors[field.name])}
                      value={
                        table.Language.find(({ id }) => value === id)
                          ? value
                          : ""
                      }
                      loading={!tableLoaded}
                      {...field}
                    >
                      <MenuItem value="" sx={{ opacity: 0.5 }}>
                        {t("profile:lang")}
                      </MenuItem>
                      {table.Language.map(
                        (item: { id: string; name: string }) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        )
                      )}
                    </Select>
                    <FormHelperText error>
                      {errors[field.name]?.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />
              <LoadingButton
                type="submit"
                size="large"
                loading={isLoading}
                fullWidth
                sx={{ mt: 6 }}
              >
                {!isLoading && t("profile:save")}
              </LoadingButton>
            </div>
            {!userProfileDataLoaded && (
              <div className="profile-loader">
                <Loader />
              </div>
            )}
          </form>
        </div>
      </div>
      {/* Do not remove! To be implemented in the nearest future */}
      {/* <ResetPasswordDialog
        openReset={openReset}
        handleResetClose={handleResetClose}
      /> */}
    </>
  );
};

export default Profile;
