import { SearchOffersParams } from "components/models/offer";
import { TableElement } from "components/models/table";
import { useAppSelector } from "components/store/configureStore";
import { UtilityState } from "components/store/utilitySlice";

export type LookupTableSearchParam = keyof Omit<
  SearchOffersParams,
  | "min_price"
  | "max_price"
  | "min_size"
  | "max_size"
  | "location"
  | "searchString"
  | "title"
  | "others"
  | "page"
  | "page_size"
  | "order"
  | "order_by"
>;

export const searchParamToTableKey: Record<
  LookupTableSearchParam,
  keyof UtilityState["table"]
> = {
  buildType: "Build",
  type: "OfferType",
  rooms: "Room",
  floor: "Floor",
  loudness: "Loudness",
  installation: "Installation",
  furniture: "Furniture",
  condition: "Condition",
  direction: "Direction",
  amenity: "Amenity",
  medium: "Medium",
  parking: "Parking",
  energy: "Energy",
  window: "Window",
  kitchen: "Kitchen",
  bathroom: "Bathroom",
  health: "Health",
  recreation: "Recreation",
  education: "Education",
};

const useLookupTable = () => {
  const table = useAppSelector((state) => state.utility.table);
  const tableLoaded = useAppSelector((state) => state.utility.tableLoaded);

  const getLookupNameFromSearchParam = (
    key: LookupTableSearchParam | "location",
    id: TableElement["id"]
  ) => {
    if (!tableLoaded) return id;

    if (key === "location") {
      const [city, district] = id.split(",");
      const cityObj = table.City.find((el) => el.id === city);
      const districtObj = table.District.find((el) => el.id === district);

      if (!cityObj) return id;

      if (!district || !districtObj) return cityObj.name;

      return `${cityObj.name}, ${districtObj.name}`;
    }

    if (!searchParamToTableKey[key as LookupTableSearchParam]) return id;

    return (
      table[searchParamToTableKey[key as LookupTableSearchParam]].find(
        (el) => el.id === id
      )?.name ?? id
    );
  };

  return { isLoading: !tableLoaded, table, getLookupNameFromSearchParam };
};

export default useLookupTable;
