import { motion } from "framer-motion";
import { Box } from "@mui/system";
import { MouseEventHandler } from "react";
import { Button } from "@mui/material";

interface Props {
  options: [{ id: string; name: string }];
  value?: string[];
  onChange: (e: string[]) => void;
}

const ButtonGroup = ({ options, value, onChange }: Props) => {
  const handleChange: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    const id = (e.target as HTMLButtonElement).value;
    if (value?.includes(id))
      return onChange(value?.filter((val) => val !== id));

    return onChange([...(value || []), id]);
  };

  return (
    <Box display="flex" flexWrap={"wrap"} gap={1}>
      {options.map(({ id, name }) => (
        <Button
          key={id}
          size="large"
          component={motion.button}
          color={value?.includes(id) ? "selected" : "secondary"}
          whileTap={{ scale: 0.75 }}
          value={id}
          sx={{ fontWeight: 400 }}
          onClick={handleChange}
        >
          {name}
        </Button>
      ))}
    </Box>
  );
};

export default ButtonGroup;
