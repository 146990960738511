import {
  CircularProgress,
  InputAdornment,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import { useTranslation } from "react-i18next";

interface LazySelectProps<Value> extends SelectProps<Value> {
  loading?: boolean;
}

const LazySelect = <T,>({
  loading,
  children,
  ...props
}: LazySelectProps<T>) => {
  const { t } = useTranslation();

  return (
    <Select
      {...props}
      {...(loading && {
        endAdornment: (
          <InputAdornment position="end">
            <CircularProgress size={20} color="inherit" />
          </InputAdornment>
        ),
        IconComponent: () => null,
      })}
    >
      {loading && (
        <MenuItem value="" disabled>
          {t("app:loading")}
        </MenuItem>
      )}
      {!loading && children}
    </Select>
  );
};

export default LazySelect;
