import "./propertyDetails.scss";
import { Button, Grid } from "@mui/material";
import { Params, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/configureStore";
import { offersSelectors } from "components/store/selectors";
import PropertySlider from "./propComponents/PropertySlider";
import PropNav from "./propComponents/PropNav";
import PropInfo from "./propComponents/PropInfo";
import PropWidget from "./propComponents/PropWidget";
import { fetchOfferAsync } from "components/views/offers/catalogSlice";
import { useTranslation } from "react-i18next";
import DeleteOfferButton from "./propComponents/DeleteOfferButton";
import EditOfferButton from "./propComponents/EditOfferButton";
import DuplicationOfferButton from "./propComponents/DuplicationOfferButton";

function PropertyDetails() {
  const { t } = useTranslation();
  const { id }: Readonly<Params<string>> = useParams();
  const { offerLoaded } = useAppSelector((state) => state.catalog);
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.account.user?.id);

  const offer = useAppSelector((state) =>
    offersSelectors.selectById(state, id!)
  );

  useEffect(() => {
    dispatch(fetchOfferAsync(id!));
  }, [userId]);

  useEffect(() => {
    if (offer === undefined || !offerLoaded) dispatch(fetchOfferAsync(id!));
  }, [offerLoaded, id, offer, dispatch]);

  if (!offer) return <h1>{t("buy:loading")}...</h1>;

  return (
    <div className="property-details">
      <PropNav offer={offer} />
      <article className="about-box">
        <article className="text-box">
          <PropertySlider offer={offer} />
          <h1 id="about">{offer.title}</h1>

          {/* {offer.agent && (
            <>
              <p>Dodano przez: {offer.agent.name}</p>
              <p> {offer.agent.phone}</p>
              <p>{offer.agent.email}</p>
            </>
          )} */}
          <PropInfo offer={offer} />
          <Grid
            container
            sx={{ display: { xs: "flex", sm: "none" } }}
            direction="row"
            justifyContent="center"
            alignItems="center"
            rowGap={2}
          >
            <Grid item xs={12}>
              <EditOfferButton offer={offer} />
            </Grid>
            <Grid item xs={12}>
              <DuplicationOfferButton offer={offer} />
            </Grid>
            <Grid item xs={12}>
              <DeleteOfferButton offer={offer} />
            </Grid>
          </Grid>
        </article>
        <PropWidget offer={offer} />
      </article>
    </div>
  );
}

export default PropertyDetails;
