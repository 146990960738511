import { AlertColor } from "@mui/material";
import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import axios from "axios";
import { City } from "components/models/offer";
import { Table } from "components/models/table";
import variables from "variables";
import i18next from "i18n";

export interface Notification {
  message: string;
  severity?: AlertColor;
}

export interface UtilityState {
  page: "buy" | "sell";
  isUserDrawerOpen: boolean;
  status: string;
  table: Table;
  tableLoaded: boolean;
  availableLocations: City[];
  backendCommitHash: string | null;
  utilityError: string | null;
  notification: Notification | null;
}

const initialState: UtilityState = {
  page: "buy",
  isUserDrawerOpen: false,
  status: "idle",
  table: {
    OfferType: [{ id: "", name: "" }],
    Language: [{ id: "", name: "" }],
    Gender: [{ id: "", name: "" }],
    Amenity: [{ id: "", name: "" }],
    Bathroom: [{ id: "", name: "" }],
    Build: [{ id: "", name: "" }],
    Buy: [{ id: "", name: "" }],
    City: [{ id: "", name: "", country: "" }],
    Condition: [{ id: "", name: "" }],
    Direction: [{ id: "", name: "" }],
    District: [{ id: "", name: "", city_id: "" }],
    Education: [{ id: "", name: "" }],
    Energy: [{ id: "", name: "" }],
    Floor: [{ id: "", name: "" }],
    Furniture: [{ id: "", name: "" }],
    Health: [{ id: "", name: "" }],
    Installation: [{ id: "", name: "" }],
    Kitchen: [{ id: "", name: "" }],
    Loudness: [{ id: "", name: "" }],
    Material: [{ id: "", name: "" }],
    Market: [{ id: "", name: "" }],
    Medium: [{ id: "", name: "" }],
    OthersNearby: [{ id: "", name: "" }],
    Parking: [{ id: "", name: "" }],
    Recreation: [{ id: "", name: "" }],
    Room: [{ id: "", name: "" }],
    Sort: [{ id: "", name: "" }],
    State: [{ id: "", name: "" }],
    Transport: [{ id: "", name: "" }],
    Window: [{ id: "", name: "" }],
  },
  tableLoaded: false,
  availableLocations: [],
  backendCommitHash: null,
  utilityError: null,
  notification: null,
};

export const getLookupTable = createAsyncThunk(
  "utility/getLookupTable",
  async (_, thunkAPI) => {
    try {
      const response = await axios({
        method: "get",
        url: `${variables.getBackend}/api/v1/lookuptable/`,
        headers: {
          ...variables.xsrfToken,
          Accept: "application/json",
          "Accept-Language": i18next.language,
        },
        withCredentials: true,
      });
      return { data: response.data };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const getAvailableLocations = createAsyncThunk(
  "utility/getAvailableLocations",
  async (_, thunkAPI) => {
    try {
      const response = await axios({
        method: "get",
        url: `${variables.getBackend}/api/v1/lookuptable/city?r=1`,
        headers: {
          ...variables.xsrfToken,
          Accept: "application/json",
        },
        withCredentials: true,
      });
      return { data: response.data.city };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const getBackendCommitHash = createAsyncThunk(
  "utility/getBackendCommitHash",
  async (_, thunkAPI) => {
    try {
      const response = await axios({
        method: "get",
        url: `${variables.getBackend}/api/v1/git-commit`,
        headers: {
          ...variables.xsrfToken,
          Accept: "application/json",
        },
        withCredentials: true,
      });
      return response.data.hash;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const utilitySlice = createSlice({
  name: "utility",
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setIsUserDrawerOpen: (state, action) => {
      state.isUserDrawerOpen = action.payload;
    },
    resetUtilityError: (state) => {
      state.utilityError = null;
    },
    showNotification: (state, action: { payload: Notification }) => {
      state.notification = {
        message: action.payload.message,
        severity: action.payload.severity ?? "info",
      };
    },
    resetNotification: (state) => {
      state.notification = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLookupTable.pending, (state) => {
        state.table = initialState.table;
        state.tableLoaded = false;
        state.status = "pendingLookupTable";
      })
      .addCase(getLookupTable.rejected, (state, action) => {
        state.table = initialState.table;
      })
      .addCase(getLookupTable.fulfilled, (state, action) => {
        state.table = action.payload.data;
        state.status = "idle";
        state.tableLoaded = true;
      })
      .addCase(getAvailableLocations.pending, (state) => {
        state.status = "pendingLookupTable";
      })
      .addCase(getAvailableLocations.rejected, (state, action) => {
        state.availableLocations = initialState.availableLocations;
      })
      .addCase(getAvailableLocations.fulfilled, (state, action) => {
        state.availableLocations = action.payload.data;
        state.status = "idle";
      })
      .addCase(getBackendCommitHash.pending, (state) => {
        state.status = "pendingBackendCommitHash";
      })
      .addCase(getBackendCommitHash.rejected, (state, action) => {
        state.backendCommitHash = initialState.backendCommitHash;
      })
      .addCase(getBackendCommitHash.fulfilled, (state, action) => {
        state.backendCommitHash = action.payload;
        state.status = "idle";
      });
    builder.addMatcher(
      isAnyOf(
        getLookupTable.rejected,
        getAvailableLocations.rejected,
        getBackendCommitHash.rejected
      ),
      (state, action) => {
        if (typeof action.payload === "string") {
          state.utilityError = action.payload;
        }
        state.status = "idle";
      }
    );
  },
});

export const {
  setPage,
  setIsUserDrawerOpen,
  resetUtilityError,
  showNotification,
  resetNotification,
} = utilitySlice.actions;
