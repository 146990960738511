export const EMAIL_PATTERN = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const PASSWORD_PATTERN =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>]).{6,80}$/;
export const VALUE_SUFFIX: Record<string, string | JSX.Element> = {
  price: "zł",
  size: (
    <>
      m<sup>2</sup>
    </>
  ),
};
export const DATE_FORMAT = "YYYY-MM-DD";

export const USER_IMAGE_REPLACER = "https://placehold.co/400";

export const PRICE_FORMAT = /\B(?=(\d{3})+(?!\d))/g;
