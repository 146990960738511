import { BoxProps } from "@mui/material";

const markdownStyles: BoxProps["sx"] = (theme) => ({
  ".markdown-editor": {
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: "0.375rem",
    '[role="toolbar"]': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  ".markdown-editor-content": {
    minHeight: "300px",
    fontFamily: theme.typography.fontFamily,
    li: {
      ...theme.typography.caption,
      my: 0,
    },
    p: {
      ...theme.typography.caption,
      mt: 0,
      mb: 2,
    },
    h1: {
      ...theme.typography.h1,
      fontSize: 36,
      my: 6,
    },
    h2: {
      ...theme.typography.h2,
      fontSize: 32,
      my: 5,
    },
    h3: {
      ...theme.typography.h3,
      fontSize: 30,
      my: 4,
    },
    h4: {
      ...theme.typography.h4,
      fontSize: 24,
      my: 3,
    },
    h5: {
      ...theme.typography.h5,
      fontSize: 20,
      my: 2,
    },
    h6: {
      ...theme.typography.h6,
      fontSize: 16,
      my: 2,
    },
    "h1, h2, h3, h4, h5, h6": {
      fontWeight: 600,
      color: `${theme.palette.text.primary} !important`,
      border: "none !important",
    },
  },
});

export default markdownStyles;
