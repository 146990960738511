import "./main.scss";
import EstateSlider from "../utils/EstateSlider";
import pic2 from "../../../images/back6.webp";
import pic5 from "../../../images/back3.webp";
import SmallContact from "../utils/SmallContact";
import Searcher from "../utils/Searcher/Searcher";
import { useAppDispatch } from "components/store/configureStore";
import { useEffect } from "react";
import { searchOffersAsync } from "../offers/catalogSlice";

import { useTranslation } from "react-i18next";
import SearchWithState from "../utils/Searcher/SearchWithState";
import { Link as RouterLink } from "react-router-dom";
import { Grid, Link } from "@mui/material";

const Main = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(searchOffersAsync(""));
  }, []);

  return (
    <>
      <div className="main-header">
        <Grid
          container
          mt={{
            xs: 50,
            md: 10,
          }}
        >
          <Grid item xs={11} sm={9} m={"0 auto"}>
            <header className="main-head-box">
              <h1>{t("main:technology-driven")}</h1>
              <div className="input-holder">
                <Searcher />
              </div>
            </header>
          </Grid>
        </Grid>
      </div>
      <article className="main-page">
        <article className="main-first">
          <img className="main-img" src={pic5} alt="home for sell"></img>
          <aside>
            <h1>
              {t("main:sell-apartments")} <br />
              {t("main:quickly-and-conveniently")}
            </h1>
            <p>
              {t("main:agents-help-you")}
              <br /> {t("main:start-from")}
            </p>
            <aside className="main-top-searcher-box">
              {/* <input type="text" placeholder={t("main:enter-email")}></input>
              <p>{t("main:next")}</p> */}
              {/* <a className="main-top-searcher-content">
                {t("main:contact")}
              </a> */}
              <Link component={RouterLink} to="/contact" variant="button">
                {t("main:contact")}
              </Link>
            </aside>
          </aside>
        </article>
        <article className="main-first">
          <aside>
            <h1>
              {t("main:dream-apartment")} <br /> {t("main:awaits")}
            </h1>
            <p>{t("main:find-own-corner")}</p>
            <SearchWithState />
          </aside>
          <img className="main-img" src={pic2} alt="home for rent"></img>
        </article>
      </article>
      <article className="slider-title">
        <h1> {t("main:check-apartments")}:</h1>
      </article>
      <EstateSlider />
      <SmallContact />
    </>
  );
};

export default Main;
