import { IconButton, SxProps, darken } from "@mui/material";
import { IconType } from "react-icons";
import {
  MdArrowBack,
  MdArrowDownward,
  MdArrowForward,
  MdArrowUpward,
  MdDelete,
  MdOutlineHouse,
} from "react-icons/md";

type ButtonVariant =
  | "move-up"
  | "move-right"
  | "move-down"
  | "move-left"
  | "set-featured"
  | "delete";

interface OfferImagesOverlayButtonProps {
  variant: ButtonVariant;
  disabled?: boolean;
  onClick?: () => void;
}

const Icons: Record<ButtonVariant, IconType> = {
  "move-up": MdArrowUpward,
  "move-right": MdArrowForward,
  "move-down": MdArrowDownward,
  "move-left": MdArrowBack,
  "set-featured": MdOutlineHouse,
  delete: MdDelete,
};

const styles: Record<ButtonVariant, SxProps> = {
  "move-up": {
    top: "5px",
    left: "50%",
    transform: "translateX(-50%)",
  },
  "move-right": {
    top: "50%",
    right: "5px",
    transform: "translateY(-50%)",
  },
  "move-down": {
    bottom: "5px",
    left: "50%",
    transform: "translateX(-50%)",
  },
  "move-left": {
    top: "50%",
    left: "5px",
    transform: "translateY(-50%)",
  },
  "set-featured": {
    bottom: "5px",
    left: "5px",
  },
  delete: {
    bottom: "5px",
    right: "5px",
  },
};

const OfferImagesOverlayButton = ({
  variant,
  disabled,
  onClick,
}: OfferImagesOverlayButtonProps) => {
  const Icon = Icons[variant];
  return (
    <IconButton
      sx={{
        backgroundColor: "grey.200",
        color: "common.white",
        borderRadius: "50%",
        position: "absolute",
        ...styles[variant],
        "&:hover": {
          backgroundColor: (theme) => darken(theme.palette.grey[200], 0.2),
        },
        "&:disabled": {
          backgroundColor: (theme) => `
            ${theme.palette.grey[200]} !important
          `,
          color: "grey.100",
          opacity: 0.4,
        },
      }}
      disabled={disabled}
      onClick={onClick}
    >
      <Icon />
    </IconButton>
  );
};

export default OfferImagesOverlayButton;
