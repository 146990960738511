import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Checkbox,
  Chip,
  CircularProgress,
  List,
  SxProps,
  TextField,
  Typography,
} from "@mui/material";
import { TableCity } from "components/models/table";
import { useAppSelector } from "components/store/configureStore";
import React, {
  Children,
  forwardRef,
  useMemo,
  useReducer,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { MdExpandMore } from "react-icons/md";

interface LocationSelectOption {
  id: string;
  name: string;
  city_id: string;
}

interface LocationSelectProps {
  value: string[];
  onChange: (value: string[]) => void;
  label?: string;
  multiple?: boolean;
  limitTags?: number;
  size?: "small" | "medium";
  sx?: SxProps;
  error?: string;
  required?: boolean;
  allowAllDistricts?: boolean;
}

const parseLocationSelectOptionToLocationString = (
  option?: LocationSelectOption
) => {
  if (!option) return "";
  if (isCityOption(option)) return option.city_id;

  return `${option.city_id},${option.id}`;
};

const isCityOption = (option: LocationSelectOption) =>
  option.id.startsWith("city-");

const getOptionFullName = (option: LocationSelectOption, city?: TableCity) => {
  if (!city) return option.name;
  return `${city.name}, ${option.name}`;
};

const LocationSelect = forwardRef(
  (
    {
      value,
      onChange,
      multiple,
      label,
      required,
      limitTags = 2,
      size = "medium",
      sx,
      allowAllDistricts = true,
      error,
    }: LocationSelectProps,
    ref
  ) => {
    const { t } = useTranslation();
    const cities = useAppSelector((state) => state.utility.table.City);
    const cityOptions = allowAllDistricts
      ? cities.map((city) => ({
          id: `city-${city.id}`,
          name: t("utils:location-select-all-districts"),
          city_id: city.id,
        }))
      : ([] as LocationSelectOption[]);
    const districtOptions = useAppSelector(
      (state) => state.utility.table.District as LocationSelectOption[]
    );
    const isTableLoaded = useAppSelector((state) => state.utility.tableLoaded);

    const [expandedGroups, handleClickGroup] = useReducer(
      (
        state: LocationSelectOption["id"][],
        groupId: LocationSelectOption["id"]
      ) => {
        if (!groupId) return [];
        if (state.includes(groupId)) {
          return state.filter((id) => id !== groupId);
        } else {
          return [...state, groupId];
        }
      },
      []
    );

    const inputLabel = label ?? t("utils:location-select-default-label");
    const [inputValue, setInputValue] = useState("");

    const options: LocationSelectOption[] = isTableLoaded
      ? [...cityOptions.concat(districtOptions)].sort(
          (a: LocationSelectOption, b: LocationSelectOption) => {
            if (a.city_id < b.city_id) {
              return -1;
            } else if (a.city_id > b.city_id) {
              return 1;
            } else {
              return 0;
            }
          }
        )
      : [];

    const parsedValue = useMemo(
      () =>
        value
          .map((option) => {
            if (!option) return null;
            const [cityId, districtId] = option.split(",");
            const city = cities.find((c) => c.id === cityId);

            if (!city) return null;
            if (!districtId)
              return cityOptions.find((c) => c.city_id === cityId)!;

            return districtOptions.find(
              (d) => d.id === districtId && d.city_id === cityId
            );
          })
          .filter((v) => v !== null),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [value]
    );

    const handleChange = (
      event: React.SyntheticEvent<Element, Event>,
      newValue: LocationSelectOption[]
    ) => {
      const value = Array.isArray(newValue)
        ? newValue.map(parseLocationSelectOptionToLocationString)
        : [parseLocationSelectOptionToLocationString(newValue)];
      onChange(value);
    };

    const handleClose = () => handleClickGroup("");

    return (
      <Autocomplete
        multiple={multiple}
        loading={!isTableLoaded}
        size={size}
        fullWidth
        options={options}
        placeholder={inputLabel}
        getOptionLabel={(option) => {
          if (Array.isArray(option)) {
            if (!option[0]) return "";
            const city = cities.find((city) => city.id === option[0].city_id)!;
            if (isCityOption(option[0])) return city.name;
            return getOptionFullName(option[0], city);
          }
          return option.name;
        }}
        groupBy={(option: any) => option.city_id}
        renderGroup={(params) => {
          const city = cities.find((city) => city.id === params.group)!;
          const districts = districtOptions.filter(
            (d) => d.city_id === city.id
          );
          const childrenLength = Children.count(params.children);
          const isExpanded =
            expandedGroups.includes(params.group) ||
            (Boolean(inputValue) &&
              districts.some(
                (d) =>
                  d.name
                    .toLowerCase()
                    .includes(inputValue.trim().toLowerCase()) ||
                  getOptionFullName(d, city)
                    .toLowerCase()
                    .includes(inputValue.trim().toLowerCase())
              ) &&
              childrenLength < 3);

          return (
            <Accordion
              key={params.group}
              expanded={isExpanded}
              onChange={() => handleClickGroup(params.group)}
              elevation={0}
            >
              <AccordionSummary expandIcon={<MdExpandMore />}>
                <Typography>{city.name}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                <List>{params.children}</List>
              </AccordionDetails>
            </Accordion>
          );
        }}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox checked={selected || Boolean(props["aria-disabled"])} />
            {option.name}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={inputLabel}
            required={required}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {!isTableLoaded ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            helperText={error}
            error={Boolean(error)}
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            const city = cities.find((city) => city.id === option.city_id)!;
            const label = isCityOption(option)
              ? city.name
              : getOptionFullName(option, city);
            return <Chip {...getTagProps({ index })} label={label} />;
          })
        }
        limitTags={limitTags}
        disableCloseOnSelect={Boolean(multiple)}
        value={
          !multiple && Array.isArray(parsedValue) && !parsedValue.length
            ? null
            : parsedValue
        }
        getOptionDisabled={(option) =>
          value.includes(option.city_id) && option.id !== option.city_id
        }
        isOptionEqualToValue={(option, value) => {
          return multiple ? option.id === value.id : option.id === value[0]?.id;
        }}
        filterOptions={(options, params) =>
          options.filter((option) => {
            const optionCity = cities.find((c) => c.id === option.city_id);
            return (
              option.name
                .trim()
                .toLowerCase()
                .includes(params.inputValue.trim().toLowerCase()) ||
              (optionCity &&
                getOptionFullName(option, optionCity)
                  .trim()
                  .toLowerCase()
                  .includes(params.inputValue.trim().toLowerCase()))
            );
          })
        }
        onHighlightChange={(event, option) => {
          if (!option && expandedGroups.length) handleClickGroup("");
          if (option && !expandedGroups.includes(option.city_id))
            handleClickGroup(option.city_id);
        }}
        onInputChange={(event, value) => setInputValue(value)}
        onChange={handleChange}
        onClose={handleClose}
        sx={sx}
        className="location-select"
        ref={ref}
      />
    );
  }
);

export default LocationSelect;
