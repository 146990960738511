import { useAppSelector } from "components/store/configureStore";
import React, { ReactElement, ReactNode } from "react";
import { UserRoleEnum } from "utils/enums";

interface UserRoleFeatureFlagProps {
  allowedRoles: UserRoleEnum[];
  children: ReactElement;
}

const UserRoleFeatureFlag: React.FC<UserRoleFeatureFlagProps> = ({
  allowedRoles,
  children,
}: UserRoleFeatureFlagProps) => {
  const user = useAppSelector((state) => state.account.user);

  if (
    !user ||
    !("roles" in user) ||
    !allowedRoles.some((role) =>
      user.roles.find((userRole) => userRole.name === role)
    )
  )
    return null;

  return children;
};

export default UserRoleFeatureFlag;
