import { Paper } from "@mui/material";
import { PropsWithChildren } from "react";

const FormSection = ({ children }: PropsWithChildren) => {

  return (
    <Paper
      elevation={0}
      sx={{
        fontSize: "36px",
        background: theme => theme.palette.common.white,
        mb: 6,
        mx: 4,
        borderRadius: 2,
        p: "50px 35px",
      }}
    >
      {children}
    </Paper>
  );
};

export default FormSection;
