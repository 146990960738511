import { PaletteColorOptions, Theme, createTheme } from "@mui/material";
import MuiButton from "./MuiButton";
import MuiFormLabel from "./MuiFormLabel";
import MuiIconButton from "./MuiIconButton";
import MuiLink from "./MuiLink";

declare module "@mui/material/styles" {
  interface Palette {
    selected?: PaletteColorOptions;
  }
  interface PaletteOptions {
    selected?: PaletteColorOptions;
  }
  interface TypographyVariantsOptions {
    captionBold: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    captionBold: true;
  }
}

const theme: Theme = createTheme({
  typography: {
    fontFamily: "Verdana",
    h1: {
      fontSize: "36px",
      marginBottom: "45px",
    },
    caption: {
      color: "grey",
      fontSize: 12,
    },
    captionBold: {
      color: "grey",
      fontSize: 12,
      fontWeight: 700,
    },
  },
  palette: {
    primary: {
      main: "#070751",
      light: "#4396ca",
      dark: "#06063a",
    },
    secondary: {
      main: "#595959",
      dark: "#4f4f4f",
      light: "#eaeaea7b",
    },
    grey: {
      "100": "#f2f4f5",
      "200": "#c5c5c5cc",
    },
    error: {
      main: "#d90429",
    },
    selected: {
      main: "#eaeaea7b",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 1024,
      lg: 1200,
      xl: 1440,
    },
  },
  spacing: 5,
  components: {
    MuiButton,
    MuiFormLabel,
    MuiIconButton,
    MuiLink,
  },
});

export default theme;
