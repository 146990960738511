import { Theme, darken } from "@mui/material";
import { OverridesStyleRules } from "@mui/material/styles/overrides";

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    selected: boolean;
  }
  interface ButtonPropsType {
    color: {
      selected: boolean;
    };
  }
}

const MuiButton: OverridesStyleRules = {
  defaultProp: {
    size: "large",
    withArrow: false,
  },
  styleOverrides: {
    root: {
      borderRadius: "4px",
      border: "none",
      display: "flex",
      minWidth: "52px",
      boxShadow: "4px 7px 31px 0px rgba(147, 147, 147, 0.3)",
      svg: {
        fontSize: "22px",
      },
      fontWeight: 400,
    },
  },
  variants: [
    {
      props: { size: "large" },
      style: ({ theme }: { theme: Theme }) => ({
        padding: "15px",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontSize: "14px",
        minHeight: "55px",
        textTransform: "initial",
        whiteSpace: "nowrap",

        "&:hover": {
          boxShadow: "none",
          backgroundColor: theme.palette.primary.dark,
        },
      }),
    },
    {
      props: { size: "medium" },
      style: ({ theme }: { theme: Theme }) => ({
        padding: "8px 15px",
        height: "40px",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        textTransform: "initial",

        "&:hover": {
          boxShadow: "none",
          backgroundColor: theme.palette.primary.dark,
        },
      }),
    },
    {
      props: { color: "secondary" },
      style: ({ theme }: { theme: Theme }) => ({
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.secondary.dark,

        "&:hover": {
          boxShadow: "none",
          color: theme.palette.common.white,
          backgroundColor: theme.palette.secondary.dark,
        },
      }),
    },
    {
      props: { color: "selected" },
      style: ({ theme }: { theme: Theme }) => ({
        backgroundColor: theme?.palette?.primary?.light,
        color: theme.palette.common.white,

        "&:hover": {
          backgroundColor: theme?.palette?.primary?.light,
        },
      }),
    },
    {
      props: { color: "error" },
      style: ({ theme }: { theme: Theme }) => ({
        backgroundColor: theme?.palette?.error?.main,
        color: theme.palette.common.white,

        "&:hover": {
          backgroundColor: darken(theme?.palette?.error?.main, 0.1),
        },
      }),
    },
    {
      props: { disabled: true },
      style: ({ theme }: { theme: Theme }) => ({
        "&&&": {
          backgroundColor: theme.palette.secondary.dark,
          color: theme.palette.common.white,
          svg: {
            color: theme.palette.common.white,
          },
        },
      }),
    },
    {
      props: { size: "small", variant: "text" },
      style: () => ({
        boxShadow: "none",
        background: "transparent",
        borderRadius: 0,
      }),
    },
  ],
};

export default MuiButton;
