import { HiOutlineMagnifyingGlass } from "react-icons/hi2";
import { FormEventHandler } from "react";
import LocationSelect from "components/reusable/LocationSelect";
import { IconButton } from "@mui/material";

interface Props {
  handleSubmit: FormEventHandler<HTMLFormElement>;
  location: string[];
  setLocation: any;
}

export default function SmallSearcher({
  handleSubmit,
  location,
  setLocation,
}: Props) {
  return (
    <form className="small-searcher-form" onSubmit={handleSubmit}>
      <LocationSelect
        value={location}
        onChange={setLocation}
        multiple
        size="small"
        sx={{ backgroundColor: "white" }}
        limitTags={1}
      />
      {/* <button type="submit">
        <HiOutlineMagnifyingGlass className="main-glass-icon" />
      </button> */}
      <IconButton type="submit">
        <HiOutlineMagnifyingGlass />
      </IconButton>
    </form>
  );
}
