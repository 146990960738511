import { AsyncThunk } from "@reduxjs/toolkit";
import {
  useAppDispatch,
  useAppSelector,
} from "components/store/configureStore";
import { fetchOfferAsync } from "components/views/offers/catalogSlice";
import { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { UserRoleEnum } from "utils/enums";

type SupportedResources = "offer"; // can add more resources here as an union type eg. "offer" | "message"
interface AuthLevelProps {
  level: "auth";
}

interface RoleLevelProps {
  level: "role";
  role: UserRoleEnum;
}

interface OwnerLevelProps {
  level: "resource-owner";
  resource: SupportedResources;
}

type ProtectedRouteProps = AuthLevelProps | OwnerLevelProps | RoleLevelProps;

function isAuthLevel(props: ProtectedRouteProps): props is AuthLevelProps {
  return props.level === "auth";
}

function isRoleLevel(props: ProtectedRouteProps): props is RoleLevelProps {
  return props.level === "role";
}

function isOwnerLevel(props: ProtectedRouteProps): props is OwnerLevelProps {
  return props.level === "resource-owner";
}

const resources: Record<SupportedResources, AsyncThunk<any, string, any>> = {
  offer: fetchOfferAsync,
};

const ProtectedRoute = (props: ProtectedRouteProps) => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();
  const userToken = localStorage.getItem("currentToken");
  const user = useAppSelector((state) => state.account.user);
  const [validated, setValidated] = useState(false);

  const setProtect = () => {
    if (isAuthLevel(props)) {
      if (!userToken) return navigate("/");
      if (!user) return;

      setValidated(true);
    }

    if (isRoleLevel(props)) {
      if (!user || !("roles" in user)) return;
      if (!user.roles.find((r) => r.name === props.role)) return navigate("/");

      setValidated(true);
    }

    if (isOwnerLevel(props)) {
      if (!("id" in params) || !params.id) return navigate("/");
      dispatch(resources[props.resource](params.id)).then((response) => {
        if (!user || user.id !== response.payload.data.user.id)
          return navigate("/");
        setValidated(true);
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(setProtect, [user, userToken]);

  if (!validated) return null;

  return <Outlet />;
};

export default ProtectedRoute;
