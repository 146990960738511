import { Dialog, IconButton, TextField } from "@mui/material";
import TooltipComponent from "components/reusable/Tooltip/Tooltip";
import { FaShare } from "react-icons/fa";
import i18next from "i18n";
import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import "./propShare.scss";
import { showNotification } from "components/store/utilitySlice";
import { useAppDispatch } from "components/store/configureStore";

export default function PropShare() {
  const [open, setOpen] = useState(false);
  const t = i18next.t;
  const dispatch = useAppDispatch();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    navigator.clipboard.writeText(window.location.href);
    dispatch(
      showNotification({
        message: t("offers:clipboard"),
        severity: "success",
      })
    );
  };

  return (
    <>
      <IconButton sx={{ padding: 0.5 }} onClick={handleOpen}>
        <TooltipComponent
          WrappedComponent={<FaShare />}
          message={t("offers:share")}
        />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: "1000px",
            maxWidth: "1000px",
            minHeight: "300px",
            borderRadius: "25px",
            padding: "25px",
          },
        }}
      >
        <div className="share-dialog-box">
          <div className="share-dialog-title">
            <p> {t("offers:share")}</p>
            <AiOutlineClose onClick={handleClose} />
          </div>
          <div className="share-dialog-content">
            <TextField
              label={t("offers:link")}
              defaultValue={window.location.href}
              InputProps={{
                readOnly: true,
              }}
            />
            <button onClick={handleClick}>{t("offers:copy")}</button>
          </div>
        </div>
      </Dialog>
    </>
  );
}
