import React, { useEffect, useState } from "react";
import "./searcher.scss";
import TextField from "@mui/material/TextField";
import { HiOutlineMagnifyingGlass } from "react-icons/hi2";
import MenuItem from "@mui/material/MenuItem";
import { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import getAxiosParams from "../getParams";
import { SearchOffersParams } from "components/models/offer";
import { useAppSelector } from "components/store/configureStore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Checkbox from "@mui/material/Checkbox";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import parseUrlParameters from "../FormatUrl";
import { Table, TableElement } from "components/models/table";
import LocationSelect from "components/reusable/LocationSelect";
import { useTranslation } from "react-i18next";
import { Box, Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Select from "components/reusable/LazySelect";

interface Props {
  expanded?: boolean;
}
const initParams: SearchOffersParams = {
  title: "",
  buildType: "",
  type: "",
  location: [],
  min_price: "",
  max_price: "",
  min_size: "",
  max_size: "",
  rooms: "",
  floor: "",
  loudness: "",
  installation: "",
  furniture: "",
  condition: "",
  direction: "",
  amenity: [""],
  medium: [""],
  parking: [""],
  energy: [""],
  window: "",
  kitchen: "",
  bathroom: [""],
  health: [""],
  recreation: [""],
  education: [""],
  others: [""],
};

const firstColumn = [
  {
    id: "floor",
    name: "Floor",
  },
  {
    id: "loudness",
    name: "Loudness",
  },
  {
    id: "installation",
    name: "Installation",
  },
  {
    id: "furniture",
    name: "Furniture",
  },
  {
    id: "condition",
    name: "Condition",
  },
  {
    id: "direction",
    name: "Direction",
  },
];

const secondColumn = [
  {
    id: "amenity",
    name: "Amenity",
  },
  {
    id: "medium",
    name: "Medium",
  },
  {
    id: "parking",
    name: "Parking",
  },
  {
    id: "energy",
    name: "Energy",
  },
];

const thirdColumn = [
  {
    id: "bathroom",
    name: "Bathroom",
  },
  {
    id: "health",
    name: "Health",
  },
  {
    id: "recreation",
    name: "Recreation",
  },
  {
    id: "education",
    name: "Education",
  },
  {
    id: "others",
    name: "OthersNearby",
  },
];

const Searcher = ({ expanded }: Props) => {
  const { params } = useParams();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { table, tableLoaded } = useAppSelector((state) => state.utility);
  const { offersLoaded } = useAppSelector((state) => state.catalog);
  const navigate = useNavigate();
  const [parsedParams, setParsedParams] = useState(initParams);
  const isLoading = !offersLoaded && pathname !== "/";

  const resetFilters = () => {
    setParsedParams(initParams);
  };

  const handleChangeMultipleArray = (event: SelectChangeEvent<string[]>) => {
    const { name, value } = event.target;

    setParsedParams((prevParams) => ({
      ...prevParams,
      [name]: typeof value === "string" ? value.split(",") : value,
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    navigate(`/search/${getAxiosParams({ ...parsedParams, page: 1 })}`);
  };

  const setParamsOnInit = () => {
    setParsedParams({
      ...parsedParams,
      ...parseUrlParameters(params),
    });
  };

  // Wait for lookup tables if site is refreshed with parameters in url
  useEffect(setParamsOnInit, []);

  return (
    <form className="searcher-form" onSubmit={handleSubmit}>
      <div className="searcher-form-row">
        <TextField
          type="text"
          label={t("search:title")}
          className="text-input"
          id="title"
          variant="outlined"
          value={parsedParams.title}
          onChange={(e) =>
            setParsedParams({ ...parsedParams, title: e.target.value })
          }
        />
        <FormControl sx={{ flex: 1 }}>
          <InputLabel id="build-type">{t("search:build-type")}</InputLabel>
          <Select
            label={t("search:build-type")}
            className="select-input"
            id="build-type"
            variant="outlined"
            value={parsedParams.buildType}
            onChange={(e) =>
              setParsedParams({ ...parsedParams, buildType: e.target.value })
            }
            loading={!tableLoaded}
          >
            <MenuItem value="">{t("search:default")}</MenuItem>
            {table.Build.map((element) => (
              <MenuItem key={element.id} value={element.id}>
                {element.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ flex: 1 }}>
          <InputLabel id="sell-or-rent">{t("search:type")}</InputLabel>
          <Select
            label={t("search:type")}
            className="select-input"
            id="sell-or-rent"
            variant="outlined"
            value={parsedParams.type}
            onChange={(e) =>
              setParsedParams({ ...parsedParams, type: e.target.value })
            }
            loading={!tableLoaded}
          >
            <MenuItem value="">{t("search:default")}</MenuItem>
            {table.OfferType.map((element) => (
              <MenuItem key={element.id} value={element.id}>
                {element.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <LocationSelect
            value={parsedParams.location ?? []}
            onChange={(value) =>
              setParsedParams({ ...parsedParams, location: value })
            }
            multiple
            sx={{ maxWidth: "100%" }}
            limitTags={1}
          />
        </FormControl>
        <LoadingButton
          type="submit"
          size="large"
          loading={isLoading}
          sx={{ display: { xs: "none", md: "flex" }, height: "100%" }}
        >
          {!isLoading && <HiOutlineMagnifyingGlass />}
        </LoadingButton>
      </div>
      <div className="searcher-form-row">
        <TextField
          type="number"
          label={t("search:min-price")}
          className="text-input"
          id="min-price"
          variant="outlined"
          value={parsedParams.min_price}
          onChange={(e) =>
            setParsedParams({ ...parsedParams, min_price: e.target.value })
          }
        />
        <span className="fade-on-mid">-</span>
        <TextField
          type="number"
          label={t("search:max-price")}
          className="text-input"
          id="max-price"
          variant="outlined"
          value={parsedParams.max_price}
          onChange={(e) =>
            setParsedParams({ ...parsedParams, max_price: e.target.value })
          }
        />
        <TextField
          type="number"
          label={t("search:min-size")}
          className="text-input"
          id="min-surface"
          variant="outlined"
          value={parsedParams.min_size}
          onChange={(e) =>
            setParsedParams({ ...parsedParams, min_size: e.target.value })
          }
        />
        <span className="fade-on-mid">-</span>
        <TextField
          type="number"
          label={t("search:max-size")}
          className="text-input"
          id="max-surface"
          variant="outlined"
          value={parsedParams.max_size}
          onChange={(e) =>
            setParsedParams({ ...parsedParams, max_size: e.target.value })
          }
        />
        {expanded && (
          <>
            <FormControl sx={{ flex: 1 }}>
              <InputLabel id="rooms">{t("search:rooms")}</InputLabel>
              <Select
                label={t("search:rooms")}
                className="select-input"
                id="rooms"
                variant="outlined"
                value={parsedParams.rooms}
                onChange={(e) =>
                  setParsedParams({ ...parsedParams, rooms: e.target.value })
                }
                loading={!tableLoaded}
              >
                <MenuItem value="">{t("search:default")}</MenuItem>
                {table.Room.map((element) => (
                  <MenuItem key={element.id} value={element.id}>
                    {element.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <LoadingButton
              type="submit"
              size="large"
              startIcon={!isLoading && <HiOutlineMagnifyingGlass />}
              loading={isLoading}
              sx={{ display: { md: "none" } }}
            >
              {!isLoading && t("premium:search")}
            </LoadingButton>
            <Button
              type="reset"
              size="large"
              onClick={resetFilters}
              sx={{ display: { xs: "none", md: "flex" } }}
            >
              {t("search:clear-filters")}
            </Button>
          </>
        )}
      </div>
      {expanded && (
        <Accordion className="extra-filters">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className="summary"
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{t("search:filters")}</Typography>
          </AccordionSummary>
          <AccordionDetails className="filters">
            <div className="grid-column">
              {firstColumn.map((element) => (
                <FormControl key={element.id}>
                  <InputLabel id={element.id}>
                    {t(`search:${element.id}`)}
                  </InputLabel>
                  <Select
                    label={t(`search:${element.id}`)}
                    className="select-input"
                    id={element.id}
                    variant="outlined"
                    value={parsedParams[element.id as keyof SearchOffersParams]}
                    onChange={(e) =>
                      setParsedParams({
                        ...parsedParams,
                        [element.id]: e.target.value,
                      })
                    }
                    loading={!tableLoaded}
                  >
                    <MenuItem value="">{t("search:default")}</MenuItem>
                    {table[element.name as keyof Table].map(
                      (item: TableElement) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              ))}
            </div>
            <hr />
            <div className="grid-column">
              {secondColumn.map((element) => (
                <FormControl key={element.id}>
                  <InputLabel id={element.id}>
                    {t(`search:${element.id}`)}
                  </InputLabel>{" "}
                  <Select
                    label={t(`search:${element.id}`)}
                    multiple
                    className="select-input"
                    id={element.id}
                    variant="outlined"
                    name={element.id}
                    value={
                      (parsedParams[
                        element.id as keyof SearchOffersParams
                      ] as string[]) || []
                    }
                    renderValue={(selected) => selected.join(", ")}
                    onChange={handleChangeMultipleArray}
                    loading={!tableLoaded}
                  >
                    {table[element.name as keyof Table].map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        <Checkbox
                          checked={
                            (
                              parsedParams[
                                element.id as keyof SearchOffersParams
                              ] as string[]
                            )?.indexOf(item.id) > -1
                          }
                        />
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ))}
              <FormControl>
                <InputLabel id="window">{t("search:window")}</InputLabel>
                <Select
                  label={t("search:window")}
                  className="select-input"
                  id="window"
                  variant="outlined"
                  value={parsedParams.window}
                  onChange={(e) =>
                    setParsedParams({ ...parsedParams, window: e.target.value })
                  }
                  loading={!tableLoaded}
                >
                  <MenuItem value="">{t("search:default")}</MenuItem>
                  {table.Window.map((element) => (
                    <MenuItem key={element.id} value={element.id}>
                      {element.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <hr />
            <div className="grid-column">
              <FormControl>
                <InputLabel id="kitchen">{t("search:kitchen")}</InputLabel>
                <Select
                  label={t("search:kitchen")}
                  className="select-input"
                  id="kitchen"
                  variant="outlined"
                  value={parsedParams.kitchen}
                  onChange={(e) =>
                    setParsedParams({
                      ...parsedParams,
                      kitchen: e.target.value,
                    })
                  }
                  loading={!tableLoaded}
                >
                  <MenuItem value="">{t("search:default")}</MenuItem>
                  {table.Kitchen.map((element) => (
                    <MenuItem key={element.id} value={element.id}>
                      {element.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {thirdColumn.map((element) => (
                <FormControl key={element.id}>
                  <InputLabel id={element.id}>
                    {t(`search:${element.id}`)}
                  </InputLabel>
                  <Select
                    label={t(`search:${element.id}`)}
                    multiple
                    className="select-input"
                    id={element.id}
                    variant="outlined"
                    name={element.id}
                    value={
                      (parsedParams[
                        element.id as keyof SearchOffersParams
                      ] as string[]) || []
                    }
                    renderValue={(selected) => selected.join(", ")}
                    onChange={handleChangeMultipleArray}
                    loading={!tableLoaded}
                  >
                    {table[element.name as keyof Table].map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        <Checkbox
                          checked={
                            (
                              parsedParams[
                                element.id as keyof SearchOffersParams
                              ] as string[]
                            )?.indexOf(item.id) > -1
                          }
                        />
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      )}
      <Box
        display={"flex"}
        gap={2}
        sx={!expanded ? { display: { xs: "flex", md: "none" }, gap: 2 } : {}}
      >
        {!expanded && (
          <LoadingButton
            type="submit"
            size="large"
            startIcon={!isLoading && <HiOutlineMagnifyingGlass />}
            loading={isLoading}
            sx={{ display: { md: "none" }, flex: 1 }}
          >
            {!isLoading && t("premium:search")}
          </LoadingButton>
        )}
        <Button
          type="reset"
          size="large"
          onClick={resetFilters}
          sx={{
            display: {
              xs: "block",
              md: "none",
            },
            ...(!expanded
              ? {
                  flex: 1,
                }
              : {}),
          }}
        >
          {t("search:clear-filters")}
        </Button>
      </Box>
    </form>
  );
};

export default Searcher;
