import "./contact.scss";
import variables from "../../../variables.js";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();

  return (
    <>
      <header className="contact-header">
        <article>
          <h1>{t("main:contact-us")}</h1>
          <p>
            {t("main:we-are-disposal")} <br />
            {t("main:open-time")}
          </p>
        </article>
      </header>
      <div className="contact-box">
        <div className="phone-box">
          <i className="fa-solid fa-phone"></i>
          <a href={`tel: ${variables.realnPhone}`}>{variables.realnPhone}</a>
          <p>{t("main:call-us")}</p>
        </div>
        <div className="message-box">
          <i className="fa-regular fa-message"></i>
          <a href={`mailto: ${variables.realnEmail}`}>
            {t("main:write-to-us")}
          </a>
          <p>{t("main:customer-service")}</p>
        </div>
        <div className="connect-box">
          <i className="fa-solid fa-share-nodes"></i>
          <h3>{t("main:find-us")}</h3>
          <div className="contact-icons-holder">
            <a href={variables.realnFacebook}>
              <i className="fa-brands fa-facebook"></i>
            </a>
            <a href={variables.realnInstagram}>
              <i className="fa-brands fa-instagram"></i>
            </a>

            <a href={variables.realnLinkedin}>
              <i className="fa-brands fa-linkedin"></i>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
