import { Box } from "@mui/system";
import { FormEventHandler, PropsWithChildren } from "react";

interface Props {
  onSubmit: FormEventHandler<HTMLFormElement>;
}

const Form = ({ children, onSubmit }: Props & PropsWithChildren) => (
  <Box onSubmit={onSubmit} component={"form"}>
    {children}
  </Box>
);

export default Form;
