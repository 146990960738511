import { useAppSelector } from "components/store/configureStore";
import ProfileNav from "./ProfileNav";
import { useTranslation } from "react-i18next";

const Tokens = () => {
  const { t } = useTranslation();
  const { tokenList } = useAppSelector((state) => state.account);

  return (
    <div className="profile-container">
      <ProfileNav />
      <div className="profile-page tokens-page">
        <h1> {t("profile:tokens")} </h1>
        {/* <button onClick={handleListTokens}> {t("profile:list")} </button> */}
        <ul>
          {tokenList &&
            tokenList
              .filter((token) => token.used_at == null)
              .map((token: any) => (
                <li key={token.id}>
                  {token.token}
                  <button
                    onClick={() => navigator.clipboard.writeText(token.token)}
                  >
                    {t("profile:copy")}
                  </button>
                </li>
              ))}
        </ul>

        {/* <div className="new-token-box">
          <button onClick={handleCreateToken}> {t("profile:create")} </button>
          {newToken && (
            <div>
              <p> {t("profile:new")} {newToken}</p>
              <button onClick={() => navigator.clipboard.writeText(newToken)}>
              {t("profile:copy")} 
              </button>
            </div>
          )}
        </div> */}
      </div>
    </div>
  );
}

export default Tokens;