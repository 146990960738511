import { Avatar, Dialog } from "@mui/material";
import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { ResponseOffer } from "components/models/offer";
import { createContactRequestsAsync } from "components/views/LoginRegister/accountSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "components/store/configureStore";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { usePhoneInput } from "react-international-phone";
import { USER_IMAGE_REPLACER } from "utils/constants";
import { UserPreferences } from "components/models/user";
import { TFunction } from "i18next";
import { LoadingButton } from "@mui/lab";

interface MessageDialogProps {
  open: boolean;
  handleClose: () => void;
  offer: ResponseOffer;
}

interface MessageDialogFormValues {
  messageInput: string;
}

const getDefaultValues: (
  userPreferences: UserPreferences | null,
  t: TFunction
) => MessageDialogFormValues = (userPreferences, t) => ({
  messageInput: t("buy:default-message", {
    email: userPreferences?.email,
    phone: userPreferences?.phone,
  }),
});

export default function MessageDialog({
  open,
  handleClose,
  offer,
}: Readonly<MessageDialogProps>) {
  const userPreferences = useAppSelector(
    (state) => state.account.userPreferences
  );
  const accountError = useAppSelector((state) => state.account.accountError);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { inputValue: agentPhone } = usePhoneInput({
    value: offer.user?.phone || undefined,
  });
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: getDefaultValues(userPreferences, t),
  });

  const onSubmit: SubmitHandler<MessageDialogFormValues> = (formdata) => {
    setIsLoading(true);
    const data = {
      message: formdata.messageInput,
      customer_email: userPreferences?.email,
      customer_phone: userPreferences?.phone,
    };

    dispatch(createContactRequestsAsync({ data, offerId: offer.id })).then(
      () => {
        setIsLoading(false);
      }
    );
  };

  // Close modal if sending message operation succed without an error
  useEffect(() => {
    if (!isLoading && !accountError) {
      reset(getDefaultValues(userPreferences, t));
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, accountError]);

  useEffect(() => {
    reset(getDefaultValues(userPreferences, t));
  }, [reset, t, userPreferences]);

  return (
    <Dialog
      className="dialog"
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        sx: {
          width: "1000px",
          maxWidth: "1000px",
          minHeight: "500px",
          borderRadius: "25px",
          padding: "25px",
        },
      }}
    >
      <div className="message-dialog__container">
        <div className="message-dialog__title">
          <h3>{t("buy:ask-a-quest")}</h3>
          <AiOutlineClose onClick={handleClose} />
        </div>
        <div className="message-dialog-agent__box">
          <Avatar
            src={offer.user?.photo ?? USER_IMAGE_REPLACER}
            sx={{
              width: 110,
              height: 110,
            }}
          />
          <div className="message-dialog-agent__info">
            <h1>
              {offer.user?.firstname && offer.user?.lastname
                ? `${offer.user.firstname} ${offer.user.lastname}`
                : offer.user?.name}
            </h1>
            <p>{t("buy:agent")}</p>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <p className="label-4">{t("buy:message")}:</p>
          <textarea
            placeholder={t("buy:write")}
            {...register("messageInput", {
              required: t("validation:field-required"),
            })}
            className={errors.messageInput ? "textarea-error" : ""}
          />
          {errors.messageInput && (
            <p className="text-error">{errors.messageInput.message}</p>
          )}
          <div className="message-dialog">
            <div className="nextbut">
              {offer.user?.phone && (
                <h3>
                  {t("buy:text")}{" "}
                  <a href={`tel:${agentPhone}`} className="message-phone">
                    {agentPhone}
                  </a>
                </h3>
              )}
              <LoadingButton
                type="submit"
                size="large"
                loading={isLoading}
                sx={{ mt: 6, width: "190px" }}
              >
                {!isLoading && t("buy:send")}
              </LoadingButton>
            </div>
          </div>
        </form>
      </div>
    </Dialog>
  );
}
