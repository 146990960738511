import "./statue.scss";
import { useTranslation } from "react-i18next";

const Statue = () => {
  const { t } = useTranslation();

  return (
    <div className="statue-container">
      <header className="header">
        <h1>{t("statue:website-regulations")}</h1>
      </header>
      <article>
        <h2>{t("statue:general-concepts")}</h2>
        <ul>
          <li><strong>{t("statue:statue")}</strong> {t("statue:statue-def")}</li>
          <li><strong>{t("statue:service")}</strong> {t("statue:service-def-1")} &quot;RealN.pl&quot;, {t("statue:service-def-2")}</li>
          <li><strong>{t("statue:service-provider")}</strong> {t("statue:service-provider-def-1")} &quot;RealN Sp.zo.o&quot; {t("statue:service-provider-def-2")}</li>
          <li><strong>{t("statue:service-recipient")}</strong> {t("statue:service-recipient-def")}</li>
          <li><strong>{t("statue:electronic-communication")}</strong> {t("statue:electronic-communication-def")}</li>
        </ul>
        <h2>{t("statue:general-provisions")}</h2>
        <ul>
          <li>{t("statue:regulations-define")}</li>
          <li>{t("statue:the-subject-of-the-service")}</li>
          <li>{t("statue:all-possible-content")}</li>
          <li>{t("statue:service-recipent")}</li>
          <li>{t("statue:service-provider-2")}</li>
          <li>{t("statue:service-provider-3")}</li>
        </ul>
        <h2>{t("statue:terms-of-use")}</h2>
        <ul>
          <li>{t("statue:use-website")}</li>
          <li>{t("statue:service-recipients-obliged")}</li>
          <li>{t("statue:service-recipents-may-not")}</li>
          <li>
            {t("statue:technical-requirements")}
            <ul>
              <li>{t("statue:display-of-website")}</li>
              <li>{t("statue:internet-connection")}</li>
              <li>{t("statue:any-web-browser")}</li>
              <li>{t("statue:js-scripts")}</li>
              <li>{t("statue:cookies")}</li>
            </ul>
          </li>
          <li>{t("statue:safety-service-provider")} <a href="https://nety.pl/cyberbezpieczenstwo/zasady-ogolne-korzystania-z-sieci-internet/">{t("statue:online-safety-rules")}</a>,</li>
          <li>
          {t("statue:activities-performed-personally-header")}
            <ul>
              <li>{t("statue:activities-performed-personally-1")}</li>
              <li>{t("statue:activities-performed-personally-2")}</li>
              <li>{t("statue:activities-performed-personally-3")}</li>
              <li>{t("statue:activities-performed-personally-4")}</li>
              <li>{t("statue:activities-performed-personally-5")}</li>
              <li>{t("statue:activities-performed-personally-6")}</li>
            </ul>
          </li>
          <li>{t("statue:cybersecurity-incident")}</li>
        </ul>
        <h2>{t("statue:registration-terms")}</h2>
        <ul>
          <li>{t("statue:registration-terms-1")}</li>
          <li>{t("statue:registration-terms-2")}</li>
          <li>{t("statue:registration-terms-3")}</li>
          <li>{t("statue:registration-terms-4")}</li>
          <li>{t("statue:registration-terms-5")}</li>
          <li>
            {t("statue:registration-terms-6")}
            <ul>
              <li>{t("statue:registration-terms-6-1")}</li>
            </ul>
          </li>
          <li>{t("statue:registration-terms-7")}</li>
          <li>{t("statue:registration-terms-8")}</li>
          <li>{t("statue:registration-terms-9")}</li>
          <li>
            {t("statue:registration-terms-10")}
            <ul>
              <li>{t("statue:registration-terms-10-1")}</li>
              <li>{t("statue:registration-terms-10-2")}</li>
            </ul>
          </li>
          <li>
            {t("statue:registration-terms-11")}
            <ul>
              <li>{t("statue:registration-terms-11-1")}</li>
              <li>{t("statue:registration-terms-11-2")}</li>
              <li>{t("statue:registration-terms-11-2")}</li>
            </ul>
          </li>
        </ul>
        <h2>{t("statue:terms-of-provision")}</h2>
        <ul>
          <li>{t("statue:terms-of-provision-1")}</li>
          <li>{t("statue:terms-of-provision-2")}</li>
          <li>{t("statue:terms-of-provision-3")}</li>
          <li>
            {t("statue:terms-of-provision-4")}
            <ul>
              <li>{t("statue:terms-of-provision-4-1")}</li>
            </ul>
          </li>
          <li>
            {t("statue:terms-of-provision-5")}
            <ul>
              <li>{t("statue:terms-of-provision-5-1")}</li>
              <li>{t("statue:terms-of-provision-5-2")}</li>
              <li>{t("statue:terms-of-provision-5-3")}</li>
            </ul>
          </li>
          <li>
            {t("statue:terms-of-provision-6")}
            <ul>
              <li>{t("statue:terms-of-provision-6-1")}</li>
              <li>{t("statue:terms-of-provision-6-2")}</li>
            </ul>
          </li>
          <li>
            {t("statue:terms-of-provision-7")}
            <ul>
              <li>{t("statue:terms-of-provision-7-1")}</li>
              <li>{t("statue:terms-of-provision-7-2")}</li>
              <li>{t("statue:terms-of-provision-7-3")}</li>
            </ul>
          </li>
        </ul>
        <h2>{t("statue:terms-of-communication")}</h2>
        <ul>
          <li>
            {t("statue:terms-of-communication-1")}
            <ul>
              <li>{t("statue:terms-of-communication-1-1")}</li>
              <li>{t("statue:terms-of-communication-1-2")}</li>
            </ul>
          </li>
          <li>
            {t("statue:terms-of-communication-2")}
            <ul>
              <li>{t("statue:terms-of-communication-2-1")}</li>
              <li>{t("statue:terms-of-communication-2-2")}</li>
            </ul>
          </li>
          <li>{t("statue:terms-of-communication-3")}</li>
          <li>
          {t("statue:terms-of-communication-4")}
            <ul>
              <li>{t("statue:terms-of-communication-4-1")}</li>
              <li>{t("statue:terms-of-communication-4-2")}</li>
              <li>{t("statue:terms-of-communication-4-3")}</li>
              <li>{t("statue:terms-of-communication-4-4")}</li>
              <li>{t("statue:terms-of-communication-4-5")}</li>
            </ul>
          </li>
        </ul>
        <h2>{t("statue:collection-about-service")}</h2>
        <p>{t("statue:collection-about-service-p-1")}</p>
        <p>{t("statue:collection-about-service-p-2")}</p>
        <p>{t("statue:collection-about-service-p-3-1")} &#8222;<a href="https://realn.pl/rodo">{t("statue:collection-about-service-p-3-2")}</a>&#8221; {t("statue:collection-about-service-p-3-3")} &#8222;<a href="https://realn.pl/polityka-prywatnosci/">{t("statue:collection-about-service-p-3-4")}</a>&#8222;{t("statue:collection-about-service-p-3-5")}</p>
        <ul>
          <li>
            <em>{t("statue:data-collected-automatically")}</em><br />{t("statue:efficient-operation")}
            <ul>
              <li><p>{t("privacy:ip")}</p></li>
              <li><p>{t("privacy:browser-type")}</p></li>
              <li><p>{t("privacy:screen-resolution")}</p></li>
              <li><p>{t("privacy:location")}</p></li>
              <li><p>{t("privacy:opened-website")}</p></li>
              <li><p>{t("privacy:time-spent")}</p></li>
              <li><p>{t("privacy:system")}</p></li>
              <li><p>{t("privacy:last-page")}</p></li>
              <li><p>{t("privacy:current-page")}</p></li>
              <li><p>{t("privacy:language")}</p></li>
              <li><p>{t("privacy:connection-speed")}</p></li>
              <li><p>{t("privacy:internet-provider")}</p></li>
              <li>
              {t("statue:anonymous-demographic-data")}
                <ul>
                  <li>{t("statue:sex")}</li>
                  <li>{t("statue:age")}</li>
                  <li>{t("statue:interests")}</li>
                </ul>
              </li>
              <li>
              {t("statue:annoymous-data")}
                <ul>
                  <li>{t("statue:data-related-remarketing")}</li>
                </ul>
              </li>
            </ul>
            <p>{t("statue:google-analytics")}</p>
          </li>
          <li>
            <em>{t("statue:data-collected-during-registration")}</em><br />{t("statue:data-collector")}
            <p>{t("statue:logged-in-service")}</p>
          </li>
          <li><em>{t("statue:subscribing-newsletter")}</em><br />{t("statue:email")}</li>
        </ul>
        <h2>{t("statue:copyright")}</h2>
        <ul>
          <li>{t("statue:copyright-1")}</li>
          <li>{t("statue:copyright-2")}</li>
          <li>{t("statue:copyright-3")}</li>
        </ul>
        <h2>{t("statue:changes-requlations")}</h2>
        <ul>
          <li>{t("statue:changes-requlations-1")}</li>
          <li>{t("statue:changes-requlations-2")}</li>
          <li>{t("statue:changes-requlations-3")}</li>
          <li>{t("statue:changes-requlations-4")}</li>
          <li>{t("statue:changes-requlations-5")}</li>
        </ul>
        <h2>{t("statue:final-provisions")}</h2>
        <ul>
          <li>{t("statue:final-provisions-1")}</li>
          <li>{t("statue:final-provisions-2")}</li>
          <li>{t("statue:final-provisions-3")}</li>
          <li>{t("statue:final-provisions-4")}</li>
          <li>{t("statue:final-provisions-5")}</li>
          <li>{t("statue:final-provisions-6")}</li>
          <li>{t("statue:final-provisions-7")}</li>
          <li>
          {t("statue:final-provisions-8")}
            <ul>
              <li>{t("statue:final-provisions-8-1")}</li>
              <li>{t("statue:final-provisions-8-2")}</li>
              <li>{t("statue:final-provisions-8-3")}</li>
            </ul>
            {t("statue:final-provisions-8-4")}
          </li>
        </ul>
      </article>
    </div>
  );
}

export default Statue;