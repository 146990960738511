import ProfileNav from "./ProfileNav";
import "./profile.scss";
import ContactRequestsTable from "./ContactRequestsTable";
import { useTranslation } from "react-i18next";

const ContactRequests = () => {
  const { t } = useTranslation();

  return (
    <div className="profile-container">
      <ProfileNav />
      <div className="profile-page preferences-page">
        <h3>{t("profile:my-requests")} </h3>
        <ContactRequestsTable />
      </div>
    </div>
  );
}

export default ContactRequests;