import React, { forwardRef, useState } from "react";
import {
  IconButton,
  InputAdornment,
  FormControl,
  TextFieldProps,
  FormControlProps,
  OutlinedInputProps,
  TextField,
} from "@mui/material";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

interface Props {
  iconHidden?: boolean;
  isVisible?: boolean;
  onVisibleChange?: (visibility: boolean) => void;
}

const InputPassword = forwardRef(
  (
    {
      name,
      fullWidth,
      error,
      value,
      InputLabelProps,
      isVisible,
      iconHidden,
      onAnimationStart,
      onVisibleChange,
      ...props
    }: FormControlProps & OutlinedInputProps & TextFieldProps & Props,
    ref
  ) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const handleClickShowPassword = () => {
      onVisibleChange
        ? onVisibleChange(!showPassword)
        : setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      event.preventDefault();
    };

    const isVisibleInput = isVisible ?? showPassword;

    return (
      <FormControl variant="outlined" fullWidth={fullWidth} error={error}>
        <TextField
          type={isVisibleInput ? "text" : "password"}
          value={value || ""}
          fullWidth={fullWidth}
          error={error}
          onAnimationStart={(e) => onAnimationStart && onAnimationStart(e)}
          sx={{ p: 0 }}
          InputProps={{
            sx: { pr: 0 },
            endAdornment: !iconHidden && (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  sx={{ px: 3 }}
                  tabIndex={-1}
                >
                  {isVisibleInput ? (
                    <VisibilityOffOutlinedIcon fontSize="small" />
                  ) : (
                    <VisibilityOutlinedIcon color="primary" fontSize="small" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...props}
        />
      </FormControl>
    );
  }
);

export default InputPassword;
