import { useTranslation } from "react-i18next";
import SearchWithState from "./Searcher/SearchWithState";

const SmallContact = () => {
  const { t } = useTranslation();

  return (
    <article className="main-contact">
      <aside className="main-contact-text">
        <h1>{t("utils:agent-is-here-to-help")}</h1>
        <p>{t("utils:start-now")}</p>
      </aside>
      <aside className="main-contact-input">
        <p>{t("utils:where-you-search")}</p>
        <aside className="input-holder">
          <SearchWithState />
        </aside>
      </aside>
    </article>
  );
};

export default SmallContact;
