import { Avatar, Dialog } from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
import { ResponseOffer } from "components/models/offer";

import { useTranslation } from "react-i18next";
import { usePhoneInput } from "react-international-phone";
import { USER_IMAGE_REPLACER } from "utils/constants";

interface Props {
  open: boolean;
  handleClose: () => void;
  offer: ResponseOffer;
}

export default function AgentInfoDialog({ open, handleClose, offer }: Props) {
  const { t } = useTranslation();
  const { inputValue: agentPhone } = usePhoneInput({
    value: offer.user?.phone || undefined,
  });

  return (
    <Dialog
      className="agent-info-dialog"
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        sx: {
          width: "300px",
          minHeight: "400px",
          borderRadius: "25px",
          padding: "25px",
        },
      }}
    >
      <div className="agent-info-top">
        <Avatar
          src={offer.user?.photo ?? USER_IMAGE_REPLACER}
          sx={{
            width: 150,
            height: 150,
          }}
        />
        <AiOutlineClose onClick={handleClose} />
      </div>
      <h1>{offer.user?.firstname}</h1>
      <h2>{offer.user?.lastname}</h2>
      <h3>{t("buy:agent")}</h3>
      <a href={`tel: ${offer.user?.phone}`}>{agentPhone}</a>
    </Dialog>
  );
}
