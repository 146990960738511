import "./profile.scss";
import { NavLink } from "react-router-dom";
import { UserRoleEnum } from "utils/enums";
import UserRoleFeatureFlag from "components/reusable/UserRoleFeatureFlag";
import { useTranslation } from "react-i18next";

const ProfileNav = () => {
  const { t } = useTranslation();

  return (
    <div className="profile-nav">
      <NavLink
        to="/profile"
        end
        className={({ isActive }) => (isActive ? "active" : "")}
      >
        {t("profile:profile")}
      </NavLink>

      <NavLink
        to="/profile/preferences"
        className={({ isActive }) => (isActive ? "active" : "")}
      >
        {t("profile:preferences")}
      </NavLink>

      {/* <NavLink
        to="/profile/messages"
        className={({ isActive }) => (isActive ? "active" : "")}
      >
        {t("profile:messages")}
      </NavLink> */}

      <NavLink
        to="/profile/favorites"
        className={({ isActive }) => (isActive ? "active" : "")}
      >
        {t("profile:favorites")}
      </NavLink>

      <NavLink
        to="/profile/search-criterias"
        className={({ isActive }) => (isActive ? "active" : "")}
      >
        {t("profile:my-search-criterias")}
      </NavLink>

      <NavLink
        to="/profile/contact-requests"
        className={({ isActive }) => (isActive ? "active" : "")}
      >
        {t("profile:my-requests")}
      </NavLink>

      <UserRoleFeatureFlag
        allowedRoles={[UserRoleEnum.AGENT, UserRoleEnum.ADMIN]}
      >
        <NavLink
          to="/profile/offers"
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          {t("profile:offers")}
        </NavLink>
      </UserRoleFeatureFlag>

      <UserRoleFeatureFlag allowedRoles={[UserRoleEnum.ADMIN]}>
        <NavLink
          to="/profile/tokens"
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          Tokens
        </NavLink>
      </UserRoleFeatureFlag>
    </div>
  );
};

export default ProfileNav;
