import { FormHelperText, Slider, Stack } from "@mui/material";
import "../profile.scss";
import { FieldErrors } from "react-hook-form";
import { UserPreferencesFormValues } from "components/models/user";
import { useTranslation } from "react-i18next";

interface BudgetSliderProps {
  value: number[];
  onChange: (value: number[]) => void;
  errors?: FieldErrors<
    Pick<UserPreferencesFormValues, "min_budget" | "max_budget">
  >;
}

const BudgetSlider = ({
  value,
  onChange,
  errors,
}: Readonly<BudgetSliderProps>) => {
  const { t } = useTranslation();
  const hasErrors = Boolean(errors && Boolean(Object.keys(errors).length));

  return (
    <div>
      <p>{t("profile:budget")}</p>
      <Slider
        getAriaLabel={() => "Budget range"}
        value={value}
        min={0}
        step={10000}
        max={10000000}
        onChange={(_e, value) => onChange(value as number[])}
        sx={{ marginBottom: "8px", width: "90%", margin: "5px 5%" }}
        {...(hasErrors && { color: "error" })}
      />
      <div className="input-controller">
        <input
          type="number"
          value={value[0]}
          step={10000}
          onChange={(event) =>
            onChange([parseInt(event.target.value), value[1]])
          }
        ></input>
        <input
          type="number"
          value={value[1]}
          step={10000}
          onChange={(event) =>
            onChange([value[0], parseInt(event.target.value)])
          }
        ></input>
      </div>
      {hasErrors && (
        <Stack alignItems="center">
          {Object.entries(errors ?? {}).map(([key, value]) => (
            <FormHelperText error key={key}>
              {value?.message}
            </FormHelperText>
          ))}
        </Stack>
      )}
    </div>
  );
}

export default BudgetSlider;