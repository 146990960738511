import "./notification.scss";
import { Notification } from "./Notification";
import { useAppSelector } from "components/store/configureStore";
import { resetAccountError } from "components/views/LoginRegister/accountSlice";
import {
  resetUtilityError,
  resetNotification,
} from "components/store/utilitySlice";
import { resetCatalogError } from "components/views/offers/catalogSlice";
import { resetImageError } from "components/store/offerImageSlice";

export const Notifications = () => {
  const notification = useAppSelector((state) => state.utility.notification);
  const accountError = useAppSelector((state) => state.account.accountError);
  const offerImageError = useAppSelector(
    (state) => state.offerImages.offerImageError
  );
  const catalogError = useAppSelector((state) => state.catalog.catalogError);
  const utilityError = useAppSelector((state) => state.utility.utilityError);

  return (
    <div className="error-notifications">
      {notification && (
        <Notification
          message={notification.message}
          resetFunc={resetNotification}
          severity={notification.severity}
        />
      )}
      {accountError && (
        <Notification message={accountError} resetFunc={resetAccountError} />
      )}
      {utilityError && (
        <Notification message={utilityError} resetFunc={resetUtilityError} />
      )}
      {catalogError && (
        <Notification message={catalogError} resetFunc={resetCatalogError} />
      )}
      {offerImageError && (
        <Notification message={offerImageError} resetFunc={resetImageError} />
      )}
    </div>
  );
};
