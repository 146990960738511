import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Checkbox, FormHelperText } from "@mui/material";
import { useTranslation } from "react-i18next";

interface MultipleCheckboxOption {
  id: string;
  label?: string;
}
interface MultipleCheckboxProps {
  onChange: (event: SelectChangeEvent<string[]>) => void;
  options: MultipleCheckboxOption[];
  value: string[];
  label?: string;
  renderValue?: (selected: string[]) => string;
  error?: string;
}

const MultipleCheckbox = ({
  onChange,
  options,
  value,
  label,
  renderValue = (selected) =>
    selected.map((v) => options.find((o) => o.id === v)?.label ?? v).join(", "),
  error,
}: Readonly<MultipleCheckboxProps>) => {
  const { t } = useTranslation();
  const hasError = Boolean(error);

  return (
    <FormControl sx={{ margin: "20px 0px" }}>
      <InputLabel id="demo-multiple-checkbox-label">
        {t("profile:scope")}
      </InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={value}
        onChange={onChange}
        input={<OutlinedInput label={label} />}
        renderValue={renderValue}
        error={hasError}
      >
        {options.map(({ id, label }) => (
          <MenuItem key={id} value={id}>
            <Checkbox checked={value.indexOf(id) > -1} />
            <ListItemText primary={label || id} />
          </MenuItem>
        ))}
        {hasError && <FormHelperText>{error}</FormHelperText>}
      </Select>
    </FormControl>
  );
};

export default MultipleCheckbox;
