import {
  useAppDispatch,
  useAppSelector,
} from "components/store/configureStore";
import ProfileNav from "./ProfileNav";
import i18next from "i18n";
import { useEffect, useState } from "react";
import {
  fetchSearchCriteriasAsync,
  removeOfferFromFavoritesAsync,
} from "../LoginRegister/accountSlice";
import { Dialog, Skeleton, Stack } from "@mui/material";

import FavoritesTable from "./FavoritesTable";
import { FavoriteOffer } from "components/models/offer";
import { IoMdClose } from "react-icons/io";
import { useTranslation } from "react-i18next";

const Favorites = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { favoriteOffers, status, user } = useAppSelector(
    (state) => state.account
  );
  const [offerForDeletion, setOfferForDeletion] = useState<
    FavoriteOffer["id"] | null
  >(null);

  useEffect(() => {
    if (!user?.id) return;
    dispatch(fetchSearchCriteriasAsync());
  }, [user?.id, dispatch]);

  const handleDelete = async () => {
    if (!offerForDeletion || isDeleting) return;

    await dispatch(removeOfferFromFavoritesAsync(offerForDeletion));
    setOfferForDeletion(null);
  };

  const isLoading = status === "pendingGetUserFavoriteOffers";
  const isDeleting = status === "pendingRemoveOfferFromFavorites";

  const handleClickDelete = (
    e: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    e.preventDefault();
    setOfferForDeletion(id);
  };

  return (
    <div className="profile-container">
      <ProfileNav />
      <div className="profile-page">
        <h3>{t("profile:favorites")}</h3>
        {isLoading ? (
          <Stack spacing={2}>
            {Array(5)
              .fill(0)
              .map((_, i) => (
                <Skeleton key={i} variant="rounded" width="100%" height={56} />
              ))}
          </Stack>
        ) : (
          <FavoritesTable
            favoriteOffers={favoriteOffers}
            onDelete={handleClickDelete}
          />
        )}
        <Dialog
          open={!!offerForDeletion}
          onClose={() => setOfferForDeletion(null)}
          className="favorite-offer-delete-dialog"
          slotProps={{
            backdrop: {
              style: { backgroundColor: "transparent" },
            },
          }}
        >
          <div className="dialog-wrapper delete-dialog">
            <IoMdClose
              className="close-icon"
              size={20}
              onClick={() => setOfferForDeletion(null)}
            />
            <h4 className="delete-question">
              {t("profile:sure-to-delete-favorite-offer")}
            </h4>
            <div className="button-section">
              <button onClick={() => setOfferForDeletion(null)}>
                {t("profile:cancel")}
              </button>
              <button
                onClick={handleDelete}
                className="delete-button"
                disabled={isDeleting}
              >
                {t("profile:delete")}
              </button>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default Favorites;
