import { Link } from "react-router-dom";
import { Avatar, Drawer } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { setIsUserDrawerOpen } from "../../store/utilitySlice";
import { RiLogoutBoxFill, RiSettings3Fill } from "react-icons/ri";
import { ImStarFull } from "react-icons/im";
import {
  BsFillHouseDoorFill,
  BsSearchHeartFill,
  BsEnvelopeFill,
} from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import { logoutCurrentUser } from "components/views/LoginRegister/accountSlice";
import avatar from "images/avatar.webp";
import { GiToken } from "react-icons/gi";
import UserRoleFeatureFlag from "components/reusable/UserRoleFeatureFlag";
import { UserRoleEnum } from "utils/enums";
import { useTranslation } from "react-i18next";

interface Props {
  user: any;
}

const LoggedUserData = ({ user }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isUserDrawerOpen } = useAppSelector((state) => state.utility);
  const userProfile = useAppSelector((state) => state.account.userProfile);

  const toggleDrawer = () => {
    dispatch(setIsUserDrawerOpen(!isUserDrawerOpen));
  };

  const handleLogout = () => {
    toggleDrawer();
    dispatch(logoutCurrentUser());
  };

  return (
    <>
      <div className="nav-user">
        <div>
          <UserRoleFeatureFlag
            allowedRoles={[UserRoleEnum.AGENT, UserRoleEnum.ADMIN]}
          >
            <Link to="/add-new-offer" className="outlined-link">
              {t("nav:add-offer")}
            </Link>
          </UserRoleFeatureFlag>
          <Avatar
            className="profile-avatar"
            src={userProfile?.photo ?? avatar}
            sx={{
              width: 40,
              height: 40,
              mx: 1,
              cursor: "pointer",
            }}
            onClick={toggleDrawer}
          />
        </div>
      </div>
      <Drawer anchor="right" open={isUserDrawerOpen} onClose={toggleDrawer}>
        <div className="profile-drawer">
          <div className="profile-user">
            <Avatar
              className="profile-avatar"
              src={userProfile?.photo ?? avatar}
              sx={{
                width: 60,
                height: 60,
                margin: "30px 20px 10px",
              }}
            />
            <p>{userProfile?.firstname}</p>
            <p>{userProfile?.lastname}</p>
            <p className="email">{user.email}</p>
          </div>

          <Link to="/profile/" onClick={toggleDrawer} className="profile-but">
            <CgProfile />
            {t("profile:profile")}
          </Link>
          <Link
            to="/profile/preferences"
            onClick={toggleDrawer}
            className="profile-but"
          >
            <RiSettings3Fill />
            {t("profile:preferences")}
          </Link>
          {/* <Link
            to="/profile/messages"
            onClick={toggleDrawer}
            className="profile-but"
          >
            <FaMessage />
            {t("profile:messages")}
          </Link> */}
          <Link
            to="/profile/favorites"
            onClick={toggleDrawer}
            className="profile-but"
          >
            <ImStarFull />
            {t("profile:favorites")}
          </Link>
          <Link
            to="/profile/search-criterias"
            onClick={toggleDrawer}
            className="profile-but"
          >
            <BsSearchHeartFill />
            {t("profile:my-search-criterias")}
          </Link>
          <Link
            to="/profile/contact-requests"
            onClick={toggleDrawer}
            className="profile-but"
          >
            <BsEnvelopeFill />
            {t("profile:my-requests")}
          </Link>
          <UserRoleFeatureFlag
            allowedRoles={[UserRoleEnum.AGENT, UserRoleEnum.ADMIN]}
          >
            <Link
              to="/profile/offers"
              onClick={toggleDrawer}
              className="profile-but"
            >
              <BsFillHouseDoorFill />
              {t("profile:offers")}
            </Link>
          </UserRoleFeatureFlag>

          <UserRoleFeatureFlag allowedRoles={[UserRoleEnum.ADMIN]}>
            <Link
              to="/profile/tokens"
              onClick={toggleDrawer}
              className="profile-but"
            >
              <GiToken />
              {t("profile:tokens")}
            </Link>
          </UserRoleFeatureFlag>
          <p className="profile-but" onClick={handleLogout}>
            <RiLogoutBoxFill />
            {t("login:logout")}
          </p>
        </div>
      </Drawer>
    </>
  );
};

export default LoggedUserData;
