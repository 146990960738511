import { useEffect } from "react";
import ProfileNav from "./ProfileNav";
import MultipleCheckbox from "./components/MultipleCheckbox";
import BudgetSlider from "./components/BudgetSlider";
import {
  useAppDispatch,
  useAppSelector,
  useFormDispatch,
} from "components/store/configureStore";
import {
  getUserPreferences,
  updateUserPreferences,
} from "../LoginRegister/accountSlice";
import Loader from "../utils/Loader";
import {
  UserPreferences,
  UserPreferencesFormValues,
} from "components/models/user";
import { Controller, useForm } from "react-hook-form";
import { shallowEqual } from "react-redux";
import PhoneInput from "../utils/PhoneInput";
import { isPhoneNumberValid } from "utils/helpers";
import LocationSelect from "components/reusable/LocationSelect";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";

const getUserPreferencesValues: (
  preferences: UserPreferences | null
) => UserPreferencesFormValues = (preferences) => ({
  email: preferences?.email ?? "",
  phone: preferences?.phone ?? "",
  preferred_localization: preferences?.preferred_localization
    ? {
        city: preferences?.preferred_localization.city.id,
        district: preferences?.preferred_localization.district?.id ?? "",
      }
    : null,
  min_budget: preferences?.min_budget ?? 0,
  max_budget: preferences?.max_budget ?? 1000000,
  scope_of_interest: preferences?.scope_of_interest?.map(({ id }) => id) ?? [],
});

const Preferences = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userPreferences = useAppSelector(
    (state) => state.account.userPreferences,
    shallowEqual
  );
  const userPreferencesDataLoaded =
    userPreferences &&
    "email" in userPreferences &&
    "phone" in userPreferences &&
    "min_budget" in userPreferences &&
    "max_budget" in userPreferences;
  const status = useAppSelector((state) => state.account.status);
  const OfferType = useAppSelector((state) => state.utility.table.OfferType);
  const isLoading = status.includes("pendingUpdateUserPreferences");

  const hookForm = useForm<UserPreferencesFormValues>({
    defaultValues: getUserPreferencesValues(userPreferences),
    reValidateMode: "onChange",
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setValue,
  } = hookForm;
  const formDispatch = useFormDispatch(hookForm);

  useEffect(() => {
    if (!userPreferences) {
      dispatch(getUserPreferences());
      return;
    }
    reset(getUserPreferencesValues(userPreferences));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPreferences, reset]);

  const budget: number[] = watch(["min_budget", "max_budget"]);

  const handleBudgetChange = (value: number[]) => {
    setValue("min_budget", value[0]);
    setValue("max_budget", value[1]);
  };

  const handleLocationChange = (value: string[]) => {
    const [city, district] = value[0].split(",");
    const newValue = city ? { city: city, district: district } : null;

    setValue("preferred_localization", newValue);
  };

  const handleUpdatePreferences = async (data: UserPreferencesFormValues) => {
    await formDispatch(updateUserPreferences({ data }));
  };

  return (
    <div className="profile-container">
      <ProfileNav />
      <form
        onSubmit={handleSubmit(handleUpdatePreferences)}
        className="profile-page preferences-page"
      >
        <h3>{t("profile:user-pref")}</h3>
        <Controller
          control={control}
          name="phone"
          render={({ field }) => (
            <PhoneInput
              {...field}
              value={field.value as string}
              label={t("profile:phone")}
              error={Boolean(errors[field.name])}
              helperText={errors[field.name]?.message}
              sx={{ mb: 2 }}
            />
          )}
          rules={{
            validate: (value) => {
              if (!value) return true;
              return (
                isPhoneNumberValid(value as string) ||
                t("validation:invalid-phone-number")
              );
            },
          }}
        />
        <Controller
          control={control}
          name="preferred_localization"
          render={({ field }) => {
            return (
              <LocationSelect
                {...field}
                value={[
                  `${field.value?.city ?? ""}${
                    field.value?.district ? "," + field.value.district : ""
                  }`,
                ]}
                onChange={handleLocationChange}
                error={errors[field.name]?.message}
              />
            );
          }}
        />
        <BudgetSlider
          value={budget}
          onChange={handleBudgetChange}
          errors={Object.fromEntries(
            Object.entries(errors).filter(
              ([key]) => key === "min_budget" || key === "max_budget"
            )
          )}
        />
        <Controller
          control={control}
          name="scope_of_interest"
          render={({ field }) => (
            <MultipleCheckbox
              label={t("profile:scope")}
              options={OfferType.map(({ id, name }) => ({ id, label: name }))}
              error={errors[field.name]?.message}
              {...field}
            />
          )}
        />
        <LoadingButton
          type="submit"
          size="large"
          loading={isLoading}
          fullWidth
          sx={{ mt: 6 }}
        >
          {!isLoading && t("profile:save")}
        </LoadingButton>
        {!userPreferencesDataLoaded && (
          <div className="profile-loader">
            <Loader />
          </div>
        )}
      </form>
    </div>
  );
};

export default Preferences;
