import { PhoneNumberUtil } from "google-libphonenumber";
import { PRICE_FORMAT } from "./constants";

const phoneUtil = PhoneNumberUtil.getInstance();

export const isPhoneNumberValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

export const parseTwoDecimals = (str?: string) => {
  const float = str && parseFloat(str);
  if (!float || float < 0) return null;
  return float.toFixed(2);
};

export const formatPrice = (price?: string) => {
  if (price) {
    const parts = price.split(".");
    parts[0] = parts[0].replace(PRICE_FORMAT, " ");
    return parts.join(".");
  } else {
    return price;
  }
};
