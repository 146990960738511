import "./housenearby.scss";
import housebg from "../../../../images/icons/buyhouse.png";
import EstateSlider from "../../utils/EstateSlider";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "components/store/configureStore";
import { searchOffersAsync } from "components/views/offers/catalogSlice";
import { useEffect, useState } from "react";
import LocationSelect from "components/reusable/LocationSelect";
import getAxiosParams from "components/views/utils/getParams";
import { Button } from "@mui/material";

const HouseNearby = ({ type }: { type: string }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [location, setLocation] = useState([""]);
  const [min_price, setMin_price] = useState("");
  const [max_price, setMax_price] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(searchOffersAsync(`buildType=${type}`));
  }, [type]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const params = {
      location,
      min_price,
      max_price,
    };
    navigate(`/search/${getAxiosParams(params)}`);
  };

  return (
    <>
      <header className="house-search-header">
        <article>
          <h1>{t(`buy:find-${type}`)}</h1>
          <p>{t(`buy:check-${type}`)}</p>
        </article>
        <img src={housebg} alt="buy house background"></img>
      </header>
      <form className="house-search-bar" onSubmit={handleSubmit}>
        <div className="search-location">
          <p>{t("buy:location")}</p>
          <LocationSelect
            value={location}
            onChange={setLocation}
            multiple
            size="small"
            limitTags={1}
          />
        </div>
        <div className="search-price">
          <p>{t("buy:price-range")}</p>
          <div className="price-box">
            <input
              type="number"
              value={min_price}
              onChange={(e) => setMin_price(e.target.value)}
              placeholder={t("buy:min-price")}
            ></input>
            <input
              type="number"
              value={max_price}
              onChange={(e) => setMax_price(e.target.value)}
              placeholder={t("buy:max-price")}
            ></input>
          </div>
        </div>
        <Button type="submit" sx={{ py: 5, px: 4, width: "200px" }}>
          {t("buy:search")}
        </Button>
      </form>
      <article className="houses-container">
        <h1>{t(`buy:gdansk-${type}s`)}</h1>
        <EstateSlider city="gdansk" type={type} showAditional />
      </article>
      <article className="houses-container">
        <h1>{t(`buy:gdynia-${type}s`)}</h1>
        <EstateSlider city="gdynia" type={type} showAditional />
      </article>
      <article className="houses-container">
        <h1>{t(`buy:sopot-${type}s`)}</h1>
        <EstateSlider city="sopot" type={type} showAditional />
      </article>
    </>
  );
};

export default HouseNearby;
