import {
  headingsPlugin,
  listsPlugin,
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  ListsToggle,
  toolbarPlugin,
  maxLengthPlugin,
  MDXEditor,
  type MDXEditorMethods,
} from "@mdxeditor/editor";
import "@mdxeditor/editor/style.css";
import { Box } from "@mui/material";
import { useRef } from "react";
import markdownStyles from "./styles";

interface MarkdownEditorProps {
  value?: string;
  onChange?: (value: string) => void;
  maxLength?: number;
}

const MarkdownEditor = ({
  value = "",
  onChange,
  maxLength = 0,
}: MarkdownEditorProps) => {
  const editorRef = useRef<MDXEditorMethods>(null);
  return (
    <Box sx={markdownStyles}>
      <MDXEditor
        plugins={[
          headingsPlugin(),
          listsPlugin(),
          toolbarPlugin({
            toolbarContents: () => (
              <>
                <BoldItalicUnderlineToggles />
                <BlockTypeSelect />
                <ListsToggle />
              </>
            ),
          }),
          ...(maxLength > 0 ? [maxLengthPlugin(maxLength)] : []),
        ]}
        markdown={value}
        onChange={onChange}
        ref={editorRef}
        className="markdown-editor"
        contentEditableClassName="markdown-editor-content"
      />
    </Box>
  );
};

export default MarkdownEditor;
