import "./footer.scss";
import { Link } from "react-router-dom";
import useGitCommits from "hooks/useGitCommits";
import variables from "../../../variables.js";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const commits = useGitCommits();

  return (
    <footer>
      <h1 className="footer-title1">{t("main:join-us")}</h1>
      <div className="footer-text1">
        <Link to="/contact">
          <span className="text-bold">{t("main:contact-us")}</span>
        </Link>
        <Link to="/search">
          <span className="text-bold">{t("main:see-all-offers")}</span>
        </Link>
      </div>
      <h1 className="footer-title2">{t("main:about-us")}</h1>
      <div className="footer-text2">
        {/* <Link to="/">{t("main:our-mission")}</Link> */}
        <Link to="/about">{t("main:about-company")}</Link>
        <Link to="/possibilities">{t("main:development-opportunities")}</Link>
        {/* <Link to="/">{t("main:advertisement")}</Link> */}
        <Link to="/statue">{t("main:state")}</Link>
        <Link to="/privacy-policy">{t("main:privacy-policy")}</Link>
      </div>
      <h1 className="footer-title3">{t("main:find-us")}</h1>
      <div className="footer-text3">
        <Link to="/contact">{t("main:contact")}</Link>
        {/* <Link to="/">{t("main:help")}</Link> */}
        <a href={variables.realnFacebook}>{t("main:facebook")}</a>
        {/* <Link to="/">{t("main:twitter")}</Link> */}
        <a href={variables.realnInstagram}>{t("main:instagram")}</a>
      </div>
      <div className="footer-text4"></div>
      <div className="footer-version-info">
        {Object.entries(commits).map(([key, value]) => (
          <p key={key}>
            <span>
              <b>{key}:</b>
            </span>
            <span>{value}</span>
          </p>
        ))}
      </div>
    </footer>
  );
};

export default Footer;
