import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../store/configureStore";
import { setPage } from "../../store/utilitySlice";
import variables from "variables";
import { useTranslation } from "react-i18next";
import { ClickAwayListener } from "@mui/base";

interface Props {
  isBuyActive: boolean;
  isSellActive: boolean;
  isRentActive: boolean;
  handleBuy: (arg: boolean) => void;
  handleSell: (arg: boolean) => void;
  handleRent: (arg: boolean) => void;
  menuControl: () => void;
}

const Navlinks = ({
  isBuyActive,
  isRentActive,
  isSellActive,
  menuControl,
  handleBuy,
  handleRent,
  handleSell,
}: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <>
      <li
        onMouseEnter={(e) => {
          handleBuy(true);
          handleRent(false);
          handleSell(false);
        }}
      >
        {t("nav:buy")}
      </li>
      {isBuyActive && (
        <ClickAwayListener onClickAway={() => handleBuy(false)}>
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            className="buy-drop"
            onMouseLeave={(e) => handleBuy(false)}
          >
            <h3 className="header1">{t("nav:popular")}</h3>

            {variables.featuredOffers.map((type, i) => (
              <Link
                key={i}
                to={`${type}-nearby`}
                className={`buy-drop${i}`}
                onClick={() => menuControl()}
              >
                {t(`nav:search-for-a-${type}`)}
              </Link>
            ))}
            <h3 className="header2">{t("nav:purchase-options")}</h3>
            <Link
              to="/why-buy"
              className="buy-drop4"
              onClick={() => menuControl()}
            >
              {t("nav:buy-with-realn")}
            </Link>
            <h3 className="header3">{t("nav:funds-for-purchases")}</h3>
            {/* <Link
              to="/can-i-afford"
              className="buy-drop5"
              onClick={() => menuControl()}
            >
              {t("nav:check-budget")}
            </Link> */}
            <Link
              to="/guides"
              className="buy-drop5"
              onClick={() => {
                menuControl();
                dispatch(setPage("buy"));
              }}
            >
              {t("nav:home-buying-guide")}
            </Link>
            {/* <Link to="/find-services" className="buy-drop7"onClick={() => menuControl()}>
                Szukaj firmy i usługi
              </Link> */}
            {/* <Link
              to="/find-events"
              className="buy-drop6"
              onClick={() => menuControl()}
            >
              {t("nav:search-for-worshop-events")}
            </Link> */}
            <a href={variables.realEstateOverviewURL} className="buy-drop6">
              {t("nav:overview")}
            </a>
          </motion.div>
        </ClickAwayListener>
      )}
      <li
        onMouseEnter={(e) => {
          handleSell(true);
          handleBuy(false);
          handleRent(false);
        }}
      >
        {t("nav:sale")}
      </li>
      {isSellActive && (
        <ClickAwayListener onClickAway={() => handleSell(false)}>
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            className="sell-drop"
            onMouseLeave={(e) => handleSell(false)}
          >
            <h3 className="sell-drop1">{t("nav:my-house")}</h3>
            <Link
              to="/sell/how-much-is-it-worth"
              className="sell-drop2"
              onClick={() => menuControl()}
            >
              {t("nav:house-worth")}
            </Link>
            {/* <Link to="/sell/dashboard" className="sell-drop3"onClick={() => menuControl()}>
                Przegląd mojej nieruchomości
              </Link> */}
            <h3 className="sell-drop4">{t("nav:sales-options")}</h3>
            <Link
              to="/sell"
              className="sell-drop5"
              onClick={() => menuControl()}
            >
              {t("nav:sell-with-realn")}
            </Link>
            <Link
              to="/home-nearby"
              className="sell-drop6"
              onClick={() => menuControl()}
            >
              {t("nav:compare-offers")}
            </Link>

            <h3 className="sell-drop8">{t("nav:knowledge-base")}</h3>
            {/* <Link
              to="/guides/will-selling-pay-off"
              className="sell-drop9"
              onClick={() => menuControl()}
            >
              {t("nav:sale-profitable")}
            </Link> */}

            <Link
              to="/guides"
              className="buy-drop6"
              onClick={() => {
                menuControl();
                dispatch(setPage("sell"));
              }}
            >
              {t("nav:selling-guide")}
            </Link>
            <Link
              to="/sell/how-much-is-it-worth/ten-advices"
              className="sell-drop9"
              onClick={() => menuControl()}
            >
              {t("nav:how-increase-property-value")}
            </Link>
          </motion.div>
        </ClickAwayListener>
      )}
      <li
        onMouseEnter={(e) => {
          handleRent(true);
          handleSell(false);
          handleBuy(false);
        }}
      >
        {t("nav:rent")}
      </li>
      {isRentActive && (
        <ClickAwayListener onClickAway={() => handleRent(false)}>
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            className="rent-drop"
            onMouseLeave={(e) => handleRent(false)}
          >
            <h3 className="rent-drop1">{t("nav:search")}</h3>
            <Link
              to="/home-nearby"
              className="rent-drop2"
              onClick={() => menuControl()}
            >
              {t("nav:apartments-for-rent")}
            </Link>
            <Link
              to="/house-nearby"
              className="rent-drop3"
              onClick={() => menuControl()}
            >
              {t("nav:properties-for-rent")}
            </Link>

            <h3 className="rent-drop4">{t("nav:knowledge-base")}</h3>
            <Link
              to="/guides/how-to-rent-a-house"
              className="rent-drop5"
              onClick={() => menuControl()}
            >
              {t("nav:rent-my-property")}
            </Link>
            <Link
              to="/guides/buy-or-rent"
              className="rent-drop6"
              onClick={() => menuControl()}
            >
              {t("nav:buying-or-renting")}
            </Link>
            {/* <Link
              to="/guides/rent-trends"
              className="rent-drop7"
              onClick={() => menuControl()}
            >
              {t("nav:market-trends")}
            </Link> */}
            <Link
              to="/guides/tips-for-renters"
              className="rent-drop7"
              onClick={() => menuControl()}
            >
              {t("nav:tips-for-tenants")}
            </Link>
          </motion.div>
        </ClickAwayListener>
      )}
    </>
  );
};

export default Navlinks;
