import "./loginregister.scss";
import { Controller, useForm } from "react-hook-form";
import { Link, Params, useNavigate, useParams } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { resetPassword } from "./accountSlice";
import { useFormDispatch } from "components/store/configureStore";
import { ResetPasswordFormValues } from "components/models/user";
import Loader from "../utils/Loader";
import { EMAIL_PATTERN, PASSWORD_PATTERN } from "utils/constants";
import { useTranslation } from "react-i18next";
import { FormControl, TextField } from "@mui/material";
import InputPassword from "components/reusable/InputPassword";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";

const NewPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { token }: Readonly<Params<string>> = useParams();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const form = useForm<ResetPasswordFormValues>({
    defaultValues: {
      email: "",
      password: "",
      password_confirmation: "",
      token: "",
    },
  });

  const {
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors, isValid, isSubmitted, isSubmitting },
  } = form;

  const dispatchForm = useFormDispatch(form);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const submit = async (data: ResetPasswordFormValues) => {
    await dispatchForm(resetPassword(data)).then(() => navigate("/login"));
  };
  const checkPasswordEquality = (value: string) => {
    if (value !== watch("password")) return t("validation:field-invalid");
    return true;
  };

  useEffect(() => {
    token && setValue("token", token);
  }, [token]);

  return (
    <div className="reset-password-container">
      <Link to="/" className="reset-back-but">
        <AiOutlineArrowLeft />
        <p>{t("login:back")}</p>
      </Link>
      <div className="reset-password-box">
        <FormControl component={"form"} onSubmit={handleSubmit(submit)}>
          <h1>{t("login:pass-reset")}</h1>
          <Controller
            name={"email"}
            control={control}
            rules={{
              required: t("validation:field-required"),
              pattern: {
                value: EMAIL_PATTERN,
                message: t("validation:field-invalid"),
              },
            }}
            render={({ field }) => (
              <TextField
                label={t("login:email")}
                variant="outlined"
                autoComplete="off"
                size="small"
                type="email"
                error={Boolean(errors[field.name])}
                helperText={errors[field.name]?.message}
                fullWidth
                sx={{ mb: 3 }}
                {...field}
              />
            )}
          />
          <Controller
            name={"password"}
            control={control}
            rules={{
              required: t("validation:field-required"),
              pattern: {
                value: PASSWORD_PATTERN,
                message: t("login:password-needs"),
              },
            }}
            render={({ field }) => (
              <InputPassword
                label={t("login:give-password")}
                variant="outlined"
                isVisible={showPassword}
                onVisibleChange={handleClickShowPassword}
                size="small"
                autoComplete="off"
                error={Boolean(errors[field.name])}
                helperText={errors[field.name]?.message}
                sx={{ mb: 3 }}
                fullWidth
                {...field}
              />
            )}
          />
          <Controller
            name={"password_confirmation"}
            control={control}
            rules={{
              required: t("validation:field-required"),
              validate: checkPasswordEquality,
            }}
            render={({ field }) => (
              <InputPassword
                label={t("login:repeat-password")}
                variant="outlined"
                size="small"
                isVisible={showPassword}
                autoComplete="off"
                error={Boolean(errors[field.name])}
                helperText={errors[field.name]?.message}
                fullWidth
                iconHidden
                {...field}
              />
            )}
          />
          <LoadingButton
            type="submit"
            size="large"
            disabled={!isValid && isSubmitted}
            loading={isSubmitting}
            sx={{ mt: 6 }}
          >
            {!isSubmitting && t("login:send")}
          </LoadingButton>
        </FormControl>
      </div>
    </div>
  );
};

export default NewPassword;
