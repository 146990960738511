import { MenuItem, FormControl, InputLabel } from "@mui/material";
import { TableElement } from "components/models/table";
import { forwardRef } from "react";
import Select from "components/reusable/LazySelect";

interface Props {
  value: string;
  name?: string;
  label?: string;
  values: TableElement[];
  fullWidth?: boolean;
  required?: boolean;
  onChange: (value: string) => void;
  loading?: boolean;
}

const SelectGroup = forwardRef(
  (
    {
      value,
      label,
      name,
      onChange,
      values,
      fullWidth,
      required,
      loading,
    }: Props,
    ref
  ) => {
    return (
      <FormControl fullWidth={fullWidth} required={required}>
        <InputLabel id={name}>{label}</InputLabel>
        <Select
          labelId={name}
          id={name}
          value={value ?? ""}
          label={label}
          inputRef={ref}
          name={name}
          fullWidth={fullWidth}
          required={required}
          onChange={(e) => onChange(e.target.value)}
          loading={loading}
        >
          {values.map((value: TableElement) => (
            <MenuItem key={value.id} value={value.id}>
              {value.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
);

export default SelectGroup;
