const variables = {
  fallbackLng: process.env.REACT_APP_FALLBACK_LANGUAGE ?? "pl",
  getBackend: process.env.REACT_APP_BACKEND_HOST,
  xsrfToken: {
    "X-XSRF-TOKEN": decodeURIComponent(document.cookie),
  },
  featuredOffers: process.env.REACT_APP_FEATURED_OFFERS
    ? process.env.REACT_APP_FEATURED_OFFERS.split(",")
    : ["apartment"],
  notificationAutoHideDuration:
    process.env.REACT_APP_NOTIFICATION_AUTOHIDE_DURATION ?? 6000,
  realnPhone: process.env.REACT_APP_REALN_PHONE ?? "668-001-756",
  realnEmail: process.env.REACT_APP_REALN_MAIL ?? "info@realn.pl",
  realnFacebook:
    process.env.REACT_APP_REALN_FACEBOOK ??
    "https://www.facebook.com/RealN.Polska/",
  realnInstagram:
    process.env.REACT_APP_REALN_INSTAGRAM ??
    "https://www.instagram.com/realn_pl/",
  realnLinkedin:
    process.env.REACT_APP_REALN_LINKEDIN ??
    "https://www.linkedin.com/company/realn/",
  realEstateOverviewURL:
    process.env.REACT_APP_REAL_ESTATE_OVERVIEW_URL ??
    "https://nbp.pl/publikacje/cykliczne-materialy-analityczne-nbp/rynek-nieruchomosci/informacja-kwartalna/",
};

export default variables;
