interface ParsedParams {
  [key: string]: string | string[];
}

export default function parseUrlParameters(url?: string): ParsedParams {
  const params = new URLSearchParams(url);
  const result: ParsedParams = {};

  params.forEach((value, key) => {
    const arrayParam = key.match(/\b(\w+)\[(\d+)\]$/);

    if (arrayParam !== null) {
      const paramName = arrayParam[1];
      const paramIndex = +arrayParam[2];

      if (paramName in result) {
        (result[paramName] as string[])[paramIndex] = value;
      } else {
        result[paramName] = [value];
      }
      result[paramName] = (result[paramName] as string[]).filter(Boolean);
    } else {
      result[key] = value;
    }
  });

  return result;
}
