import {
  useAppDispatch,
  useAppSelector,
} from "components/store/configureStore";
import ProfileNav from "./ProfileNav";
import { useEffect, useState } from "react";
import { getUserOffers } from "../LoginRegister/accountSlice";
import { ResponseOffer } from "components/models/offer";
import { useTranslation } from "react-i18next";

import { Button, Dialog, Skeleton } from "@mui/material";
import { Stack } from "@mui/system";
import OfferTable from "./OfferTable";
import { IoMdClose } from "react-icons/io";
import { deleteOfferAsync, duplicateOfferAsync } from "../offers/catalogSlice";

const Offers = () => {
  const dispatch = useAppDispatch();
  const { userOffers } = useAppSelector((state) => state.account);
  const { t } = useTranslation();
  const isDeleting = useAppSelector(
    (state) => state.catalog.status === "pendingDeleteOffer"
  );
  const isDuplicating = useAppSelector(
    (state) => state.catalog.status === "pendingDuplicateOffer"
  );
  const isLoading = useAppSelector(
    (state) => state.account.status === "pendingFetchUserOffers"
  );
  const [offerForDeletion, setOfferForDeletion] = useState<
    ResponseOffer["id"] | null
  >(null);
  const [offerForDuplication, setOfferForDuplication] = useState<
    ResponseOffer["id"] | null
  >(null);

  useEffect(() => {
    dispatch(getUserOffers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = async () => {
    if (!offerForDeletion || isDeleting) return;

    const res = await dispatch(deleteOfferAsync(offerForDeletion));
    setOfferForDeletion(null);
    if (res.type === "catalog/deleteOfferAsync/fulfilled")
      dispatch(getUserOffers());
  };

  const handleDuplicate = async () => {
    if (!offerForDuplication || isDuplicating) return;

    const res = await dispatch(duplicateOfferAsync(offerForDuplication));
    setOfferForDuplication(null);
    if (res.type === "catalog/duplicateOfferAsync/fulfilled")
      dispatch(getUserOffers());
  };

  const handleClickDelete = (
    e: React.MouseEvent<HTMLButtonElement>,
    id: ResponseOffer["id"]
  ) => {
    e.stopPropagation();
    setOfferForDeletion(id);
  };

  const handleClickDuplicate = (
    e: React.MouseEvent<HTMLButtonElement>,
    id: ResponseOffer["id"]
  ) => {
    e.stopPropagation();
    setOfferForDuplication(id);
  };

  return (
    <div className="profile-container">
      <ProfileNav />
      <div className="profile-page">
        <h3>{t("profile:your-offers")}</h3>
        {isLoading ? (
          <Stack spacing={2}>
            {Array(5)
              .fill(0)
              .map((_, i) => (
                <Skeleton key={i} variant="rounded" width="100%" height={56} />
              ))}
          </Stack>
        ) : userOffers && userOffers.length > 0 ? (
          <OfferTable
            userOffers={userOffers}
            onDelete={handleClickDelete}
            onDuplicate={handleClickDuplicate}
          />
        ) : (
          <h1>{t("profile:no-offers")}</h1>
        )}
      </div>

      <Dialog
        open={!!offerForDuplication}
        onClose={() => setOfferForDuplication(null)}
        className="user-offer-duplicate-dialog"
        slotProps={{
          backdrop: {
            style: { backgroundColor: "transparent" },
          },
        }}
      >
        <div className="dialog-wrapper delete-dialog">
          <IoMdClose
            className="close-icon"
            size={20}
            onClick={() => setOfferForDuplication(null)}
          />
          <h4 className="delete-question">
            {t("profile:sure-to-duplicate-offer")}
          </h4>
          <div className="button-section">
            <Button
              onClick={() => setOfferForDuplication(null)}
              color="selected"
            >
              {t("profile:cancel")}
            </Button>
            <Button
              onClick={handleDuplicate}
              size="medium"
              disabled={isDuplicating}
            >
              {t("profile:duplicate")}
            </Button>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={!!offerForDeletion}
        onClose={() => setOfferForDeletion(null)}
        className="user-offer-delete-dialog"
        slotProps={{
          backdrop: {
            style: { backgroundColor: "transparent" },
          },
        }}
      >
        <div className="dialog-wrapper delete-dialog">
          <IoMdClose
            className="close-icon"
            size={20}
            onClick={() => setOfferForDeletion(null)}
          />
          <h4 className="delete-question">
            {t("profile:sure-to-delete-offer")}
          </h4>
          <div className="button-section">
            <Button onClick={() => setOfferForDeletion(null)} color="secondary">
              {t("profile:cancel")}
            </Button>
            <Button onClick={handleDelete} size="medium" disabled={isDeleting}>
              {t("profile:delete")}
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Offers;
