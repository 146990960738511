import { useEffect } from "react";
import { getBackendCommitHash } from "components/store/utilitySlice";
import {
  useAppDispatch,
  useAppSelector,
} from "components/store/configureStore";

const COMMIT_LENGTH = 7;

const useGitCommits = () => {
  const dispatch = useAppDispatch();
  const backendVersion = useAppSelector(
    (state) => state.utility.backendCommitHash ?? "-"
  );
  const frontendVersion = process.env.REACT_APP_GIT_HEAD_COMMIT_HASH ?? "-";

  useEffect(() => {
    dispatch(getBackendCommitHash());
  }, [dispatch]);

  return {
    frontend: frontendVersion.substring(0, COMMIT_LENGTH),
    backend: backendVersion.substring(0, COMMIT_LENGTH),
  };
};

export default useGitCommits;
