import "./sellwith.scss";
import pic5 from "../../../../images/pic5.webp";
import pic4 from "../../../../images/pic4.webp";
import mock from "../../../../images/mock.webp";
import EstateSlider from "../../utils/EstateSlider";
import SmallContact from "../../utils/SmallContact";
import { useTranslation } from "react-i18next";
import { FaMedal, FaPeopleArrows } from "react-icons/fa";
import { GiClassicalKnowledge } from "react-icons/gi";
import SearchWithState from "components/views/utils/Searcher/SearchWithState";
import { useAppDispatch } from "components/store/configureStore";
import { useEffect } from "react";
import { searchOffersAsync } from "components/views/offers/catalogSlice";
import variables from "variables";

const SellWith = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(searchOffersAsync());
  }, []);

  return (
    <>
      <div className="sellwith__container">
        <div className="sellwith-aside__text">
          <h1>
            {t("sell:sell-for-more")} <br />
            {t("sell:and-save")}
          </h1>
          <div className="sellwith-aside__head">
            <p>{t("sell:realn-agents-has-experience")}</p>
            <p>{t("sell:to-start")}</p>
            <a href={`tel: ${variables.realnPhone}`}>{variables.realnPhone},</a>
            <p>{t("sell:or")}</p>
            <a href={`mailto: ${variables.realnEmail}`}>{t("sell:text-us")}</a>
            <p>{t("sell:we-contact")}</p>
          </div>
        </div>
        <img src={pic5} alt="agent bg" />
      </div>
      <div className="sellwith-quest__container">
        <h1>{t("sell:why-is-it-worth")} </h1>
        <div className="sellwith-quest__gridbox">
          <div>
            <FaMedal />
            <h3>{t("sell:best-agents")}</h3>
            <p>{t("sell:top-one")}</p>
          </div>
          <div>
            <GiClassicalKnowledge />

            <h3>{t("sell:experts")}</h3>
            <p>{t("sell:experience")}</p>
          </div>
          <div>
            <FaPeopleArrows />

            <h3>{t("sell:personal")}</h3>
            <p>{t("sell:agency-personal")}</p>
          </div>
        </div>
      </div>
      <div className="sellwith-ipad__container">
        <h1>{t("sell:get-a-marketing")}</h1>
        <div className="sellwith-ipad__gridbox">
          <img src={mock} alt="ipad"></img>
          <div>
            <h3>{t("sell:more-eyes-on-house")} </h3>
            <p>{t("sell:when-you-place-offer")}</p>
            <h3>{t("sell:preferred-position")}</h3>
            <p>{t("sell:first-week")}</p>
            <h3>{t("sell:digital-campaigns")}</h3>
            <p>{t("sell:each-offer")}</p>
            <h3>{t("sell:interactive-tours")}</h3>
            <p>{t("sell:each-house")}</p>
          </div>
        </div>
      </div>
      <div className="sellwith-more__gridbox">
        <div>
          <h3>{t("sell:high-quality-sale")}</h3>
          <p>{t("sell:premium")}</p>
          {/* <button className="text-bold">{t("sell:know-more")}</button> */}
        </div>
        <img src={pic5} alt="luxury apartment" />
      </div>
      <div className="sellwith-ready__gridbox">
        <img src={pic4} alt="luxury apartment" />
        <div className="sellwith-ready__gridbox__content">
          <h3>{t("sell:ready-to-go")}</h3>
          <p>{t("sell:today-connect-with-agent")}</p>
          <div className="desktop">
            <SearchWithState />
          </div>
        </div>
      </div>
      {/* 
      <div className="sellwith-opinions">
        <ImQuotesLeft />
        <h3>{t("sell:opinions")}</h3>
        <p>
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates
          alias voluptatem accusamus temporibus voluptatum rerum labore
          cupiditate sapiente incidunt facilis!"
        </p>
        <p>Sebastian Zimnol</p>
        <ImQuotesRight />
      </div>
    */}
      <EstateSlider />
      <SmallContact />
    </>
  );
};

export default SellWith;
