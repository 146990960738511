import { Button, Chip, CircularProgress, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import parseUrlParameters from "../utils/FormatUrl";
import {
  MdAttachMoney,
  MdFavorite,
  MdFavoriteBorder,
  MdHotel,
  MdHouse,
  MdLocalOffer,
  MdLocationCity,
  MdSearch,
  MdSquareFoot,
} from "react-icons/md";
import { IconType } from "react-icons";
import {
  useAppDispatch,
  useAppSelector,
} from "components/store/configureStore";
import {
  fetchSearchCriteriasAsync,
  postSaveSearchCriteria,
} from "../LoginRegister/accountSlice";
import useLookupTable, { LookupTableSearchParam } from "hooks/useLookupTable";
import { useEffect } from "react";
import { SearchCriteria } from "components/models/offer";
import TooltipComponent from "components/reusable/Tooltip/Tooltip";
import { VALUE_SUFFIX } from "utils/constants";
import { useTranslation } from "react-i18next";

const CHIP_LIMIT = 3;

const EXCLUDED_PARAMS = ["page", "page_size", "order", "order_by"];

const icon: Record<string, IconType> = {
  title: MdSearch,
  buildType: MdHouse,
  type: MdLocalOffer,
  location: MdLocationCity,
  price: MdAttachMoney,
  size: MdSquareFoot,
  rooms: MdHotel,
};

const getTranslationKey = (key: string) =>
  key
    .replaceAll(/([a-z])([A-Z])/g, "$1-$2")
    .replaceAll("_", "-")
    .toLowerCase();

const SearchResultSummary = () => {
  const { params } = useParams();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.account);
  const { t } = useTranslation();
  const favoriteSearchCriterias = useAppSelector(
    (state) => state.account.favoriteSearchCriterias
  );
  const accountStatus = useAppSelector((state) => state.account.status);
  const { getLookupNameFromSearchParam } = useLookupTable();

  const criteria = Object.entries(parseUrlParameters(params))
    .filter(([key]) => !EXCLUDED_PARAMS.includes(key))
    .reduce<[string, string][]>((acc, curr) => {
      if (Array.isArray(curr[1])) {
        return acc.concat(curr[1].map((c, i) => [curr[0].split("[")[0], c]));
      } else {
        if (curr[0].startsWith("min_") || curr[0].startsWith("max_")) {
          const [rangeValue, key] = curr[0].split("_");
          const criteria = acc.find(([k]) => k === key);
          if (criteria) {
            criteria[1] = criteria[1].replace("...", curr[1]);
          } else {
            acc.push([
              key,
              (rangeValue === "min"
                ? `${curr[1]} - ... ${VALUE_SUFFIX[key]}`
                : `... - ${curr[1]} ${VALUE_SUFFIX[key]}`
              ).trim(),
            ]);
          }
          return acc;
        }

        if (curr[0] === "title") {
          curr[1] = `"${curr[1]}""`;
        }

        return [...acc, curr as [string, string]];
      }
    }, []);

  const overLimitCount = criteria.length - CHIP_LIMIT;
  const isLoadingCriteria = accountStatus.includes(
    "pendingFetchSearchCriterias"
  );
  const isSavingCriteria = accountStatus.includes(
    "pendingPostSaveSearchCriteria"
  );
  const isCriteriaSaved = favoriteSearchCriterias.some((c) =>
    Object.entries(c)
      .filter((c) => c[0] !== "id")
      .every(([key, value]) => {
        const parsedParams = parseUrlParameters(params);
        if (Array.isArray(value)) {
          return (
            value &&
            value.length ===
              parsedParams[key as keyof SearchCriteria]?.length &&
            value.every((v) =>
              (parsedParams[key as keyof SearchCriteria] as string[])?.includes(
                v
              )
            )
          );
        }
        return (
          parsedParams[key as keyof SearchCriteria] === value ||
          (!parsedParams[key as keyof SearchCriteria] && !value)
        );
      })
  );

  const SaveButtonIcon = isCriteriaSaved ? MdFavorite : MdFavoriteBorder;

  const handleSaveSearch = () => {
    dispatch(postSaveSearchCriteria(parseUrlParameters(params)));
  };

  useEffect(() => {
    if (!favoriteSearchCriterias.length && !isLoadingCriteria && user) {
      dispatch(fetchSearchCriteriasAsync());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container pt={2} pb={4}>
      <Grid
        item
        xs
        container
        sx={{ alignItems: "center", columnGap: 1, rowGap: 1 }}
      >
        <Grid item xs="auto">
          {t("search:summary-label")}
        </Grid>
        <Grid
          item
          xs
          container
          sx={{ columnGap: 1, rowGap: 1, alignItems: "center" }}
        >
          {!criteria.length && <Grid item>-</Grid>}
          {criteria.slice(0, CHIP_LIMIT).map(([key, value]) => {
            const Icon = icon[key];
            return (
              <Grid item key={value}>
                <TooltipComponent
                  WrappedComponent={
                    <Chip
                      label={getLookupNameFromSearchParam(
                        key as LookupTableSearchParam,
                        value
                      )}
                      {...(Icon && { icon: <Icon size={20} /> })}
                    />
                  }
                  message={t(`search:${getTranslationKey(key)}`)}
                />
              </Grid>
            );
          })}
          {overLimitCount > 0 && (
            <Grid item>{` + ${overLimitCount} more`}</Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs="auto" sx={{ justifyContent: "flex-end" }}>
        {user && (
          <Button
            size="large"
            startIcon={
              isSavingCriteria ? (
                <CircularProgress size={20} />
              ) : (
                <SaveButtonIcon />
              )
            }
            onClick={handleSaveSearch}
            disabled={isLoadingCriteria || isSavingCriteria || isCriteriaSaved}
          >
            {t(
              `search:criteria-save-button-${
                !isCriteriaSaved ? "not-" : ""
              }saved`
            )}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default SearchResultSummary;
