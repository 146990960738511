import { Box, CircularProgress } from "@mui/material";

const OfferImagesItemLoader = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        aspectRatio: "4/3",
        border: "1px solid",
        borderColor: "grey.100",
        borderRadius: "4px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress size={40} />
    </Box>
  );
};

export default OfferImagesItemLoader;
