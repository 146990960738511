import Slider from "react-slick";
import OfferCard from "./OfferCard";
import "./slider.scss";
import { useAppSelector } from "../../store/configureStore";
import { offersSelectors } from "components/store/selectors";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { Link, Grid, Skeleton } from "@mui/material";
import notFoundImage from "images/icons/buyhouse.png";
import { useMemo } from "react";

interface EstateSliderProps {
  city?: string;
  type?: string;
  showAditional?: boolean;
}

const EstateSlider = ({
  city,
  type,
  showAditional,
}: Readonly<EstateSliderProps>) => {
  const { t } = useTranslation();
  const catalog = useAppSelector(offersSelectors.selectAll);
  const offersLoaded = useAppSelector((state) => state.catalog.offersLoaded);

  const offers = useMemo(() => {
    let results = catalog;

    if (type !== undefined) {
      results = results.filter((offer) => offer.buildType === type);
    }

    if (city !== undefined) {
      const filteredByCity = results.filter((offer) => offer.city.id === city);
      const aditional = showAditional
        ? results
            .filter(
              (offer) => !filteredByCity.find((obtac) => obtac.id === offer.id)
            )
            .slice(0, 3 - filteredByCity.length)
        : [];

      results = [...filteredByCity, ...aditional];
    }

    return results;
  }, [catalog, city, type, showAditional]);

  const settings = {
    slidesToShow: Math.min(offers.length, 3),
    slidesToScroll: 1,
    autoplay: true,
    dots: true,
    arrows: false,
    autoplayspeed: 500,
    speed: 1000,
    infinite: true,
    responsive: [
      {
        breakpoint: 1224,
        settings: {
          slidesToShow: Math.min(offers.length, 2),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: Math.min(offers.length, 1),
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <div className="main-slider-container">
      <div className="slider-box">
        {!offersLoaded && (
          <Grid container spacing={5}>
            {Array(3)
              .fill(1)
              .map((start, index) => (
                <Grid item xs={12} sm={6} md={4} key={start + index}>
                  <Skeleton
                    variant="rounded"
                    sx={{
                      minHeight: 420,
                    }}
                  />
                </Grid>
              ))}
          </Grid>
        )}
        {offersLoaded && !offers.length && (
          <div className="slider-placeholder">
            <div className="slider-placeholder-image">
              <img src={notFoundImage} alt="not found" />
            </div>
            <h3>{t("utils:slider-placeholder-text")}</h3>
          </div>
        )}
        {offersLoaded && Boolean(offers.length) && (
          <Slider {...settings}>
            {offers.map((offer) => (
              <OfferCard
                key={offer.id}
                offer={offer}
                {...(city !== undefined &&
                  offer.city.id !== city && {
                    ribbon: t("utils:slider-aditional-offer-ribbon"),
                  })}
              />
            ))}
          </Slider>
        )}
      </div>
      <Grid container sx={{ py: 10, px: 4 }}>
        <Grid item xs="auto">
          <Link
            variant="button"
            component={RouterLink}
            to={`/search${city ? "/location%5B1%5D=" + city : ""}`}
          >
            {t("utils:check-all-offers")}
          </Link>
        </Grid>
      </Grid>
    </div>
  );
};

export default EstateSlider;
