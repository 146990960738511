import {
  PostOfferParams,
  RequestOffer,
  ResponseOffer,
} from "components/models/offer";
import { parseTwoDecimals } from "./helpers";
import { calcPriceM } from "components/views/Offer/components/FormOffer";

export const createOfferRequestData = (params: PostOfferParams) =>
  ({
    id: params.id || null,
    title: params.title || null,
    type: params.type || null,
    size: parseTwoDecimals(params.size),
    city: params.city || null,
    district: params.district || null,
    street: params.street || null,
    stnum: params.stnum || null,
    floor: params.floor || null,
    rooms: params.rooms || null,
    condition: params.condition || null,
    market: params.market || null,
    parking: params.parking || null,
    buildType: params.buildType || null,
    costs: parseTwoDecimals(params.costs),
    year: params.year || null,
    transport: JSON.stringify(params.transport),
    education: JSON.stringify(params.education),
    health: JSON.stringify(params.health),
    recreation: JSON.stringify(params.recreation),
    others: JSON.stringify(params.others),
    amenities: JSON.stringify(params.amenities),
    kitchen: params.kitchen || null,
    kitchenAm: JSON.stringify(params.kitchenAm),
    bathroom: params.bathroom || null,
    bathAm: JSON.stringify(params.bathAm),
    installation: params.installation || null,
    loudness: params.loudness || null,
    windows: params.windows || null,
    furnitured: JSON.stringify(params.furnitured),
    energy: JSON.stringify(params.energy),
    media: JSON.stringify(params.media),
    direction: JSON.stringify(params.direction),
    description: params.description || null,
    price: parseTwoDecimals(params.price),
    priceM: calcPriceM(params.price, params.size).toString(),
    material: params.material || null,
  } as RequestOffer);

export const createOfferData = (offer: any) =>
  ({
    id: offer.id ?? "",
    title: offer.title ?? "",
    type: offer.type?.id ?? "",
    size: offer.size ?? "",
    city: offer.city?.id ?? "",
    district: offer.district?.id ?? "",
    street: offer.street ?? "",
    stnum: offer.stnum ?? "",
    floor: offer.floor ?? "",
    rooms: offer.rooms ?? "",
    market: offer.market ?? "",
    condition: offer.condition ?? "",
    parking: offer.parking ?? "",
    buildType: offer.buildType ?? "",
    costs: offer.costs ?? "",
    year: offer.year ?? "",
    transport: JSON.parse(offer.transport),
    education: JSON.parse(offer.education),
    health: JSON.parse(offer.health),
    recreation: JSON.parse(offer.recreation),
    others: JSON.parse(offer.others),
    amenities: JSON.parse(offer.amenities),
    kitchen: offer.kitchen ?? "",
    kitchenAm: JSON.parse(offer.kitchenAm),
    bathroom: offer.bathroom ?? "",
    bathAm: JSON.parse(offer.bathAm),
    installation: offer.installation ?? "",
    loudness: offer.loudness ?? "",
    windows: offer.windows ?? "",
    furnitured: JSON.parse(offer.furnitured),
    energy: JSON.parse(offer.energy),
    media: JSON.parse(offer.media),
    direction: JSON.parse(offer.direction),
    description: offer.description ?? "",
    price: offer.price ?? "",
    priceM: offer.priceM ?? "",
    material: offer.material ?? "",
  } as ResponseOffer);
