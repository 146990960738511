import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import plAbout from "./locales/pl/about.json";
import plLists from "./locales/pl/lists.json";
import plAddOffer from "./locales/pl/add-offer.json";
import plBuy from "./locales/pl/buy.json";
import plLogin from "./locales/pl/login.json";
import plMain from "./locales/pl/main.json";
import plNav from "./locales/pl/nav.json";
import plOffers from "./locales/pl/offers.json";
import plPremium from "./locales/pl/premium.json";
import plRent from "./locales/pl/rent.json";
import plResources from "./locales/pl/resources.json";
import plSales from "./locales/pl/sell.json";
import plUtils from "./locales/pl/utils.json";
import plApp from "./locales/pl/app.json";
import plProfile from "./locales/pl/profile.json";
import plPrivacy from "./locales/pl/privacy.json";
import plStatue from "./locales/pl/statue.json";
import plSearch from "./locales/pl/search.json";
import plValidation from "./locales/pl/validation.json";

import enAbout from "./locales/en/about.json";
import enLists from "./locales/en/lists.json";
import enAddOffer from "./locales/en/add-offer.json";
import enBuy from "./locales/en/buy.json";
import enLogin from "./locales/en/login.json";
import enMain from "./locales/en/main.json";
import enNav from "./locales/en/nav.json";
import enOffers from "./locales/en/offers.json";
import enPremium from "./locales/en/premium.json";
import enRent from "./locales/en/rent.json";
import enResources from "./locales/en/resources.json";
import enSales from "./locales/en/sell.json";
import enUtils from "./locales/en/utils.json";
import enApp from "./locales/en/app.json";
import enPrivacy from "./locales/en/privacy.json";
import enStatue from "./locales/en/statue.json";
import enProfile from "./locales/en/profile.json";
import enSearch from "./locales/en/search.json";
import enValidation from "./locales/en/validation.json";
import variables from "variables";

export const defaultNS = "ns1";
export const fallbackNS = "fallback";

i18next.use(initReactI18next).init({
  debug: true,
  fallbackLng: variables.fallbackLng,
  defaultNS,
  resources: {
    en: {
      about: enAbout,
      lists: enLists,
      addOffer: enAddOffer,
      buy: enBuy,
      login: enLogin,
      main: enMain,
      nav: enNav,
      offers: enOffers,
      premium: enPremium,
      rent: enRent,
      resources: enResources,
      sell: enSales,
      utils: enUtils,
      app: enApp,
      privacy: enPrivacy,
      statue: enStatue,
      profile: enProfile,
      search: enSearch,
      validation: enValidation,
    },
    pl: {
      about: plAbout,
      lists: plLists,
      addOffer: plAddOffer,
      buy: plBuy,
      login: plLogin,
      main: plMain,
      nav: plNav,
      offers: plOffers,
      premium: plPremium,
      rent: plRent,
      resources: plResources,
      sell: plSales,
      utils: plUtils,
      app: plApp,
      privacy: plPrivacy,
      statue: plStatue,
      profile: plProfile,
      search: plSearch,
      validation: plValidation,
    },
  },
});

export default i18next;
