import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { ResponseOffer } from "components/models/offer";
import { useAppSelector } from "components/store/configureStore";

interface EditOfferButtonProps {
  offer: ResponseOffer;
}

const EditOfferButton = ({ offer }: EditOfferButtonProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userId = useAppSelector((state) => state.account.user?.id);

  const handleEdit = (
    e: React.MouseEvent<HTMLButtonElement>,
    offer: ResponseOffer
  ) => {
    e.stopPropagation();
    navigate(`/catalog/${offer.id}`);
  };

  if (!offer?.user || offer.user.id !== userId) return null;

  return (
    <>
      <Button size="large" fullWidth onClick={(e) => handleEdit(e, offer)}>
        {t("profile:edit-offer")}
      </Button>
    </>
  );
};

export default EditOfferButton;
