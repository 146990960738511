import { IconButton } from "@mui/material";
import TooltipComponent from "components/reusable/Tooltip/Tooltip";
import {
  useAppDispatch,
  useAppSelector,
} from "components/store/configureStore";
import {
  addOfferToFavoritesAsync,
  removeOfferFromFavoritesAsync,
} from "components/views/LoginRegister/accountSlice";
import { useTranslation } from "react-i18next";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { useParams } from "react-router-dom";

interface PropAddToFavoritesProps {}

const PropAddToFavorites = (props: PropAddToFavoritesProps) => {
  const { t } = useTranslation();
  const params = useParams();
  const dispatch = useAppDispatch();
  const isFavorite = useAppSelector((state) =>
    Boolean(state.account.favoriteOffers.find(({ id }) => id === params.id))
  );

  const isPendingAddOfferToFavorites = useAppSelector(
    (state) => state.account.status === "pendingAddOfferToFavorites"
  );
  const isPendingRemoveOfferFromFavorites = useAppSelector(
    (state) => state.account.status === "pendingRemoveOfferFromFavorites"
  );
  const isLoading = useAppSelector(
    (state) => state.account.status === "pendingGetUserFavoriteOffers"
  );

  const isOptimisticallyFavorite =
    isPendingAddOfferToFavorites ||
    (isFavorite && !isPendingRemoveOfferFromFavorites);

  const Icon = isOptimisticallyFavorite ? AiFillHeart : AiOutlineHeart;

  const handleClick = () => {
    if (
      isLoading ||
      isPendingAddOfferToFavorites ||
      isPendingRemoveOfferFromFavorites ||
      !params?.id
    )
      return;
    const action = isFavorite
      ? removeOfferFromFavoritesAsync
      : addOfferToFavoritesAsync;
    dispatch(action(params.id));
  };

  return (
    <IconButton
      sx={{ padding: 0.5, opacity: isLoading ? 0.5 : 1 }}
      onClick={handleClick}
      disabled={isLoading}
    >
      <TooltipComponent
        WrappedComponent={<Icon />}
        message={t(
          `offers:${
            isOptimisticallyFavorite ? "remove-from" : "add-to"
          }-favorites`
        )}
      />
    </IconButton>
  );
};

export default PropAddToFavorites;
