import SmallContact from "../../utils/SmallContact";
import homebg from "../../../../images/icons/homebg.png";
import homebg2 from "../../../../images/icons/landbg.png";
import homebg3 from "../../../../images/icons/steps.png";
import homebg4 from "../../../../images/icons/guide.png";
import "../../nav/nav.scss";
import "./sellworth.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import SearchWithState from "components/views/utils/Searcher/SearchWithState";

const SellWorth = () => {
  const { t } = useTranslation();

  return (
    <>
      <header className="house-search-header">
        <article className="house-search-header-box">
          <h1>{t("sell:how-much-is-my-house")}</h1>
          <p>{t("sell:view-nearby-sales")}</p>

          <aside className="input-holder">
            <SearchWithState />
          </aside>
        </article>
        <img src={homebg} alt="buy home background"></img>
      </header>

      <div className="sellworth__container">
        <article className="sellworth-grid">
          <div className="sellworth-grid__box1">
            <h1>{t("sell:accurate-valuation")}</h1>
            <p>{t("sell:to-count-realn")}</p>
          </div>
          <div className="sellworth-grid__box2">
            <h3>{t("sell:track-home-value")}</h3>
            <p>{t("sell:know-value-instantly")}</p>
          </div>
          <div className="sellworth-grid__box3">
            <h3>{t("sell:see-local-trends")}</h3>
            <p>{t("sell:stay-up-to-date")}</p>
          </div>
          <div className="sellworth-grid__box4">
            <h3>{t("sell:get-a-free-report")}</h3>
            <p>{t("sell:receive-monthly-email-updates")}</p>
          </div>
        </article>
      </div>

      <h1 className="sellworth__subtitle">{t("sell:more-resources")}</h1>
      <div className="sellworth-grid__container">
        <Link to="/sell/how-much-is-it-worth/find-the-value">
          <div className="box1">
            <img src={homebg4} alt="home"></img>
            <h3>{t("sell:how-calculate")}</h3>
            <p>{t("sell:continue")}</p>
          </div>
        </Link>
        <Link to="/sell/how-much-is-it-worth/five-ways">
          <div className="box2">
            <img src={homebg2} alt="home"></img>
            <h3>{t("sell:five-ways")}</h3>
            <p>{t("sell:continue")}</p>
          </div>
        </Link>
        <Link to="/sell/how-much-is-it-worth/ten-advices">
          <div className="box3">
            <img src={homebg3} alt="home"></img>
            <h3>{t("sell:10-amazing-tips")}</h3>
            <p>{t("sell:continue")}</p>
          </div>
        </Link>
      </div>
      <SmallContact />
    </>
  );
};

export default SellWorth;
