import { MouseEventHandler, useState } from "react";
import "./addoffer.scss";
import {
  MdOutlineKeyboardArrowUp,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import { PostOfferParams } from "../../../models/offer";
import ButtonGroup from "./ButtonGroup";
import SelectGroup from "./SelectGroup";
import OfferImages from "components/views/utils/OfferImages";
import LocationSelect from "components/reusable/LocationSelect";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button,
  InputAdornment,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import Form from "./Form";
import Page from "./Page";
import FormFieldset from "./FormFieldset";
import FormSection from "./FormSection";
import {
  useAppSelector,
  useFormDispatch,
} from "components/store/configureStore";
import {
  createOfferAsync,
  updateOfferAsync,
} from "components/views/offers/catalogSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { createOfferRequestData } from "utils/offer";
import { VALUE_SUFFIX } from "utils/constants";
import { LoadingButton } from "@mui/lab";
import MarkdownEditor from "components/views/utils/Markdown/Editor";

interface Props {
  offer?: any;
}
export const calcPriceM = (price: string, size: string) => {
  const priceM = size ? Number(price) / Number(size) : 0;
  const result = Math.round(priceM * 100) / 100;
  return result.toFixed(2);
};

const FormOffer = ({ offer }: Props) => {
  const { t } = useTranslation();
  const { table, tableLoaded } = useAppSelector((state) => state.utility);
  const [showMore, setShowMore] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();

  const form = useForm<PostOfferParams>({
    reValidateMode: "onChange",
    defaultValues: offer || {
      title: "",
      price: "",
      priceM: "",
      type: "",
      size: "",
      city: "",
      district: "",
      street: "",
      stnum: "",
      floor: "",
      rooms: "",
      condition: "",
      bathroom: "",
      installation: "",
      description: "",
      loudness: "",
      kitchen: "",
      windows: "",
      parking: "",
      buildType: "",
      market: "",
      material: "",
      costs: "",
      year: "",
      transport: [],
      education: [],
      bathAm: [],
      health: [],
      kitchenAm: [],
      recreation: [],
      energy: [],
      furnitured: [],
      amenities: [],
      others: [],
    },
  });
  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = form;

  const dispatchForm = useFormDispatch(form);

  const countCharacters = (text: string) => {
    const textWithoutLineBreaks = text.replace(/<br>/g, "X");
    return textWithoutLineBreaks.length;
  };

  const createOffer = async (params: PostOfferParams) => {
    const offerData = createOfferRequestData(params);
    const res = await dispatchForm(
      offer
        ? updateOfferAsync({ offerData, id: offer.id })
        : createOfferAsync({ offerData })
    );
    if (res.type.endsWith("rejected")) return;
    navigate("/profile/offers");
  };

  const handleChangeLocation = (location: string[]) => {
    const [city, district] = location[0].split(",");

    setValue("city", city);
    setValue("district", district);
  };

  const handleShowMore: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    setShowMore(!showMore);
  };

  const stylesWrap = {
    flexWrap: "wrap",
    [theme.breakpoints.up("sm")]: {
      flexWrap: "nowrap",
    },
  };

  return (
    <Page bgcolor={"grey.100"}>
      <Box maxWidth="900px" margin="0 auto">
        <Box component="header">
          <Typography
            variant={"h1"}
            fontFamily="Verdana"
            fontWeight={600}
            pl={5}
          >
            {offer ? t("offers:edit-offer") : t("addOffer:add-your-offer")}
          </Typography>
        </Box>
        <Form onSubmit={handleSubmit(createOffer)}>
          <FormSection>
            <FormFieldset heading={t("addOffer:the-title-of-your-ad")} mb={0}>
              <Typography component={"p"} fontSize="14px" mb={6} maxWidth={500}>
                {t("addOffer:title-max-char")}
              </Typography>
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <TextField
                    label={t("addOffer:enter-the-title")}
                    variant="outlined"
                    fullWidth
                    error={Boolean(errors[field.name])}
                    helperText={errors[field.name]?.message}
                    required
                    sx={{ mb: 3 }}
                    {...field}
                  />
                )}
              />
              <Box display="flex" sx={stylesWrap} gap={3}>
                <Controller
                  name="price"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="number"
                      label={t("addOffer:price")}
                      variant="outlined"
                      fullWidth
                      required
                      error={Boolean(errors[field.name])}
                      helperText={errors[field.name]?.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {VALUE_SUFFIX.price}
                          </InputAdornment>
                        ),
                      }}
                      {...field}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="size"
                  render={({ field }) => (
                    <TextField
                      type="number"
                      label={t("addOffer:area-in-m2")}
                      variant="outlined"
                      fullWidth
                      error={Boolean(errors[field.name])}
                      helperText={errors[field.name]?.message}
                      required
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {VALUE_SUFFIX.size}
                          </InputAdornment>
                        ),
                      }}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="priceM"
                  control={control}
                  disabled={true}
                  render={({ field }) => (
                    <TextField
                      disabled={true}
                      type="number"
                      label={t("addOffer:price-per-square")}
                      value={calcPriceM(watch("price"), watch("size"))}
                      variant="outlined"
                      error={Boolean(errors[field.name])}
                      helperText={errors[field.name]?.message}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {VALUE_SUFFIX.price}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Box>
            </FormFieldset>
          </FormSection>
          <FormSection>
            <FormFieldset heading={t("addOffer:basic-information")}>
              <Box display={"flex"} sx={stylesWrap} gap={3}>
                <Controller
                  name="type"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <SelectGroup
                      name={name}
                      required
                      fullWidth
                      label={t("addOffer:transaction-type")}
                      onChange={onChange}
                      values={table.OfferType}
                      value={value ?? ""}
                      loading={!tableLoaded}
                    />
                  )}
                />
                <Controller
                  name="buildType"
                  control={control}
                  render={({ field }) => (
                    <SelectGroup
                      fullWidth
                      required
                      label={t("addOffer:type-of-development")}
                      values={table.Build}
                      loading={!tableLoaded}
                      {...field}
                    />
                  )}
                />
              </Box>
            </FormFieldset>
            <FormFieldset heading={t("addOffer:location")} mb={0}>
              <Box mb={3}>
                <LocationSelect
                  value={[watch(["city", "district"]).join(",")]}
                  onChange={handleChangeLocation}
                  allowAllDistricts={false}
                  required
                />
              </Box>
              <Box display={"flex"} sx={stylesWrap} gap={3}>
                <Controller
                  name="street"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label={t("addOffer:street")}
                      variant="outlined"
                      error={Boolean(errors[field.name])}
                      helperText={errors[field.name]?.message}
                      fullWidth
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="stnum"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label={t("addOffer:number-of-the-building")}
                      variant="outlined"
                      error={Boolean(errors[field.name])}
                      helperText={errors[field.name]?.message}
                      fullWidth
                      {...field}
                    />
                  )}
                />
              </Box>
            </FormFieldset>
          </FormSection>
          <FormSection>
            <FormFieldset heading={t("addOffer:gallery")}>
              <OfferImages />
            </FormFieldset>
          </FormSection>
          <FormSection>
            <FormFieldset heading={t("addOffer:extra-info")} mb={0}>
              <Box display="flex" flexWrap={"wrap"} gap={3} mb={6}>
                <Controller
                  name="floor"
                  control={control}
                  render={({ field }) => (
                    <Box sx={{ flex: "48%" }}>
                      <SelectGroup
                        fullWidth
                        label={t("addOffer:floor")}
                        values={table.Floor}
                        loading={!tableLoaded}
                        {...field}
                      />
                    </Box>
                  )}
                />
                <Controller
                  name="market"
                  control={control}
                  render={({ field }) => (
                    <Box sx={{ flex: "48%" }}>
                      <SelectGroup
                        fullWidth
                        label={t("addOffer:market")}
                        values={table.Market}
                        loading={!tableLoaded}
                        {...field}
                      />
                    </Box>
                  )}
                />
                <Controller
                  name="rooms"
                  control={control}
                  render={({ field }) => (
                    <Box sx={{ flex: "48%" }}>
                      <SelectGroup
                        fullWidth
                        label={t("addOffer:count-rooms")}
                        values={table.Room}
                        {...field}
                      />
                    </Box>
                  )}
                />
                <Controller
                  name="condition"
                  control={control}
                  render={({ field }) => (
                    <Box sx={{ flex: "48%" }}>
                      <SelectGroup
                        fullWidth
                        label={t("addOffer:state")}
                        values={table.Condition}
                        loading={!tableLoaded}
                        {...field}
                      />
                    </Box>
                  )}
                />
                <Controller
                  name="parking"
                  control={control}
                  render={({ field }) => (
                    <Box sx={{ flex: "48%" }}>
                      <SelectGroup
                        fullWidth
                        label={t("addOffer:parking")}
                        values={table.Parking}
                        loading={!tableLoaded}
                        {...field}
                      />
                    </Box>
                  )}
                />
                <Controller
                  name="material"
                  control={control}
                  render={({ field }) => (
                    <FormControl sx={{ flex: "48%" }}>
                      <SelectGroup
                        fullWidth
                        label={t("addOffer:building-material")}
                        values={table.Material}
                        loading={!tableLoaded}
                        {...field}
                      />
                    </FormControl>
                  )}
                />
                <Controller
                  name="costs"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label={t("addOffer:fees")}
                      variant="outlined"
                      error={Boolean(errors[field.name])}
                      helperText={errors[field.name]?.message}
                      sx={{ flex: "48%" }}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="year"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label={t("addOffer:year-of-construction")}
                      variant="outlined"
                      fullWidth
                      sx={{ flex: "48%" }}
                      {...field}
                    />
                  )}
                />
              </Box>
              {!showMore && (
                <Button
                  size="large"
                  onClick={handleShowMore}
                  sx={{ m: "0 auto" }}
                  endIcon={<MdOutlineKeyboardArrowDown />}
                >
                  {t("addOffer:show-more")}
                </Button>
              )}
            </FormFieldset>
          </FormSection>
          {showMore && (
            <>
              <FormSection>
                <FormFieldset heading={t("addOffer:communication")}>
                  <Controller
                    name="transport"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <ButtonGroup
                        options={table.Transport}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </FormFieldset>
                <FormFieldset heading={t("addOffer:education")}>
                  <Controller
                    name="education"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <ButtonGroup
                        options={table.Education}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </FormFieldset>
                <FormFieldset heading={t("addOffer:health-and-beauty")}>
                  <Controller
                    name="health"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <ButtonGroup
                        options={table.Health}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </FormFieldset>
                <FormFieldset heading={t("addOffer:recreation")}>
                  <Controller
                    name="recreation"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <ButtonGroup
                        options={table.Recreation}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </FormFieldset>
                <FormFieldset heading={t("addOffer:remaining")}>
                  <Controller
                    name="others"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <ButtonGroup
                        options={table.OthersNearby}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </FormFieldset>
                <FormFieldset heading={t("addOffer:amenities")}>
                  <Controller
                    name="amenities"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <ButtonGroup
                        options={table.Amenity}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </FormFieldset>
              </FormSection>
              <FormSection>
                <Box display={"flex"} sx={stylesWrap} gap={3}>
                  <Box flex={"50%"}>
                    <FormFieldset heading={t("addOffer:kitchen")}>
                      <Controller
                        name="kitchen"
                        control={control}
                        render={({ field }) => (
                          <SelectGroup
                            label={t("addOffer:kitchen-condition")}
                            values={table.State}
                            fullWidth
                            loading={!tableLoaded}
                            {...field}
                          />
                        )}
                      />
                    </FormFieldset>
                  </Box>
                  <Box flex={"50%"}>
                    <FormFieldset heading={t("addOffer:kitchen-form")}>
                      <Controller
                        name="kitchenAm"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <ButtonGroup
                            options={table.Kitchen}
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                    </FormFieldset>
                  </Box>
                </Box>
                <Box display={"flex"} sx={stylesWrap} gap={3}>
                  <Box flex={"50%"}>
                    <FormFieldset heading={t("addOffer:bathroom")}>
                      <Controller
                        name="bathroom"
                        control={control}
                        render={({ field }) => (
                          <SelectGroup
                            label={t("addOffer:bathroom-state")}
                            values={table.State}
                            fullWidth
                            loading={!tableLoaded}
                            {...field}
                          />
                        )}
                      />
                    </FormFieldset>
                  </Box>
                  <Box flex={"50%"}>
                    <FormFieldset heading={t("addOffer:bathroom-details")}>
                      <Controller
                        name="bathAm"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <ButtonGroup
                            options={table.Bathroom}
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                    </FormFieldset>
                  </Box>
                </Box>
                <FormFieldset heading={t("addOffer:interior")}>
                  <Box display="flex" sx={stylesWrap} gap={3}>
                    <Controller
                      name="installation"
                      control={control}
                      render={({ field }) => (
                        <Box sx={{ flex: "50%" }}>
                          <SelectGroup
                            label={t("addOffer:installation-status")}
                            values={table.Installation}
                            fullWidth
                            loading={!tableLoaded}
                            {...field}
                          />
                        </Box>
                      )}
                    />
                    <Controller
                      name="loudness"
                      control={control}
                      render={({ field }) => (
                        <Box sx={{ flex: "50%" }}>
                          <SelectGroup
                            values={table.Loudness}
                            label={t("addOffer:volume")}
                            fullWidth
                            loading={!tableLoaded}
                            {...field}
                          />
                        </Box>
                      )}
                    />
                    <Controller
                      name="windows"
                      control={control}
                      render={({ field }) => (
                        <Box sx={{ flex: "50%" }}>
                          <SelectGroup
                            label={t("addOffer:windows")}
                            values={table.Window}
                            fullWidth
                            loading={!tableLoaded}
                            {...field}
                          />
                        </Box>
                      )}
                    />
                  </Box>
                </FormFieldset>
                <FormFieldset heading={t("addOffer:furnished")}>
                  <Controller
                    name="furnitured"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <ButtonGroup
                        options={table.Furniture}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </FormFieldset>
                <FormFieldset heading={t("addOffer:heating")}>
                  <Controller
                    name="energy"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <ButtonGroup
                        options={table.Energy}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </FormFieldset>
                <FormFieldset heading={t("addOffer:media")}>
                  <Controller
                    name="media"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <ButtonGroup
                        options={table.Medium}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </FormFieldset>
                <FormFieldset heading={t("addOffer:cardinal-directions")}>
                  <Controller
                    name="direction"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <ButtonGroup
                        options={table.Direction}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </FormFieldset>
                <Button
                  size="large"
                  onClick={handleShowMore}
                  sx={{ m: "0 auto" }}
                  endIcon={<MdOutlineKeyboardArrowUp />}
                >
                  {t("addOffer:collapse")}
                </Button>
              </FormSection>
            </>
          )}
          <FormSection>
            <FormFieldset heading={t("addOffer:description")}>
              <Typography component={"p"} fontSize="14px" mb={3}>
                {t("addOffer:description-should-contain")}
              </Typography>
              <Typography component={"p"} fontSize="14px" mb={3}>
                {t("addOffer:description")}{" "}
                {countCharacters(watch("description"))} / 4000
              </Typography>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <MarkdownEditor
                    value={field.value}
                    onChange={field.onChange}
                    maxLength={4000}
                  />
                )}
              />
            </FormFieldset>
          </FormSection>

          <LoadingButton
            type="submit"
            size="large"
            sx={{ m: "0 auto" }}
            loading={isSubmitting}
          >
            {isSubmitting
              ? t("addOffer:saving")
              : offer
              ? t("addOffer:save-offer")
              : t("addOffer:add-offer")}
          </LoadingButton>
        </Form>
      </Box>
    </Page>
  );
};

export default FormOffer;
