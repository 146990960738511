import { useDispatch, TypedUseSelectorHook, useSelector } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import { accountSlice } from "../views/LoginRegister/accountSlice";
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { utilitySlice } from "./utilitySlice";
import { catalogSlice } from "../views/offers/catalogSlice";
import { offerImagesSlice } from "components/store/offerImageSlice";
import { FieldValues, Path, UseFormReturn } from "react-hook-form";

export const store = configureStore({
  reducer: {
    account: accountSlice.reducer,
    utility: utilitySlice.reducer,
    catalog: catalogSlice.reducer,
    offerImages: offerImagesSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useFormDispatch = <T extends FieldValues>(
  form: UseFormReturn<T>
) => {
  const dispatch = useAppDispatch();
  const { setError } = form;

  return async (action: any) =>
    dispatch(action).then((response: any) => {
      // Set api validation errors
      if (response.type.endsWith("/rejected")) {
        Object.entries(response.payload ?? {}).forEach(([key, value]) => {
          setError(key as Path<T>, {
            message: (value as [string])[0],
          });
        });
      }
      return response;
    });
};
