import { Dialog, FormControl, TextField } from "@mui/material";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { useAppDispatch } from "components/store/configureStore";
import { forgotPassword } from "./accountSlice";
import { EMAIL_PATTERN } from "utils/constants";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { useEffect } from "react";
interface Props {
  handlePasswordClose: () => void;
  openPassword: boolean;
}

interface RemindPasswordParams {
  email: string;
}

const RemindPassword = ({ handlePasswordClose, openPassword }: Props) => {
  const { t } = useTranslation();
  const form = useForm<RemindPasswordParams>({
    defaultValues: {
      email: "",
    },
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting, errors, isValid, isSubmitted },
  } = form;
  const dispatch = useAppDispatch();

  const submit = async (params: FieldValues) => {
    const res = await dispatch(forgotPassword(params));
    if (res.type !== "account/forgotPassword/rejected") handlePasswordClose();
  };

  useEffect(() => {
    !openPassword && reset();
  }, [reset, openPassword]);

  return (
    <Dialog
      open={openPassword}
      onClose={handlePasswordClose}
      aria-labelledby="form-dialog-title"
    >
      <div className="reset-box">
        <h2>{t("login:reset-password")}</h2>
        <FormControl component={"form"} onSubmit={handleSubmit(submit)}>
          <Controller
            name={"email"}
            control={control}
            rules={{
              required: t("validation:field-required"),
              pattern: {
                value: EMAIL_PATTERN,
                message: t("validation:field-invalid"),
              },
            }}
            render={({ field }) => (
              <TextField
                label={t("login:email")}
                variant="outlined"
                autoComplete="username"
                size="small"
                type="email"
                error={Boolean(errors[field.name])}
                helperText={errors[field.name]?.message}
                fullWidth
                {...field}
              />
            )}
          />

          <LoadingButton
            type="submit"
            size="large"
            disabled={!isValid && isSubmitted}
            loading={isSubmitting}
            sx={{ mt: 6, minWidth: "180px", mx: "auto" }}
          >
            {!isSubmitting && t("login:send")}
          </LoadingButton>
        </FormControl>
      </div>
    </Dialog>
  );
};

export default RemindPassword;
