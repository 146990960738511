import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import getAxiosParams from "../getParams";
import SmallSearcher from "./SmallSearcher";

const SearchWithState = () => {
  const navigate = useNavigate();
  const [location, setLocation] = useState([]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    navigate(`/search/${getAxiosParams({ location })}`);
  };

  return (
    <SmallSearcher
      location={location}
      setLocation={setLocation}
      handleSubmit={handleSubmit}
    />
  );
};

export default SearchWithState;
