import { Link } from "@mui/material";
import "./slider.scss";
import { ResponseOffer } from "components/models/offer";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { AuthPoster } from "components/reusable/AuthPoster/AuthPoster";
import placeholder from "images/home-placeholder.webp";
import { formatPrice } from "utils/helpers";
import { VALUE_SUFFIX } from "utils/constants";
interface Props {
  offer: ResponseOffer;
  ribbon?: string;
}

const OfferCard = ({ offer, ribbon }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="offer-box">
      <div className="image-wrapper">
        <img
          src={offer.featured_image?.variants?.thumbnail ?? placeholder}
          alt="residence-images"
        />
      </div>
      <div className="info">
        <div className="features flex">
          <p className="location">{offer.city && offer.city.name}</p>
          <p className="rooms">
            <i className="fa-solid fa-bed"></i>
            {offer.rooms} {t("utils:rooms")}
          </p>
          <p className="size">
            <i className="fa-solid fa-ruler-combined"></i>
            {offer.size} {VALUE_SUFFIX.size}
          </p>
          <p className="area">
            <i className="fa-solid fa-calendar"></i>
            {t("utils:construction-year")}
            {offer.year}
          </p>
        </div>
        <div className="details-box flex">
          {offer.price ? (
            <p className="price">
              {formatPrice(offer.price)} {VALUE_SUFFIX.price}
            </p>
          ) : (
            <AuthPoster message={t("utils:log-in-to-see-price")} />
          )}

          <Link
            component={RouterLink}
            to={`/offer/${offer.id}`}
            variant="button"
          >
            {t("utils:details")}
          </Link>
        </div>
      </div>
      {ribbon && (
        <div className="offer-box-ribbon">
          <p className="offer-box-ribbon-text">{ribbon}</p>
        </div>
      )}
    </div>
  );
};

export default OfferCard;
