import { ResponseOffer } from "components/models/offer";
import { motion } from "framer-motion";
import { useAppSelector } from "components/store/configureStore";
import { TableElement } from "components/models/table";
import { useTranslation } from "react-i18next";
import { AuthPoster } from "components/reusable/AuthPoster/AuthPoster";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { VALUE_SUFFIX } from "utils/constants";
import { formatPrice } from "utils/helpers";
import MarkdownPreview from "components/views/utils/Markdown/Preview";

interface Props {
  offer: ResponseOffer;
}
export default function PropInfo({ offer }: Props) {
  const { t } = useTranslation();
  const table = useAppSelector((state) => state.utility.table);

  // return table of values from lookupTable for array of strings
  const checkArrayLookupTable = (
    lookupTable: TableElement[],
    requestTable: string[]
  ) => {
    return lookupTable.filter((lookupEl: any) =>
      requestTable?.includes(lookupEl.id)
    );
  };

  // return table of values from lookupTable for single value
  const checkForSingleLookupTable = (
    lookupTable: TableElement[],
    itemId: string
  ) => {
    return lookupTable.find((element: TableElement) => element.id === itemId);
  };

  const buildType = checkForSingleLookupTable(table.Build, offer.buildType);
  const market = checkForSingleLookupTable(table.Market, offer.market);
  const type = checkForSingleLookupTable(table.OfferType, offer.type?.id);
  const state = checkForSingleLookupTable(table.State, offer.condition);
  const material = checkForSingleLookupTable(table.Material, offer.material);
  const loudness = checkForSingleLookupTable(table.Loudness, offer.loudness);
  const furniture = checkForSingleLookupTable(
    table.Furniture,
    offer.furnitured?.[0]
  );
  const windows = checkForSingleLookupTable(table.Window, offer.windows);
  const direction = checkForSingleLookupTable(
    table.Direction,
    offer.direction?.[0]
  );
  const floor = checkForSingleLookupTable(table.Floor, offer.floor);
  const kitchen = checkForSingleLookupTable(table.State, offer.kitchen);
  const bathroom = checkForSingleLookupTable(table.State, offer.bathroom);

  const transport = checkArrayLookupTable(table.Transport, offer.transport);
  const education = checkArrayLookupTable(table.Education, offer.education);

  const healthAndRecreation = checkArrayLookupTable(
    table.Health,
    offer.health
  ).concat(checkArrayLookupTable(table.Recreation, offer.recreation));

  const amenities = checkArrayLookupTable(table.Amenity, offer.amenities);

  const kitchenAm = checkArrayLookupTable(table.Kitchen, offer.kitchenAm);
  const bathAm = checkArrayLookupTable(table.Bathroom, offer.bathAm);
  const energy = checkArrayLookupTable(table.Energy, offer.energy);
  const media = checkArrayLookupTable(table.Medium, offer.media);

  return (
    <>
      <h3>{t("buy:real-estate-information")}</h3>
      <Grid container rowSpacing={2}>
        <Grid container item id="details" xs={12} md={6} rowSpacing={3}>
          {!offer.price ? (
            <Grid item xs={12}>
              <AuthPoster message={t("utils:log-in-to-see-price")} />
            </Grid>
          ) : (
            <>
              <Grid item xs={6}>
                <Typography variant="captionBold">{t("buy:price")}:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption">
                  {`${formatPrice(offer.price)} ${VALUE_SUFFIX.price}` ||
                    t("buy:not-provided")}
                </Typography>
              </Grid>
              {offer.priceM && (
                <>
                  <Grid item xs={6}>
                    <Typography variant="captionBold">
                      {t("buy:price")} {VALUE_SUFFIX.size}:{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption">
                      {offer.priceM
                        ? `${formatPrice(offer.priceM)} ${VALUE_SUFFIX.price}`
                        : t("buy:not-provided")}
                    </Typography>
                  </Grid>
                </>
              )}
              <Grid item xs={6}>
                <Typography variant="captionBold">{t("buy:rent")}: </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption">
                  {offer.costs
                    ? `${offer.costs} ${VALUE_SUFFIX.price}`
                    : t("buy:not-provided")}
                </Typography>
              </Grid>
            </>
          )}
          <Grid item xs={6}>
            <Typography variant="captionBold">
              {t("buy:transaction-type")}:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption">
              {type?.name || t("buy:not-provided")}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="captionBold">
              {t("buy:area-in-m2")}:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption">
              {offer.size ? (
                <>
                  {offer.size} {VALUE_SUFFIX.size}
                </>
              ) : (
                t("buy:not-provided")
              )}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="captionBold">
              {t("buy:property-type")}:
            </Typography>
          </Grid>{" "}
          <Grid item xs={6}>
            <Typography variant="caption">
              {buildType?.name || t("buy:not-provided")}{" "}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="captionBold">{t("buy:city")}:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption">
              {offer.city?.name || t("buy:not-provided")}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="captionBold">{t("buy:district")}:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption">
              {offer.district?.name || t("buy:not-provided")}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={1}
          sx={{ display: { xs: "none", sm: "flex" } }}
        >
          <Divider orientation="vertical" flexItem />
        </Grid>
        <Grid container item xs={12} md={5} rowSpacing={3}>
          <Grid item xs={6}>
            <Typography variant="captionBold">{t("buy:street")}:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption">
              {offer.street || t("buy:not-provided")}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="captionBold">{t("buy:streetNum")}:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption">
              {offer.stnum || t("buy:not-provided")}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="captionBold">{t("buy:condition")}:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption">
              {state?.name || t("buy:not-provided")}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="captionBold">{t("buy:material")}:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption">
              {material?.name || t("buy:not-provided")}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="captionBold"> {t("buy:loudness")}:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption">
              {loudness?.name || t("buy:not-provided")}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="captionBold"> {t("buy:market")}:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption">
              {" "}
              {market?.name || t("buy:not-provided")}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="captionBold">
              {" "}
              {t("buy:number-of-rooms")}:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption">
              {" "}
              {offer.rooms || t("buy:not-provided")}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="captionBold">
              {" "}
              {t("buy:year-of-construction")}:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption">
              {" "}
              {offer.year || t("buy:not-provided")}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="captionBold">{t("buy:floor")}:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption">
              {" "}
              {floor?.name || t("buy:not-provided")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <h3 id="about-facility">{t("buy:about-the-facility")}</h3>
      {offer.description ? (
        <MarkdownPreview source={offer.description} />
      ) : (
        <div className="offer-description">
          {t("buy:description-not-provided")}
        </div>
      )}
      <div className="additional-info" id="info">
        {(transport.length !== 0 ||
          education.length !== 0 ||
          healthAndRecreation.length !== 0 ||
          amenities.length !== 0) && (
          <>
            <h3>{t("buy:additional-info")}</h3>
            <div className="additional-box">
              {transport.length !== 0 && (
                <div className="motion-button-row">
                  <>
                    <h4>{t("buy:transport")}</h4>
                    {transport.map((item: TableElement) => (
                      <motion.button key={item.id} value={item.name}>
                        {item.name}
                      </motion.button>
                    ))}
                  </>
                </div>
              )}
              {education.length !== 0 && (
                <div className="motion-button-row">
                  <>
                    <h4>{t("buy:education")}</h4>
                    {education.map((item: TableElement) => (
                      <motion.button key={item.id} value={item.name}>
                        {item.name}
                      </motion.button>
                    ))}
                  </>
                </div>
              )}
            </div>
            <div className="additional-box">
              {healthAndRecreation.length !== 0 && (
                <div className="motion-button-row">
                  <>
                    <h4>{t("buy:health-and-recreation")}</h4>
                    {healthAndRecreation.map((item: TableElement) => (
                      <motion.button key={item.id} value={item.name}>
                        {item.name}
                      </motion.button>
                    ))}
                  </>
                </div>
              )}
              {amenities.length !== 0 && (
                <div className="motion-button-row">
                  <>
                    <h4>{t("buy:building-amenities")}</h4>
                    {amenities.map((item: TableElement) => (
                      <motion.button key={item.id} value={item.name}>
                        {item.name}
                      </motion.button>
                    ))}
                  </>
                </div>
              )}
            </div>
          </>
        )}
        <h3>{t("buy:indoors")}</h3>
        <p>
          {t("buy:furnished")}: {furniture?.name || t("buy:not-provided")}
        </p>
        <p>
          {t("buy:windows")}: {windows?.name || t("buy:not-provided")}
        </p>
        <p>
          {t("buy:cardinal-directions")}:{" "}
          {direction?.name || t("buy:not-provided")}
        </p>
        <p>
          {t("buy:kitchen-condition")}: {kitchen?.name || t("buy:not-provided")}
        </p>
        <p>
          {t("buy:bathroom-condition")}:{" "}
          {bathroom?.name || t("buy:not-provided")}
        </p>
        {kitchenAm.length !== 0 && (
          <div className="motion-button-row">
            <p>{t("buy:kitchen-form")}:</p>
            {kitchenAm.map((item: TableElement) => (
              <motion.button key={item.id} value={item.name}>
                {item.name}
              </motion.button>
            ))}
          </div>
        )}
        {bathAm.length !== 0 && (
          <div className="motion-button-row">
            <p>{t("buy:bathroom-eq")}:</p>
            {bathAm.map((item: TableElement) => (
              <motion.button key={item.id} value={item.name}>
                {item.name}
              </motion.button>
            ))}
          </div>
        )}
        {energy.length !== 0 && (
          <div className="motion-button-row">
            <p>{t("buy:heating")}:</p>
            {energy.map((item: TableElement) => (
              <motion.button key={item.id} value={item.name}>
                {item.name}
              </motion.button>
            ))}
          </div>
        )}
        {media.length !== 0 && (
          <div className="motion-button-row">
            <p>{t("buy:following-utilities")}:</p>
            {media.map((item: TableElement) => (
              <motion.button key={item.id} value={item.name}>
                {item.name}
              </motion.button>
            ))}
          </div>
        )}
      </div>
    </>
  );
}
