import {
  useAppDispatch,
  useAppSelector,
} from "components/store/configureStore";
import { ChangeEventHandler, useEffect, useMemo, useState } from "react";
import {
  clearState,
  fetchOfferImagesAsync,
  markImageAsDeleted,
  removeUploadedImage,
  uploadOfferImageAsync,
  reorderImages,
} from "components/store/offerImageSlice";
import { Button, Dialog, Grid, Skeleton } from "@mui/material";
import { OfferImage, UploadedOfferImage } from "components/models/offer";
import { useTranslation } from "react-i18next";
import { showNotification } from "components/store/utilitySlice";
import OfferImagesItem from "./OfferImagesItem";
import OfferImagesItemLoader from "./OfferImagesItemLoader";
import OfferImagesUploadInput from "./OfferImagesUploadInput";
import { IoMdClose } from "react-icons/io";

const MAX_IMAGES = 20;

interface OfferImagesProps {
  offerId?: string;
}

const OfferImages = ({ offerId }: OfferImagesProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [itemForDeletion, setItemForDeletion] = useState<
    UploadedOfferImage["id"] | OfferImage["id"] | null
  >(null);

  const offerImages = useAppSelector((state) => state.offerImages.items);
  const uploadedImages = useAppSelector((state) => state.offerImages.uploaded);
  const imageOrder = useAppSelector((state) => state.offerImages.order);

  const visibleImages: (UploadedOfferImage | OfferImage)[] = useMemo(
    () =>
      uploadedImages
        .concat(offerImages)
        .filter((img) => imageOrder.includes(img.id))
        .slice()
        .sort((a, b) => imageOrder.indexOf(a.id) - imageOrder.indexOf(b.id)),
    [offerImages, uploadedImages, imageOrder]
  );

  const isFetching = useAppSelector((state) => state.offerImages.isFetching);

  const uploadingCount = useAppSelector(
    (state) => state.offerImages.uploadingCount
  );
  const isUploading = uploadingCount > 0;
  const canUpload =
    !isFetching && visibleImages.length + uploadingCount < MAX_IMAGES;

  const uploadImage: ChangeEventHandler<HTMLInputElement> = (e) => {
    const files = e.target.files;
    const remainingSlots = MAX_IMAGES - visibleImages.length - uploadingCount;

    if (!files) return;

    if (remainingSlots < files.length) {
      dispatch(
        showNotification({
          message: t("addOffer:max-photos", { max: MAX_IMAGES }),
          severity: "warning",
        })
      );
    }

    for (let i = 0; i < Math.min(remainingSlots, files.length); i++) {
      const formData = new FormData();
      formData.append("images[]", files[i]);
      dispatch(uploadOfferImageAsync(formData));
    }
  };

  const moveImage = (id: OfferImage["id"], newIndex: number) => {
    const image = visibleImages.find((img) => img.id === id);
    if (!image) return;

    if (newIndex < 0 || newIndex >= visibleImages.length) return;

    dispatch(
      reorderImages({ oldIndex: visibleImages.indexOf(image), newIndex })
    );
  };

  const deleteImage = () => {
    if (!itemForDeletion) return;

    if (offerImages.find((img) => img.id === itemForDeletion))
      dispatch(markImageAsDeleted(itemForDeletion));

    if (uploadedImages.find((img) => img.id === itemForDeletion))
      dispatch(removeUploadedImage(itemForDeletion));

    return setItemForDeletion(null);
  };

  useEffect(() => {
    dispatch(clearState());

    if (offerId) {
      dispatch(fetchOfferImagesAsync(offerId));
    }

    return () => {
      dispatch(clearState());
    };
  }, [offerId, dispatch]);

  return (
    <Grid container spacing={4} sx={{ minHeight: "300px" }}>
      {isFetching &&
        new Array(9).fill(0).map((_, i) => (
          <Grid item xs={12} sm={6} md={4} key={i}>
            <Skeleton
              variant="rectangular"
              sx={{ width: "100%", height: "auto", aspectRatio: "4/3" }}
            />
          </Grid>
        ))}
      {visibleImages.map(
        ({ id, variants: { thumbnail } }, index, collection) => (
          <Grid item xs={12} sm={6} md={4} key={id}>
            <OfferImagesItem
              id={id}
              index={index}
              src={thumbnail}
              collection={collection}
              onMove={moveImage}
              onDelete={setItemForDeletion}
            />
          </Grid>
        )
      )}
      {isUploading &&
        Array(uploadingCount)
          .fill(1)
          .map((start, index) => (
            <Grid item xs={12} sm={6} md={4} key={start + index}>
              <OfferImagesItemLoader />
            </Grid>
          ))}
      {canUpload && (
        <Grid item xs={12} sm={6} md={4}>
          <OfferImagesUploadInput onChange={uploadImage} />
        </Grid>
      )}
      <Dialog
        open={!!itemForDeletion}
        onClose={() => setItemForDeletion(null)}
        className="offer-image-delete-dialog"
        slotProps={{
          backdrop: {
            style: { backgroundColor: "transparent" },
          },
        }}
      >
        <div className="dialog-wrapper delete-dialog">
          <IoMdClose
            className="close-icon"
            size={20}
            onClick={() => setItemForDeletion(null)}
          />
          <h4 className="delete-question">
            {t("addOffer:sure-to-delete-image")}
          </h4>
          <div className="button-section">
            <Button onClick={() => setItemForDeletion(null)} color="secondary">
              {t("addOffer:cancel")}
            </Button>
            <Button onClick={deleteImage} size="medium">
              {t("addOffer:delete")}
            </Button>
          </div>
        </div>
      </Dialog>
    </Grid>
  );
};

export default OfferImages;
