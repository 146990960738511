import { useEffect, useState } from "react";
import "./catalog.scss";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  Skeleton,
  Typography,
} from "@mui/material";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { searchOffersAsync } from "./catalogSlice";
import { offersSelectors } from "components/store/selectors";
import { ResponseOffer } from "../../models/offer";
import Searcher from "../utils/Searcher/Searcher";
import parseUrlParameters from "../utils/FormatUrl";
import getAxiosParams from "../utils/getParams";
import SearchResultSummary from "./SearchResultSummary";
import { useTranslation } from "react-i18next";
import TooltipComponent from "components/reusable/Tooltip/Tooltip";
import { formatPrice } from "utils/helpers";
import { VALUE_SUFFIX } from "utils/constants";
import placeholder from "images/home-placeholder.webp";
const initParams = {
  title: "",
  buildType: "",
  type: "",
  location: [],
  min_price: "",
  max_price: "",
  min_size: "",
  max_size: "",
  rooms: "",
  floor: "",
  loudness: "",
  installation: "",
  furniture: "",
  condition: "",
  direction: "",
  amenity: [""],
  medium: [""],
  parking: [""],
  energy: [""],
  window: "",
  kitchen: "",
  bathroom: [""],
  health: [""],
  recreation: [""],
  education: [""],
  others: [""],
  order: "asc",
  page: 1,
};

const arr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

const Catalog = () => {
  const { t } = useTranslation();
  const { params } = useParams();
  const catalog = useAppSelector(offersSelectors.selectAll);
  const offersLoaded = useAppSelector((state) => state.catalog.offersLoaded);
  const metaData = useAppSelector((state) => state.catalog.metaData);
  const navigate = useNavigate();
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");
  const [count, setCount] = useState("10");
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(searchOffersAsync(params));
  }, [dispatch, params]);

  const parsedParams = params
    ? { ...initParams, ...parseUrlParameters(params) }
    : initParams;

  const handlePageChange = (page: number) => {
    navigate(`/search/${getAxiosParams({ ...parsedParams, page: page })}`);
  };

  const handleSelectChange = (
    parameter: string,
    value: string,
    updateFunction: React.Dispatch<React.SetStateAction<string>>,
    axiosParams: any
  ) => {
    updateFunction(value);
    navigate(
      `/search/${getAxiosParams({
        ...parsedParams,
        [parameter]: value,
      })}`
    );
  };

  const handleOrderByChange = (event: SelectChangeEvent) => {
    handleSelectChange(
      "order_by",
      event.target.value,
      setOrderBy,
      parsedParams
    );
  };

  const handleOrderChange = (event: SelectChangeEvent) => {
    handleSelectChange("order", event.target.value, setOrder, parsedParams);
  };

  const handleCountChange = (event: SelectChangeEvent) => {
    handleSelectChange("page_size", event.target.value, setCount, parsedParams);
  };

  return (
    <div className="catalog-flexbox">
      <div className="catalog-container">
        <Searcher expanded={true} />
        <div className="catalog-sort__box">
          <p>
            {metaData?.from}-{metaData?.to} {t("main:offers")} {metaData?.total}
          </p>
          <Box display={"flex"} gap={2}>
            <FormControl>
              <InputLabel id="orderBy-select-label">
                {t("main:sort")}
              </InputLabel>
              <Select
                labelId="orderBy-select-label"
                value={orderBy}
                label={t("main:sort")}
                onChange={handleOrderByChange}
                sx={{ marginRight: "2px" }}
              >
                <MenuItem value={""}> {t("main:default")}</MenuItem>
                <MenuItem value={"price"}>{t("main:price")}</MenuItem>
                <MenuItem value={"size"}>{t("main:size")}</MenuItem>
                <MenuItem value={"created_at"}>{t("main:created")}</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id="order-select-label">{t("main:order")}</InputLabel>
              <Select
                labelId="order-select-label"
                value={order}
                label={t("main:order")}
                onChange={handleOrderChange}
              >
                <MenuItem value={"asc"}>{t("main:asc")}</MenuItem>
                <MenuItem value={"desc"}>{t("main:desc")}</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </div>
        {offersLoaded ? (
          <div className="catalog-box">
            <SearchResultSummary />
            {!catalog.length && (
              <div className="catalog-box">
                <Box sx={{ pt: 4, pb: 6 }}>
                  <h1>{t("offers:no-offers")}</h1>
                </Box>
              </div>
            )}
            {catalog.map((offer: ResponseOffer, i: number) => (
              <Box
                className="offer-line"
                key={offer.id}
                component={Link}
                to={`/offer/${offer.id}`}
              >
                <img
                  src={
                    offer.featured_image
                      ? offer.featured_image.variants.thumbnail
                      : placeholder
                  }
                  alt={offer.title}
                />
                <div className="offer-description">
                  <div>
                    <p>{offer.title}</p>
                    <p>
                      {offer.street && "ul." + offer.street + " "}
                      {offer.city && offer.city.name + " "}
                      {offer.district &&
                        offer.district.name != offer.city.name &&
                        offer.district.name}
                    </p>
                  </div>
                  <h4>
                    {offer.price ? (
                      <>
                        <span className="visible-data">
                          {formatPrice(offer.price)} {VALUE_SUFFIX.price}
                        </span>
                        {offer.priceM && (
                          <span className="visible-data">
                            {formatPrice(offer.priceM)} {VALUE_SUFFIX.price}/
                            {VALUE_SUFFIX.size}
                          </span>
                        )}
                      </>
                    ) : (
                      <>
                        <TooltipComponent
                          WrappedComponent={
                            <span>
                              <span className="blurred-data">000 000</span>
                              <span className="visible-data">
                                {VALUE_SUFFIX.price}
                              </span>
                            </span>
                          }
                          message={t("utils:log-in-to-see-price")}
                        />
                        <TooltipComponent
                          WrappedComponent={
                            <span>
                              <span className="blurred-data">0 000</span>
                              <span className="visible-data">
                                {VALUE_SUFFIX.price}/{VALUE_SUFFIX.size}
                              </span>
                            </span>
                          }
                          message={t("utils:log-in-to-see-price")}
                        />
                      </>
                    )}
                    <span className="visible-data">
                      {offer.size} {VALUE_SUFFIX.size}
                    </span>
                    {offer.rooms && (
                      <span className="visible-data">
                        {t("buy:room", { count: parseInt(offer.rooms) })}
                      </span>
                    )}
                  </h4>
                </div>
              </Box>
            ))}
            <Box py={4}>
              <Grid
                container
                rowGap={{
                  xs: 5,
                  md: 0,
                }}
              >
                <Grid item xs={12} md={3}>
                  <FormControl
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Typography>{t("main:per")}</Typography>
                    <Select
                      labelId="order-select-label"
                      value={count}
                      onChange={handleCountChange}
                      sx={{ width: "80px", height: "40px" }}
                    >
                      <MenuItem value={6}>6</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={24}>24</MenuItem>
                      <MenuItem value={48}>48</MenuItem>
                    </Select>
                    <Typography>{t("main:page")}</Typography>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  display={"flex"}
                  justifyContent={"center"}
                >
                  <Pagination
                    onChange={(e, page: number) => handlePageChange(page)}
                    size="large"
                    page={metaData?.current_page}
                    count={metaData?.last_page}
                    //FIX FOR BREAKING CSS RULES FROM NAV.SCSS
                    sx={{ position: "static", width: "auto" }}
                  />
                </Grid>
              </Grid>
            </Box>
          </div>
        ) : (
          <div className="catalog-box">
            {arr.map((i) => (
              <Box className="offer-line" key={i}>
                <Skeleton
                  animation="wave"
                  height={240}
                  width={250}
                  style={{ gridArea: " 1 / 1 / 2 / 2" }}
                />
                <Skeleton
                  animation="wave"
                  height={60}
                  style={{ gridArea: " 1 / 2 / 2 / 3" }}
                />
                <Skeleton
                  animation="wave"
                  height={40}
                  style={{ gridArea: " 1 / 3 / 2 / 4" }}
                />
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  height={60}
                  style={{ gridArea: " 1 / 4 / 2 / 5" }}
                />
              </Box>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Catalog;
