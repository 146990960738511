import { Box, alpha, useMediaQuery, useTheme } from "@mui/material";
import OfferImagesOverlayButton from "./OfferImagesOverlayButton";
import { OfferImage, UploadedOfferImage } from "components/models/offer";

type ImageId = OfferImage["id"] | UploadedOfferImage["id"];

interface OfferImagesItemProps {
  id: ImageId;
  index: number;
  src: string;
  collection: (OfferImage | UploadedOfferImage)[];
  onMove: (id: ImageId, newIndex: number) => void;
  onDelete: (id: ImageId) => void;
}

const OfferImagesItem = ({
  id,
  index,
  src,
  collection,
  onMove,
  onDelete,
}: OfferImagesItemProps) => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up("sm"));
  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  const itemsPerRow = isMd ? 3 : isSm ? 2 : 1;

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "auto",
        aspectRatio: "4/3",
        img: {
          width: "100%",
          height: "100%",
          borderRadius: "4px",
          objectFit: "cover",
          filter: "brightness(1)",
        },
        "&:hover": {
          "& > .offer-images-item-overlay": {
            opacity: 1,
          },
        },
      }}
    >
      <img src={src} alt="uploaded" />
      <Box
        sx={{
          width: "100%",
          height: (theme) => `calc(100% - ${theme.spacing(2)})`,
          borderRadius: "4px",
          position: "absolute",
          top: 0,
          left: 0,
          backgroundColor: (theme) => alpha(theme.palette.common.black, 0.5),
          opacity: 0,
          transition: "opacity .2s",
        }}
        className="offer-images-item-overlay"
      >
        <OfferImagesOverlayButton
          variant="move-up"
          disabled={index < itemsPerRow}
          onClick={() => onMove(id, index - itemsPerRow)}
        />
        <OfferImagesOverlayButton
          variant="move-right"
          disabled={index === collection.length - 1}
          onClick={() => onMove(id, index + 1)}
        />
        <OfferImagesOverlayButton
          variant="move-down"
          disabled={index > collection.length - (itemsPerRow + 1)}
          onClick={() => onMove(id, index + itemsPerRow)}
        />
        <OfferImagesOverlayButton
          variant="move-left"
          disabled={index === 0}
          onClick={() => onMove(id, index - 1)}
        />
        <OfferImagesOverlayButton
          variant="set-featured"
          onClick={() => onMove(id, 0)}
          disabled={index === 0}
        />
        <OfferImagesOverlayButton
          variant="delete"
          onClick={() => {
            onDelete(id);
          }}
        />
      </Box>
    </Box>
  );
};

export default OfferImagesItem;
