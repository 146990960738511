import { ReactNode } from "react";
import "./tooltip.scss";

interface Props {
  WrappedComponent: any;
  message: ReactNode;
}

export default function TooltipComponent({ WrappedComponent, message }: Props) {
  return (
    <>
      <div className="tooltip">
        {WrappedComponent}
        <span className="tooltip-text">{message}</span>
      </div>
    </>
  );
}
