import { OverridesStyleRules } from "@mui/material/styles/overrides";

const MuiFormLabel: OverridesStyleRules = {
  defaultProp: {},
  styleOverrides: {
    asterisk: {
      color: "red",
    },
  },
  variants: [],
};

export default MuiFormLabel;
