import {
  useAppDispatch,
  useAppSelector,
} from "components/store/configureStore";
import ProfileNav from "./ProfileNav";
import { useEffect, useState } from "react";
import {
  deleteSearchCriteriaAsync,
  fetchSearchCriteriasAsync,
} from "../LoginRegister/accountSlice";
import { Button, Dialog, Skeleton, Stack } from "@mui/material";

import { SearchCriteria } from "components/models/offer";
import { IoMdClose } from "react-icons/io";
import SearchCriteriasTable from "./SearchCriteriasTable";
import { useTranslation } from "react-i18next";
import { showNotification } from "components/store/utilitySlice";

const SearchCriterias = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { favoriteSearchCriterias, status, user } = useAppSelector(
    (state) => state.account
  );
  const [criteriaForDeletion, setCriteriaForDeletion] = useState<
    SearchCriteria["id"] | null
  >(null);

  useEffect(() => {
    if (!user?.id) return;
    dispatch(fetchSearchCriteriasAsync());
  }, [user?.id, dispatch]);

  const handleDelete = async () => {
    if (!criteriaForDeletion || isDeleting) return;
    await dispatch(deleteSearchCriteriaAsync(criteriaForDeletion));
    setCriteriaForDeletion(null);
  };

  const isLoading = status === "pendingFetchSearchCriterias";
  const isDeleting = status === "pendingDeleteSearchCriteria";

  return (
    <div className="profile-container">
      <ProfileNav />
      <div className="profile-page">
        <h3>{t("profile:my-search-criterias")}</h3>
        {isLoading ? (
          <Stack spacing={2}>
            {Array(5)
              .fill(0)
              .map((_, i) => (
                <Skeleton key={i} variant="rounded" width="100%" height={56} />
              ))}
          </Stack>
        ) : (
          <SearchCriteriasTable
            favoriteSearchCriterias={favoriteSearchCriterias}
            onDelete={setCriteriaForDeletion}
          />
        )}
        <Dialog
          open={!!criteriaForDeletion}
          onClose={() => setCriteriaForDeletion(null)}
          className="search-criteria-delete-dialog"
          slotProps={{
            backdrop: {
              style: { backgroundColor: "transparent" },
            },
          }}
        >
          <div className="dialog-wrapper delete-dialog">
            <IoMdClose
              className="close-icon"
              size={20}
              onClick={() => setCriteriaForDeletion(null)}
            />
            <h4 className="delete-question">
              {t("profile:sure-to-delete-search-criteria")}
            </h4>
            <div className="button-section">
              <Button
                color="secondary"
                onClick={() => setCriteriaForDeletion(null)}
              >
                {t("profile:cancel")}
              </Button>
              <Button onClick={() => handleDelete()}>
                {t("profile:delete")}
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default SearchCriterias;
