import { Navigate, createBrowserRouter } from "react-router-dom";
import HouseNearby from "./components/views/buy/housenearby/HouseNearby";
import WhyBuy from "./components/views/buy/whybuy/WhyBuy";
import CanIAfford from "./components/views/resources/caniafford/CanIAfford";
import BuyGuide from "./components/views/resources/buyguide/BuyGuide";
import BuyInSteps from "./components/views/resources/buyguide/guides/BuyInSteps";
import ChooseAgent from "./components/views/resources/buyguide/guides/ChooseAgent";
import Commision from "./components/views/resources/buyguide/guides/Commision";
import FindServices from "./components/views/resources/findservices/FindServices";
import FindEvents from "./components/views/resources/findevents/FindEvents";
import HouseMarket from "./components/views/resources/housemarket/HouseMarket";
import SellWorth from "./components/views/sell/sellworth/SellWorth";
import SellDashboard from "./components/views/sell/selldashboard/SellDashboard";
import PayOff from "./components/views/sell/payoff/Payoff";
import SellGuide from "./components/views/sell/sellguide/SellGuide";
import HomeImprovement from "./components/views/sell/homepreparation/HomePreparation";
import RentGuide from "./components/views/rent/rentguide/RentGuide";
import BuyOrRent from "./components/views/rent/buyorrent/BuyOrRent";
import RentTrends from "./components/views/rent/renttrends/RentTrends";
import TipsForRenters from "./components/views/rent/tipsforrenters/TipsForRenters";
import Contact from "./components/views/main/Contact";
import PropertyDetails from "./components/views/buy/propertyDetails/PropertyDetails";
import UpcomingEvents from "./components/views/resources/findevents/upcomingevents/UpcomingEvents";
import Catalog from "./components/views/offers/Catalog";
import HomeValue from "./components/views/sell/sellworth/HomeValue";
import FiveWays from "./components/views/sell/sellworth/FiveWays";
import TenAdvices from "./components/views/sell/sellworth/TenAdvices";
import SellWith from "./components/views/sell/sellwithrealn/SellWith";
import App from "./App";
import Premium from "./components/views/premium/Premium";
import Profile from "./components/views/profile/Profile";
import Offers from "./components/views/profile/Offers";
import Favorites from "./components/views/profile/Favorites";
import ContactRequests from "./components/views/profile/ContactRequests";
import Preferences from "./components/views/profile/Preferences";
import About from "./components/views/footerLinks/about/About";
import PrivacyPolicy from "./components/views/footerLinks/privacypolicy/PrivacyPolicy";
import Possibilities from "./components/views/footerLinks/possibilities/Possibilities";
import Statue from "./components/views/footerLinks/statue/Statue";
import ProtectedRoute from "ProtectedRoute";
import NewPassword from "components/views/LoginRegister/NewPassword";
import Tokens from "components/views/profile/Tokens";
import SearchCriterias from "components/views/profile/SearchCriterias";
import { UserRoleEnum } from "utils/enums";
import EditOffer from "components/views/Offer/EditOffer";
import AddOffer from "components/views/Offer/AddOffer";
import variables from "variables";
const generateNearbyRoutes = () => {
  return variables.featuredOffers.map((type) => ({
    path: `${type}-nearby`,
    element: <HouseNearby type={type} />,
  }));
};
const nearbyRoutes = generateNearbyRoutes();

const router = createBrowserRouter([
  {
    path: "*",
    element: <App />,
    children: [
      {
        element: <ProtectedRoute level="auth" />,
        children: [
          { path: "profile", element: <Profile /> },
          { path: "profile/favorites", element: <Favorites /> },
          { path: "profile/search-criterias", element: <SearchCriterias /> },
          { path: "profile/contact-requests", element: <ContactRequests /> },
          { path: "profile/preferences", element: <Preferences /> },
          {
            element: <ProtectedRoute level="role" role={UserRoleEnum.AGENT} />,
            children: [
              { path: "add-new-offer", element: <AddOffer /> },
              { path: "profile/offers", element: <Offers /> },

              {
                element: (
                  <ProtectedRoute level="resource-owner" resource="offer" />
                ),
                children: [{ path: "catalog/:id", element: <EditOffer /> }],
              },
            ],
          },

          {
            element: <ProtectedRoute level="role" role={UserRoleEnum.ADMIN} />,
            children: [{ path: "profile/tokens", element: <Tokens /> }],
          },
        ],
      },
      { path: "search/", element: <Catalog key="default" /> },
      { path: "search/:params", element: <Catalog /> },
      { path: "offer/:id", element: <PropertyDetails /> },
      ...nearbyRoutes,
      { path: "why-buy", element: <WhyBuy /> },
      // { path: "can-i-afford", element: <CanIAfford /> },
      { path: "guides", element: <BuyGuide /> },
      { path: "guides/how-to-buy-a-house", element: <BuyInSteps /> },
      {
        path: "guides/how-to-prepare-my-home",
        element: <HomeImprovement />,
      },
      {
        path: "guides/how-to-choose-real-estate-agent",
        element: <ChooseAgent />,
      },
      { path: "guides/real-estate-commision", element: <Commision /> },
      { path: "find-services", element: <FindServices /> },
      { path: "find-events", element: <FindEvents /> },
      { path: "housing-market", element: <HouseMarket /> },
      { path: "sell", element: <SellWith /> },
      { path: "upcoming-events", element: <UpcomingEvents /> },
      { path: "sell/how-much-is-it-worth", element: <SellWorth /> },
      {
        path: "sell/how-much-is-it-worth/find-the-value",
        element: <HomeValue />,
      },
      { path: "sell/how-much-is-it-worth/five-ways", element: <FiveWays /> },
      {
        path: "sell/how-much-is-it-worth/ten-advices",
        element: <TenAdvices />,
      },
      { path: "sell/dashboard", element: <SellDashboard /> },
      // { path: "guides/will-selling-pay-off", element: <PayOff /> },
      { path: "guide/how-to-sell-a-house", element: <SellGuide /> },
      { path: "guides/home-improvement", element: <HomeImprovement /> },
      { path: "guides/how-to-rent-a-house", element: <RentGuide /> },
      { path: "guides/buy-or-rent", element: <BuyOrRent /> },
      { path: "guides/rent-trends", element: <RentTrends /> },
      { path: "guides/tips-for-renters", element: <TipsForRenters /> },
      { path: "realn-premium", element: <Premium /> },
      { path: "contact", element: <Contact /> },
      { path: "about", element: <About /> },
      { path: "privacy-policy", element: <PrivacyPolicy /> },
      { path: "possibilities", element: <Possibilities /> },
      { path: "statue", element: <Statue /> },
      { path: "reset-password/:token", element: <NewPassword /> },
      { path: "*", element: <Navigate replace to="/" /> },
    ],
  },
]);
export default router;
