import { useEffect } from "react";
import { ResponseOffer } from "../../../../models/offer";
import {
  useAppDispatch,
  useAppSelector,
} from "components/store/configureStore";
import ImageGallery from "react-image-gallery";
import { fetchOfferImagesAsync } from "components/store/offerImageSlice";
import { Skeleton } from "@mui/material";
import { Box } from "@mui/system";
interface Props {
  offer: ResponseOffer;
}

const PropertySlider = ({ offer }: Props) => {
  const dispatch = useAppDispatch();
  const imageOrder = useAppSelector((state) => state.offerImages.order);
  const images = useAppSelector((state) =>
    state.offerImages.items
      .slice()
      .sort((a, b) => imageOrder.indexOf(a.id) - imageOrder.indexOf(b.id))
      .map((element: any) => {
        return {
          original: element.variants.large,
          thumbnail: element.variants.thumbnail,
        };
      })
  );
  const isLoading = useAppSelector(
    (state: any) => state.offerImages.isFetching
  );
  const offerId = offer?.id;

  useEffect(() => {
    if (offerId) dispatch(fetchOfferImagesAsync(offerId));
  }, [offerId]);

  if (isLoading)
    return (
      <Box sx={{ width: "100%", aspectRatio: "16/9" }}>
        <Skeleton variant="rounded" sx={{ width: "100%", height: "100%" }} />
      </Box>
    );

  return <ImageGallery items={images} />;
};

export default PropertySlider;
