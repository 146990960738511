import MessageDialog from "./MessageDialog";
import { useEffect, useState } from "react";
import { ResponseOffer } from "components/models/offer";
import { useTranslation } from "react-i18next";
import AgentInfoDialog from "./AgentInfoDialog";
import { Button, Grid } from "@mui/material";
import { AuthPoster } from "components/reusable/AuthPoster/AuthPoster";
import DeleteOfferButton from "./DeleteOfferButton";
import Divider from "@mui/material/Divider";
import EditOfferButton from "./EditOfferButton";
import DuplicationOfferButton from "./DuplicationOfferButton";
import { useAppSelector } from "components/store/configureStore";

interface Props {
  offer: ResponseOffer;
}

const MOBILE_VIEW_BREAKPOINT = 600;

export default function PropWidget({ offer }: Props) {
  const { t } = useTranslation();
  const [isSticky, setIsSticky] = useState(false);
  const initialOffset = 80;
  const [messageOpen, setMessageOpen] = useState(false);
  const [agentOpen, setAgentOpen] = useState(false);
  const userId = useAppSelector((state) => state.account.user?.id);

  const handleMessageOpen = () => {
    setMessageOpen(true);
  };

  const handleMessageClose = () => {
    setMessageOpen(false);
  };

  const handleAgentOpen = () => {
    setAgentOpen(true);
  };

  const handleAgentClose = () => {
    setAgentOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(
        window.scrollY > initialOffset &&
          window.innerWidth > MOBILE_VIEW_BREAKPOINT
      );
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <aside
      style={{ top: isSticky ? 80 : initialOffset }}
      className={`property-widget ${isSticky ? "sticky" : ""}`}
    >
      <h2>{offer.title}</h2>
      <h3>{offer.city.name}</h3>

      {offer.user ? (
        <>
          {offer?.user?.phone && (
            <Button
              size="large"
              fullWidth
              sx={{ mb: 2 }}
              onClick={handleAgentOpen}
            >
              {t("buy:appointment-with-agent")}
            </Button>
          )}
          <Button size="large" fullWidth onClick={handleMessageOpen}>
            {t("buy:agent-message")}
          </Button>

          {offer?.user && offer.user.id == userId && (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              rowGap={2}
            >
              <Grid item xs={12} mt={2}>
                <Divider
                  style={{ opacity: 0.1, marginTop: 20, marginBottom: 20 }}
                />
              </Grid>
              <Grid item xs={12}>
                <EditOfferButton offer={offer} />
              </Grid>
              <Grid item xs={12}>
                <DuplicationOfferButton offer={offer} />
              </Grid>
              <Grid item xs={12}>
                <DeleteOfferButton offer={offer} />
              </Grid>
            </Grid>
          )}

          <AgentInfoDialog
            open={agentOpen}
            handleClose={handleAgentClose}
            offer={offer}
          />
          <MessageDialog
            open={messageOpen}
            handleClose={handleMessageClose}
            offer={offer}
          />
        </>
      ) : (
        <AuthPoster message={t("utils:log-in-to-contact-agent")} />
      )}
    </aside>
  );
}
