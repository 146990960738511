import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import axios from "axios";
import variables from "../../../variables.js";
import { FieldValues } from "react-hook-form";
import { Token } from "components/models/token.js";
import {
  FavoriteOffer,
  ResponseOffer,
  SearchCriteria,
} from "components/models/offer.js";
import { showNotification } from "components/store/utilitySlice";
import i18next from "i18next";
import {
  ResetPasswordFormValues,
  User,
  UserPreferences,
  UserProfile,
} from "components/models/user";

axios.defaults.withCredentials = true;

interface AccountState {
  user: User | null;
  userPreferences: UserPreferences | null;
  userProfile: UserProfile | null;
  userToken: string | null;
  accountError: any;
  message: string;
  token: string | null;
  status: string;
  newToken: string;
  tokenList: Token[];
  userOffers: ResponseOffer[] | null;
  favoriteSearchCriterias: SearchCriteria[];
  favoriteOffers: FavoriteOffer[];
}

const initialState: AccountState = {
  user: null,
  userPreferences: null,
  userProfile: null,
  userToken: null,
  accountError: null,
  message: "",
  token: null,
  status: "idle",
  newToken: "",
  tokenList: [],
  userOffers: null,
  favoriteSearchCriterias: [],
  favoriteOffers: [],
};

export const getToken = createAsyncThunk(
  "account/getToken",
  async (_, thunkAPI) => {
    try {
      const config = {
        withCredentials: true,
      };
      await axios.get(`${variables.getBackend}/sanctum/csrf-cookie`, config);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const login = createAsyncThunk<{ data: any }, FieldValues>(
  "account/login",
  async ({ email, password }, thunkAPI) => {
    try {
      const response = await axios({
        method: "post",
        url: `${variables.getBackend}/api/auth/login`,
        headers: {
          ...variables.xsrfToken,
          Accept: "application/json",
        },
        withCredentials: true,
        data: {
          email: email,
          password: password,
        },
      });

      return { data: response.data };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.errors);
    }
  }
);

export const signup = createAsyncThunk<{ data: any }, FieldValues>(
  "account/signup",
  async ({ name, email, password, password_confirmation, token }, thunkAPI) => {
    try {
      const config = {
        headers: {
          ...variables.xsrfToken,
          Authorization: `Bearer ${localStorage.getItem("currentToken")}`,
          Accept: "application/json",
        },
        withCredentials: true,
      };
      const response = await axios.post(
        `${variables.getBackend}/api/auth/register`,
        {
          name: name,
          email: email,
          password: password,
          password_confirmation: password_confirmation,
          token: token,
        },
        config
      );
      return { data: response.data };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.errors);
    }
  }
);

export const logoutCurrentUser = createAsyncThunk(
  "account/logoutCurrentUser",
  async (_, thunkAPI) => {
    try {
      let data = await axios({
        method: "post",
        url: `${variables.getBackend}/api/auth/logout`,
        withCredentials: true,
        headers: {
          ...variables.xsrfToken,
          Authorization: `Bearer ${localStorage.getItem("currentToken")}`,
          Accept: "application/json",
        },
      });

      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const forgotPassword = createAsyncThunk<string, FieldValues>(
  "account/forgotPassword",
  async ({ email }, thunkAPI) => {
    try {
      const response = await axios({
        method: "post",
        url: `${variables.getBackend}/auth/forgot-password`,
        headers: {
          ...variables.xsrfToken,
          Accept: "application/json",
        },
        withCredentials: true,
        data: {
          email: email,
        },
      });

      thunkAPI.dispatch(
        showNotification({
          message: response.data.message,
          severity: "success",
        })
      );

      return response.data.message;
    } catch (error: any) {
      // Endpoint redirects back to frontend with cors error
      // return thunkAPI.rejectWithValue(error.response.data.errors);
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const resetPassword = createAsyncThunk<
  { data: any },
  ResetPasswordFormValues
>("account/resetPassword", async (params, thunkAPI) => {
  try {
    const config = {
      headers: {
        ...variables.xsrfToken,
        Authorization: `Bearer ${localStorage.getItem("currentToken")}`,
        Accept: "application/json",
      },
    };

    const response = await axios.post(
      `${variables.getBackend}/auth/reset-password`,
      params,
      config
    );
    return { data: response.data };
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.errors);
  }
});

export const createRegisterToken = createAsyncThunk(
  "profile/createRegisterToken",
  async (_, thunkAPI) => {
    try {
      let response = await axios({
        method: "post",
        url: `${variables.getBackend}/api/v1/tokens`,
        headers: {
          ...variables.xsrfToken,
          Authorization: `Bearer ${localStorage.getItem("currentToken")}`,
          Accept: "application/json",
        },
        withCredentials: true,
      });

      return { data: response.data };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const listTokens = createAsyncThunk(
  "profile/listTokens",
  async (_, thunkAPI) => {
    try {
      let response = await axios({
        method: "get",
        url: `${variables.getBackend}/api/v1/tokens`,
        withCredentials: true,
        headers: {
          ...variables.xsrfToken,
          Authorization: `Bearer ${localStorage.getItem("currentToken")}`,
          Accept: "application/json",
        },
      });
      return { data: response.data };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchCurrentUser = createAsyncThunk(
  "account/fetchCurrentUser",
  async (_, thunkAPI) => {
    try {
      let user = await axios({
        method: "get",
        url: `${variables.getBackend}/api/v1/user`,
        headers: {
          ...variables.xsrfToken,
          Authorization: `Bearer ${localStorage.getItem("currentToken")}`,
          Accept: "application/json",
        },
        withCredentials: true,
      });

      return user.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const getUserProfile = createAsyncThunk(
  "profile/getUserPofile",
  async (_, thunkAPI) => {
    try {
      let response = await axios({
        method: "get",
        url: `${variables.getBackend}/api/v1/user/profile`,
        withCredentials: true,
        headers: {
          ...variables.xsrfToken,
          Authorization: `Bearer ${localStorage.getItem("currentToken")}`,
          Accept: "application/json",
        },
      });
      return { data: response.data };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const getUserPreferences = createAsyncThunk(
  "profile/getUserPreferences",
  async (_, thunkAPI) => {
    try {
      let response = await axios({
        method: "get",
        url: `${variables.getBackend}/api/v1/user/system-preferences`,
        withCredentials: true,
        headers: {
          ...variables.xsrfToken,
          Authorization: `Bearer ${localStorage.getItem("currentToken")}`,
          Accept: "application/json",
        },
      });
      return { data: response.data };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const getUserOffers = createAsyncThunk(
  "profile/getUserOffers",
  async (_, thunkAPI) => {
    try {
      let response = await axios({
        method: "get",
        url: `${variables.getBackend}/api/v1/user/offers`,
        withCredentials: true,
        headers: {
          ...variables.xsrfToken,
          Authorization: `Bearer ${localStorage.getItem("currentToken")}`,
          Accept: "application/json",
        },
      });
      const offers: ResponseOffer[] = response.data.data;
      return offers;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const updateUserPreferences = createAsyncThunk<any, { data: any }>(
  "profile/updateUserPreferences",
  async ({ data }, thunkAPI) => {
    try {
      let response = await axios({
        method: "patch",
        withCredentials: true,
        url: `${variables.getBackend}/api/v1/user/system-preferences`,
        headers: {
          ...variables.xsrfToken,
          Authorization: `Bearer ${localStorage.getItem("currentToken")}`,
          Accept: "application/json",
        },
        data: data,
      });
      thunkAPI.dispatch(getUserPreferences());
      thunkAPI.dispatch(
        showNotification({
          message: i18next.t("profile:success"),
          severity: "success",
        })
      );
      return response.data;
    } catch (error: any) {
      thunkAPI.dispatch(
        showNotification({
          message: i18next.t("profile:error"),
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const updateUserProfile = createAsyncThunk<any, { formData: any }>(
  "profile/updateUserProfile",
  async ({ formData }, thunkAPI) => {
    try {
      let response = await axios({
        method: "post",
        withCredentials: true,
        url: `${variables.getBackend}/api/v1/user/profile?_method=PATCH`,
        headers: {
          ...variables.xsrfToken,
          Authorization: `Bearer ${localStorage.getItem("currentToken")}`,
          Accept: "application/json",
        },
        data: formData,
      });
      thunkAPI.dispatch(getUserProfile());
      thunkAPI.dispatch(
        showNotification({
          message: i18next.t("profile:success"),
          severity: "success",
        })
      );
      return response.data;
    } catch (error: any) {
      thunkAPI.dispatch(
        showNotification({
          message: i18next.t("profile:error"),
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const postSaveSearchCriteria = createAsyncThunk<any, SearchCriteria>(
  "profile/postSaveSearchCriteria",
  async (offerParams, thunkAPI) => {
    try {
      let response = await axios({
        method: "post",
        url: `${variables.getBackend}/api/v1/user/search-criterias`,
        headers: {
          ...variables.xsrfToken,
          Authorization: `Bearer ${localStorage.getItem("currentToken")}`,
          Accept: "application/json",
        },
        data: offerParams,
        withCredentials: true,
      });
      thunkAPI.dispatch(
        showNotification({
          message: i18next.t("search:criteria-save-success"),
          severity: "success",
        })
      );
      return response.data;
    } catch (error: any) {
      thunkAPI.dispatch(
        showNotification({
          message: i18next.t("search:criteria-save-fail"),
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchContactRequestsAsync = createAsyncThunk(
  "profile/fetchContactRequestsAsync",
  async (_, thunkAPI) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("currentToken")}`,
          Accept: "application/json",
        },
        withCredentials: true,
      };
      const response = await axios.get(
        `${variables.getBackend}/api/v1/user/contact-requests`,
        config
      );
      return { data: response.data.data };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const createContactRequestsAsync = createAsyncThunk<
  string,
  { data: any; offerId: string }
>("catalog/createContactRequestsAsync", async ({ data, offerId }, thunkAPI) => {
  try {
    let response = await axios({
      method: "post",
      url: `${variables.getBackend}/api/v1/offers/${offerId}/contact-request`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("currentToken")}`,
        Accept: "application/json",
        ...variables.xsrfToken,
      },
      data: data,
      withCredentials: true,
    });

    thunkAPI.dispatch(
      showNotification({
        message: i18next.t("validation:request-sent"),
        severity: "success",
      })
    );

    return response.data.message;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const deleteContactRequestsAsync = createAsyncThunk<any, any>(
  "profile/deleteContactRequestsAsync",
  async (requestId, thunkAPI) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("currentToken")}`,
          Accept: "application/json",
          ...variables.xsrfToken,
        },
        withCredentials: true,
      };
      await axios.delete(
        `${variables.getBackend}/api/v1/user/contact-requests/${requestId}`,
        config
      );
      thunkAPI.dispatch(
        showNotification({
          message: i18next.t("profile:favorites-deleted"),
          severity: "success",
        })
      );
      return true;
    } catch (error: any) {
      thunkAPI.dispatch(
        showNotification({
          message: i18next.t("profile:error"),
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchSearchCriteriasAsync = createAsyncThunk(
  "catalog/fetchSearchCriteriasAsync",
  async (_, thunkAPI) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("currentToken")}`,
          Accept: "application/json",
        },
        withCredentials: true,
      };
      const response = await axios.get(
        `${variables.getBackend}/api/v1/user/search-criterias`,
        config
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const deleteSearchCriteriaAsync = createAsyncThunk<
  SearchCriteria["id"],
  SearchCriteria["id"]
>("catalog/deleteSearchCriteriaAsync", async (criteriaId, thunkAPI) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("currentToken")}`,
        Accept: "application/json",
      },
      withCredentials: true,
    };
    await axios.delete(
      `${variables.getBackend}/api/v1/user/search-criterias/${criteriaId}`,
      config
    );
    thunkAPI.dispatch(
      showNotification({
        message: i18next.t("profile:favorites-deleted"),
        severity: "success",
      })
    );
    return criteriaId;
  } catch (error: any) {
    thunkAPI.dispatch(
      showNotification({
        message: i18next.t("profile:error"),
        severity: "error",
      })
    );
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const getUserFavoriteOffers = createAsyncThunk(
  "profile/getUserFavoriteOffers",
  async (_, thunkAPI) => {
    try {
      const response = await axios({
        method: "get",
        url: `${variables.getBackend}/api/v1/user/favorite-offers`,
        withCredentials: true,
        headers: {
          ...variables.xsrfToken,
          Authorization: `Bearer ${localStorage.getItem("currentToken")}`,
          Accept: "application/json",
        },
      });
      return response.data.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const addOfferToFavoritesAsync = createAsyncThunk<
  any,
  ResponseOffer["id"]
>("profile/addOfferToFavoritesAsync", async (offerId, thunkAPI) => {
  try {
    const response = await axios({
      method: "post",
      url: `${variables.getBackend}/api/v1/user/favorite-offers`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("currentToken")}`,
        Accept: "application/json",
        ...variables.xsrfToken,
      },
      withCredentials: true,
      data: { offer_id: offerId },
    });
    thunkAPI.dispatch(getUserFavoriteOffers());
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const removeOfferFromFavoritesAsync = createAsyncThunk<any, any>(
  "profile/removeOfferFromFavoritesAsync",
  async (offerId, thunkAPI) => {
    try {
      await axios({
        method: "delete",
        url: `${variables.getBackend}/api/v1/user/favorite-offers`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("currentToken")}`,
          Accept: "application/json",
          ...variables.xsrfToken,
        },
        withCredentials: true,
        data: { offer_id: offerId },
      });
      thunkAPI.dispatch(getUserFavoriteOffers());
      thunkAPI.dispatch(
        showNotification({
          message: i18next.t("profile:favorites-deleted"),
          severity: "success",
        })
      );
      return offerId;
    } catch (error: any) {
      thunkAPI.dispatch(
        showNotification({
          message: i18next.t("profile:error"),
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const accountSlice = createSlice({
  name: "account",
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    resetAccountError: (state) => {
      state.accountError = null;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrentUser.pending, (state) => {
        state.status = "pendingFetchUser";
      })
      .addCase(fetchCurrentUser.rejected, (state, action) => {
        state.user = null;
        state.accountError = action.payload;
        state.status = "idle";
      })
      .addCase(fetchCurrentUser.fulfilled, (state, action) => {
        state.user = action.payload.data;
        state.status = "idle";
      })
      .addCase(getToken.pending, (state) => {
        state.status = "pendingGetToken";
      })

      .addCase(getToken.fulfilled, (state, action) => {
        state.status = "idle";
        state.token = document.cookie
          .replace("XSRF-TOKEN=", "")
          .replace("%3D", "=");
      })
      .addCase(getToken.rejected, (state) => {
        state.status = "idle";
        state.token = document.cookie
          .replace("XSRF-TOKEN=", "")
          .replace("%3D", "=");
      })
      .addCase(signup.pending, (state) => {
        state.status = "pendingSignUp";
      })
      .addCase(signup.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(signup.rejected, (state, action) => {
        state.accountError = action.error.message;
        state.status = "idle";
      })
      .addCase(login.pending, (state) => {
        state.status = "pendingLogin";
      })
      .addCase(login.fulfilled, (state, action) => {
        state.user = action.payload.data.user;
        state.userToken = action.payload.data.token;
        localStorage.setItem("currentToken", action.payload.data.token);
        state.status = "idle";
      })
      .addCase(login.rejected, (state, action) => {
        state.accountError = action.error.message;
        state.status = "idle";
      })
      .addCase(logoutCurrentUser.pending, (state) => {
        state.status = "pendingLogout";
      })
      .addCase(logoutCurrentUser.fulfilled, (state, action) => {
        state.status = "loggedOut";

        state.user = initialState.user;
        state.userProfile = initialState.userProfile;
        state.userPreferences = initialState.userPreferences;
        state.tokenList = initialState.tokenList;
        state.userOffers = initialState.userOffers;
        state.favoriteSearchCriterias = initialState.favoriteSearchCriterias;
        state.favoriteOffers = initialState.favoriteOffers;
        localStorage.removeItem("lang");
        localStorage.removeItem("currentToken");
      })
      .addCase(logoutCurrentUser.rejected, (state, action) => {
        state.status = "idle";
        state.accountError = action.payload;

        state.user = initialState.user;
        state.userProfile = initialState.userProfile;
        state.userPreferences = initialState.userPreferences;
        state.tokenList = initialState.tokenList;
        state.userOffers = initialState.userOffers;
        state.favoriteSearchCriterias = initialState.favoriteSearchCriterias;
        state.favoriteOffers = initialState.favoriteOffers;

        localStorage.removeItem("currentToken");
      })
      .addCase(createContactRequestsAsync.fulfilled, (state, action) => {
        state.accountError = "";
      })
      .addCase(createContactRequestsAsync.rejected, (state, action) => {
        state.accountError = action.error.message;
        state.status = "idle";
      })
      .addCase(forgotPassword.pending, (state) => {
        state.status = "pendingForgotPassword";
      })
      .addCase(forgotPassword.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(resetPassword.pending, (state) => {
        state.status = "pendingResetPassword";
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(createRegisterToken.pending, (state) => {
        state.status = "pendingCreateToken";
      })
      .addCase(createRegisterToken.fulfilled, (state, action) => {
        state.status = "idle";
        state.newToken = action.payload.data.data.token;
      })
      .addCase(listTokens.pending, (state) => {
        state.status = "pendingCreateToken";
      })
      .addCase(listTokens.fulfilled, (state, action) => {
        state.tokenList = action.payload.data.data;
        state.status = "idle";
      })
      .addCase(getUserPreferences.pending, (state) => {
        state.status = "pendingFetchPreferences";
      })
      .addCase(getUserPreferences.fulfilled, (state, action) => {
        state.userPreferences = action.payload.data.data;
        state.status = "idle";
      })
      .addCase(updateUserPreferences.pending, (state) => {
        state.status = "pendingUpdateUserPreferences";
      })
      .addCase(updateUserPreferences.fulfilled, (state, action) => {
        state.status = "idle";
        state.user = {
          ...state.user,
          ...action.payload.data,
        };
      })
      .addCase(getUserOffers.pending, (state) => {
        state.status = "pendingFetchUserOffers";
      })
      .addCase(getUserOffers.fulfilled, (state, action) => {
        state.userOffers = action.payload;
        state.status = "idle";
      })
      .addCase(getUserFavoriteOffers.pending, (state) => {
        state.status = "pendingGetUserFavoriteOffers";
      })
      .addCase(getUserFavoriteOffers.fulfilled, (state, action) => {
        state.favoriteOffers = action.payload;
        state.status = "idle";
      })
      .addCase(addOfferToFavoritesAsync.pending, (state) => {
        state.status = "pendingAddOfferToFavorites";
      })
      .addCase(addOfferToFavoritesAsync.fulfilled, (state, { payload }) => {
        state.favoriteOffers.push(payload);
        state.status = "idle";
      })
      .addCase(removeOfferFromFavoritesAsync.pending, (state) => {
        state.status = "pendingRemoveOfferFromFavorites";
      })
      .addCase(
        removeOfferFromFavoritesAsync.fulfilled,
        (state, { payload }) => {
          state.favoriteOffers = state.favoriteOffers.filter(
            ({ id }) => id !== payload
          );
          state.status = "idle";
        }
      )
      .addCase(updateUserProfile.pending, (state) => {
        state.status = "pendingUpdateUserProfile";
      })
      .addCase(getUserProfile.pending, (state) => {
        state.status = "pendingGetUserProfile";
      })
      .addCase(fetchSearchCriteriasAsync.pending, (state) => {
        state.status = "pendingFetchSearchCriterias";
      })
      .addCase(fetchSearchCriteriasAsync.fulfilled, (state, action) => {
        state.favoriteSearchCriterias = action.payload.data;
        state.status = "idle";
      })
      .addCase(postSaveSearchCriteria.pending, (state) => {
        state.status = "pendingPostSaveSearchCriteria";
      })
      .addCase(postSaveSearchCriteria.fulfilled, (state, action) => {
        state.favoriteSearchCriterias.push(action.payload.data);
        state.status = "idle";
      })
      .addCase(deleteSearchCriteriaAsync.pending, (state) => {
        state.status = "pendingDeleteSearchCriteria";
      })
      .addCase(deleteSearchCriteriaAsync.fulfilled, (state, action) => {
        state.favoriteSearchCriterias = state.favoriteSearchCriterias.filter(
          (criteria) => criteria.id !== action.payload
        );
        state.status = "idle";
      })
      .addMatcher(
        isAnyOf(updateUserProfile.fulfilled, getUserProfile.fulfilled),
        (state, action) => {
          state.status = "idle";
          state.userProfile = action.payload.data.data;
        }
      )
      .addMatcher(
        isAnyOf(
          updateUserProfile.rejected,
          getUserOffers.rejected,
          updateUserPreferences.rejected,
          getUserPreferences.rejected,
          listTokens.rejected,
          forgotPassword.rejected,
          resetPassword.rejected,
          createRegisterToken.rejected,
          getUserProfile.rejected,
          fetchSearchCriteriasAsync.rejected,
          fetchContactRequestsAsync.rejected,
          deleteContactRequestsAsync.rejected,
          updateUserProfile.rejected,
          postSaveSearchCriteria.rejected,
          updateUserPreferences.rejected,
          getUserFavoriteOffers.rejected,
          addOfferToFavoritesAsync.rejected,
          removeOfferFromFavoritesAsync.rejected
        ),
        (state, action) => {
          if (typeof action.payload === "string") {
            state.accountError = action.payload;
          }
          state.status = "idle";
        }
      );
  },
});

export default accountSlice.reducer;
export const { setUser, resetAccountError } = accountSlice.actions;
