import Dialog from "@mui/material/Dialog";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import "./loginregister.scss";
import { useFormDispatch } from "../../store/configureStore";
import { signup } from "./accountSlice";
import logo from "../../../images/logo2.webp";
import { EMAIL_PATTERN, PASSWORD_PATTERN } from "utils/constants";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
} from "@mui/material";
import InputPassword from "components/reusable/InputPassword";

interface Props {
  Component: () => JSX.Element;
}

interface SignUpParams {
  name: string;
  email: string;
  password: string;
  repassword: string;
  isUserAgent: boolean;
  token: string;
}

const createSignupRequestData = ({
  name,
  email,
  password,
  repassword,
  token,
  isUserAgent,
}: SignUpParams) =>
  ({
    name,
    email,
    password,
    password_confirmation: repassword,
    ...(isUserAgent && { token }),
  } as const);

const Signup = ({ Component }: Props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const form = useForm<SignUpParams>({
    defaultValues: {
      name: "",
      email: "",
      password: "",
      repassword: "",
      isUserAgent: false,
      token: "",
    },
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting, errors, isValid, isSubmitted },
    watch,
  } = form;

  const dispatchForm = useFormDispatch(form);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const checkPasswordEquality = (value: string) => {
    if (value !== watch("password")) return t("validation:field-invalid");
    return true;
  };
  const submit = async (params: SignUpParams) => {
    const requestData = createSignupRequestData(params);
    const res = await dispatchForm(signup(requestData));
    if (res.type !== "account/signup/rejected") handleClose();
  };

  useEffect(() => {
    !open && reset();
  }, [reset, open]);

  return (
    <>
      <span onClick={handleClickOpen}>
        <Component />
      </span>
      <Dialog open={open} onClose={handleClose}>
        <div className="login-box register-box">
          <img src={logo} alt="logo realn" />
          <h2>{t("login:registration")}</h2>
          <FormControl component={"form"} onSubmit={handleSubmit(submit)}>
            <Controller
              name={"name"}
              control={control}
              rules={{
                required: t("validation:field-required"),
              }}
              render={({ field }) => (
                <TextField
                  autoComplete="off"
                  label={t("login:username")}
                  variant="outlined"
                  size="small"
                  error={Boolean(errors[field.name])}
                  helperText={errors[field.name]?.message}
                  fullWidth
                  sx={{ mb: 3 }}
                  {...field}
                />
              )}
            />
            <Controller
              name={"email"}
              control={control}
              rules={{
                required: t("validation:field-required"),
                pattern: {
                  value: EMAIL_PATTERN,
                  message: t("validation:field-invalid"),
                },
              }}
              render={({ field }) => (
                <TextField
                  label={t("login:email")}
                  variant="outlined"
                  autoComplete="username"
                  size="small"
                  type="email"
                  error={Boolean(errors[field.name])}
                  helperText={errors[field.name]?.message}
                  fullWidth
                  sx={{ mb: 3 }}
                  {...field}
                />
              )}
            />
            <Controller
              name={"password"}
              control={control}
              rules={{
                required: t("validation:field-required"),
                pattern: {
                  value: PASSWORD_PATTERN,
                  message: t("login:password-needs"),
                },
              }}
              render={({ field }) => (
                <InputPassword
                  label={t("login:give-password")}
                  variant="outlined"
                  size="small"
                  isVisible={showPassword}
                  onVisibleChange={handleClickShowPassword}
                  autoComplete="new-password"
                  error={Boolean(errors[field.name])}
                  helperText={errors[field.name]?.message}
                  sx={{ mb: 3 }}
                  fullWidth
                  {...field}
                />
              )}
            />
            <Controller
              name={"repassword"}
              control={control}
              rules={{
                required: t("validation:field-required"),
                validate: checkPasswordEquality,
              }}
              render={({ field }) => (
                <InputPassword
                  label={t("login:repeat-password")}
                  variant="outlined"
                  size="small"
                  autoComplete="new-password"
                  isVisible={showPassword}
                  iconHidden
                  error={Boolean(errors[field.name])}
                  helperText={errors[field.name]?.message}
                  fullWidth
                  sx={{ mb: 3 }}
                  {...field}
                />
              )}
            />
            <Controller
              name={"isUserAgent"}
              control={control}
              render={({ field }) => (
                <FormControl>
                  <FormControlLabel
                    value="end"
                    control={<Checkbox {...field} />}
                    label={t("login:agent-register")}
                    labelPlacement="end"
                  />
                </FormControl>
              )}
            />
            {watch("isUserAgent") && (
              <Controller
                name={"token"}
                control={control}
                rules={{
                  required: t("validation:field-required"),
                }}
                render={({ field }) => (
                  <TextField
                    label={t("login:token")}
                    variant="outlined"
                    size="small"
                    type="text"
                    error={Boolean(errors[field.name])}
                    helperText={errors[field.name]?.message}
                    fullWidth
                    sx={{ mt: 3 }}
                    {...field}
                  />
                )}
              />
            )}
            <LoadingButton
              type="submit"
              size="large"
              disabled={!isValid && isSubmitted}
              loading={isSubmitting}
              sx={{ mt: 6, minWidth: "280px", mx: "auto" }}
            >
              {!isSubmitting && t("login:register")}
            </LoadingButton>
          </FormControl>
        </div>
      </Dialog>
    </>
  );
};

export default Signup;
