import { Theme } from "@mui/material";
import { OverridesStyleRules } from "@mui/material/styles/overrides";

const MuiIconButton: OverridesStyleRules = {
  defaultProp: {},
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      borderRadius: "4px",
    }),
  },
  variants: [
    {
      props: { type: "submit" },
      style: ({ theme }: { theme: Theme }) => ({
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        display: "flex",
        boxShadow: "4px 7px 31px 0px rgba(147, 147, 147, 0.3)",
        height: "100%",

        "&:hover": {
          backgroundColor: theme.palette.primary.dark,
        },
      }),
    },
    {
      props: { disabled: true },
      style: ({ theme }: { theme: Theme }) => ({
        "&&&": {
          backgroundColor: theme.palette.secondary.dark,
          svg: {
            color: "white",
          },
        },
      }),
    },
  ],
};

export default MuiIconButton;
