import "./App.css";
import Nav from "./components/views/nav/Nav";
import Footer from "./components/views/main/Footer";
import ScrollToTop from "./components/views/utils/ScrollToTop";
import { Outlet, useLocation } from "react-router-dom";
import Main from "./components/views/main/Main";
import { useEffect } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "./components/store/configureStore";
import {
  fetchCurrentUser,
  getToken,
  getUserFavoriteOffers,
  getUserPreferences,
  getUserProfile,
} from "./components/views/LoginRegister/accountSlice";
import { getLookupTable } from "components/store/utilitySlice";
import i18next from "i18n";
import { Notifications } from "./components/reusable/Notification/Notifications";
import { useTranslation } from "react-i18next";
import { Helmet, HelmetProvider } from "react-helmet-async";

const App = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const token = useAppSelector((state) => state.account.token);
  const userId = useAppSelector((state) => state.account.user?.id);
  const userProfile = useAppSelector((state) => state.account.userProfile);

  useEffect(() => {
    if (!token) {
      dispatch(getToken());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (!userId && localStorage.getItem("currentToken")) {
      dispatch(fetchCurrentUser());
    }

    if (userId) {
      dispatch(getUserProfile());
      dispatch(getUserPreferences());
      dispatch(getUserFavoriteOffers());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    if (userProfile?.language && userProfile.language.id !== i18next.language) {
      i18next.changeLanguage(userProfile.language.id);
    }
  }, [userProfile]);

  useEffect(() => {
    dispatch(getLookupTable());

    const localLang = localStorage.getItem("lang");
    if (localLang) {
      i18next.changeLanguage(localLang);
    }

    i18next.on("languageChanged", (lng) => {
      localStorage.setItem("lang", lng);
      dispatch(getLookupTable());
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18next]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{t("utils:page-title")}</title>
        <meta name="description" content={t("utils:page-description")} />
      </Helmet>
      <Notifications />
      <Nav />
      <ScrollToTop />
      {location.pathname === "/" ? <Main /> : <Outlet />}
      <Footer />
    </HelmetProvider>
  );
};

export default App;
