import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import axios from "axios";
import variables from "variables";
import { OfferImage, UploadedOfferImage } from "components/models/offer";
import i18next from "i18next";

const SLICE_NAME = "offerImages";

interface OfferImagesState {
  items: OfferImage[];
  uploaded: UploadedOfferImage[];
  deleted: OfferImage[];
  order: OfferImage["id"][];
  isFetching: boolean;
  isAttaching: boolean;
  isDeleting: boolean;
  offerImageError: any;
  uploadingCount: number;
}

export const fetchOfferImagesAsync = createAsyncThunk<OfferImage[], string>(
  SLICE_NAME + "/getOfferImagesAsync",
  async (offerId, thunkAPI) => {
    try {
      thunkAPI.dispatch(clearImages());
      const response = await axios({
        method: "get",
        url: `${variables.getBackend}/api/v1/offers/${offerId}/images`,
        headers: {
          ...variables.xsrfToken,
          Authorization: `Bearer ${localStorage.getItem("currentToken")}`,
          Accept: "application/json",
        },
        withCredentials: true,
      });
      return response.data.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.data });
    }
  }
);

export const uploadOfferImageAsync = createAsyncThunk<
  UploadedOfferImage[],
  FormData
>(SLICE_NAME + "/uploadOfferImageAsync", async (formData, thunkAPI) => {
  try {
    const response = await axios({
      method: "post",
      url: `${variables.getBackend}/api/v1/offers/upload-images`,
      headers: {
        ...variables.xsrfToken,
        Authorization: `Bearer ${localStorage.getItem("currentToken")}`,
        Accept: "application/json",
      },
      data: formData,
      withCredentials: true,
    });
    return response.data.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      error: error.data,
    });
  }
});

export const attachOfferImagesAsync = createAsyncThunk<boolean, string>(
  SLICE_NAME + "/attachOfferImagesAsync",
  async (offerId, thunkAPI) => {
    const state = thunkAPI.getState() as { [SLICE_NAME]: OfferImagesState };
    if (!state.offerImages.uploaded.length) return true;

    try {
      await axios({
        method: "post",
        url: `${variables.getBackend}/api/v1/offers/${offerId}/images`,
        headers: {
          ...variables.xsrfToken,
          Authorization: `Bearer ${localStorage.getItem("currentToken")}`,
          Accept: "application/json",
        },
        data: {
          image_ids: state.offerImages.uploaded.map(
            (image: UploadedOfferImage) => image.id
          ),
        },
        withCredentials: true,
      });
      return true;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.data });
    }
  }
);

export const deleteOfferImagesAsync = createAsyncThunk<boolean, string>(
  SLICE_NAME + "/deleteOfferImagesAsync",
  async (offerId, thunkAPI) => {
    const state = thunkAPI.getState() as { [SLICE_NAME]: OfferImagesState };
    if (!state.offerImages.deleted.length) return true;

    try {
      await axios({
        method: "delete",
        url: `${variables.getBackend}/api/v1/offers/${offerId}/images`,
        headers: {
          ...variables.xsrfToken,
          Authorization: `Bearer ${localStorage.getItem("currentToken")}`,
          Accept: "application/json",
        },
        data: {
          image_ids: state.offerImages.deleted.map(
            (image: OfferImage) => image.id
          ),
        },
        withCredentials: true,
      });
      return true;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.data });
    }
  }
);

export const reorderOfferImagesAsync = createAsyncThunk<boolean, string>(
  SLICE_NAME + "/reorderOfferImagesAsync",
  async (offerId, thunkAPI) => {
    const state = thunkAPI.getState() as { [SLICE_NAME]: OfferImagesState };

    try {
      await axios({
        method: "patch",
        url: `${variables.getBackend}/api/v1/offers/${offerId}/reorder-images`,
        headers: {
          ...variables.xsrfToken,
          Authorization: `Bearer ${localStorage.getItem("currentToken")}`,
          Accept: "application/json",
        },
        data: {
          order: state.offerImages.order,
        },
        withCredentials: true,
      });
      return true;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.data });
    }
  }
);

export const offerImagesSlice = createSlice({
  name: SLICE_NAME,
  initialState: {
    items: [],
    uploaded: [],
    deleted: [],
    order: [],
    isFetching: false,
    isAttaching: false,
    isDeleting: false,
    offerImageError: "",
    uploadingCount: 0,
  } as OfferImagesState,
  reducers: {
    resetImageError: (state) => {
      state.offerImageError = null;
    },
    removeUploadedImage: (state, action) => {
      state.uploaded = state.uploaded.filter(
        (image) => image.id !== action.payload
      );
      state.order = state.order.filter((id) => id !== action.payload);
    },
    markImageAsDeleted: (state, action) => {
      const item = state.items.find((image) => image.id === action.payload);
      if (item) {
        state.deleted.push(item);
        state.order = state.order.filter((id) => id !== item.id);
      }
    },
    reorderImages: (state, action) => {
      const { oldIndex, newIndex } = action.payload;
      const newItems = [...state.items, ...state.uploaded].filter((item) =>
        state.order.includes(item.id)
      );

      newItems.sort(
        (a, b) => state.order.indexOf(a.id) - state.order.indexOf(b.id)
      );
      newItems.splice(newIndex, 0, newItems.splice(oldIndex, 1)[0]);

      state.order = newItems.map((item) => item.id);
    },
    clearImages: (state) => {
      state.items = [];
    },
    clearState: (state) => {
      state.items = [];
      state.uploaded = [];
      state.deleted = [];
      state.order = [];
    },
  },
  extraReducers: (builder) => {
    // Get offer images
    builder.addCase(fetchOfferImagesAsync.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(fetchOfferImagesAsync.fulfilled, (state, action) => {
      state.isFetching = false;
      state.items = action.payload;
      state.order = [...action.payload]
        .sort((a, b) => a.order - b.order)
        .map((image: OfferImage) => image.id);
    });
    builder.addCase(fetchOfferImagesAsync.rejected, (state, action) => {
      state.isFetching = false;
    });

    // Upload offer image
    builder.addCase(uploadOfferImageAsync.pending, (state, action) => {
      state.uploadingCount += action.meta.arg.getAll("images[]").length;
    });
    builder.addCase(uploadOfferImageAsync.fulfilled, (state, action) => {
      if (state.uploadingCount > 0)
        state.uploadingCount -= action.payload.length;
      action.payload.forEach((image: UploadedOfferImage) => {
        state.uploaded.push(image);
        state.order.push(image.id);
      });
    });
    builder.addCase(uploadOfferImageAsync.rejected, (state, action) => {
      state.offerImageError = i18next.t("validation:something-went-wrong");
      state.uploadingCount -= action.meta.arg.getAll("images[]").length;
    });

    // Attach offer images
    builder.addCase(attachOfferImagesAsync.pending, (state, action) => {
      state.isAttaching = true;
    });
    builder.addCase(attachOfferImagesAsync.fulfilled, (state, action) => {
      state.isAttaching = false;
      state.uploaded = [];
    });
    builder.addCase(attachOfferImagesAsync.rejected, (state, action) => {
      state.isAttaching = false;
    });

    // Delete offer images
    builder.addCase(deleteOfferImagesAsync.pending, (state, action) => {
      state.isDeleting = true;
    });
    builder.addCase(deleteOfferImagesAsync.fulfilled, (state, action) => {
      state.isDeleting = false;
      state.deleted = [];
    });
    builder.addCase(deleteOfferImagesAsync.rejected, (state, action) => {
      state.isDeleting = false;
    });
    builder.addMatcher(
      isAnyOf(
        fetchOfferImagesAsync.rejected,
        uploadOfferImageAsync.rejected,
        attachOfferImagesAsync.rejected,
        deleteOfferImagesAsync.rejected,
        reorderOfferImagesAsync.rejected
      ),
      (state, action) => {
        if (typeof action.payload === "string") {
          state.offerImageError = action.payload;
        }
      }
    );
  },
});

export const {
  removeUploadedImage,
  markImageAsDeleted,
  reorderImages,
  clearImages,
  clearState,
  resetImageError,
} = offerImagesSlice.actions;
